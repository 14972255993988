import React from "react";

const CustomAlert = ({ text, show, onClick, setShow, buttonText }) => {
  if (show) {
    return (
      <div class="backdrop flex items-center justify-center">
        <div className="container flex-col w-1/3 z-50 mt-3 mx-auto p-7 bg-white rounded ">
          <div className="mb-4 mx-auto flex justify-center">
            <h3 className="text-xl font-semibold text-black-900">{text}</h3>
          </div>
          <div className="flex items-start justify-center mt-5">
            <button
              className="cancel-button mr-12"
              onClick={() => {
                onClick();
              }}
            >
              {buttonText}
            </button>
            <button
              className="upload-button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CustomAlert;
