import React from "react";
import { GrLocation } from "react-icons/gr";

import Loader from "../assets/lottie/Loader.jsx";
import ordinal from "ordinal";

const OrderFlow = ({ data, loading, onPress }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="p-2 flex flex-col flex-1">
      {data?.installments === null && data?.scheme_id === 0 ? (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center ">
              <div className="flex flex-col items-center justify-center mr-2">
                <div
                  className="text-2xl font-bold text-black-900"
                  style={{
                    lineHeight: "15px",
                    color: "green",
                  }}
                >
                  {new Date(data?.payments[0]?.created_at).getDate()}
                </div>
                <div className="text-sm font-medium text-gray-800">
                  {months[new Date(data?.payments[0]?.created_at).getMonth()]}
                </div>
              </div>
              <div>
                <div
                  className="ml-2"
                  style={{
                    lineHeight: "15px",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  Advance Payment
                </div>
                <div className="ml-2 font-bold text-black-600 text-sm">
                  {"₹ " +
                    parseFloat(data?.payments[0]?.amount).toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 2,
                      }
                    )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="dotted-line my-2 ml-3.5"></div>
          <div className="flex items-center justify-between">
            <div className="flex items-center ">
              <div className="flex flex-col items-center justify-center mr-2">
                <div
                  className="text-2xl font-bold text-black-900"
                  style={{
                    lineHeight: "15px",
                    color:
                      data?.upcoming_payment_status === 0 ? "green" : "#eba300",
                  }}
                >
                  {data?.next_installment_date === null
                    ? new Date(data?.payments[1]?.created_at).getDate()
                    : new Date(data?.next_installment_date).getDate()}
                </div>
                <div className="text-sm font-medium text-gray-800">
                  {data?.next_installment_date === null
                    ? months[new Date(data?.payments[1]?.created_at).getMonth()]
                    : months[new Date(data?.next_installment_date).getMonth()]}
                </div>
              </div>
              <div>
                <div
                  className="ml-2"
                  style={{
                    lineHeight: "15px",
                    fontSize: 16,
                    fontWeight: "bold",
                    color:
                      data?.upcoming_payment_status === 0 ? "green" : "#eba300",
                  }}
                >
                  Full Payment
                </div>
                <div className="ml-2 font-bold text-black-600 text-sm">
                  ₹
                  {data?.next_installment_amount === null
                    ? parseFloat(data?.payments[1]?.amount).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                        }
                      )
                    : parseFloat(data?.next_installment_amount).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                </div>
              </div>
            </div>
            <div>
              {data?.upcoming_payment_status === 0 ? null : (
                <div
                  style={{
                    width: "80px",
                    height: "35px",
                    backgroundColor: "#eba300",
                    borderRadius: "8px",
                    display: "flex",
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontWeight: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!loading) {
                      onPress();
                    }
                  }}
                >
                  {loading ? <Loader size={30} /> : "Pay Now"}
                </div>
              )}
            </div>
          </div>
          <div className="dotted-line my-2 ml-3.5"></div>
        </>
      ) : (
        data?.installments.map((res) => {
          return (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center ">
                  <div className="flex flex-col items-center justify-center mr-2">
                    <div
                      className="text-2xl font-bold text-black-900"
                      style={{
                        lineHeight: "15px",
                        color:
                          res.installment_num <= data?.paid_installments
                            ? "green"
                            : res.installment_num === data.paid_installments + 1
                            ? "#eba300"
                            : "gray",
                      }}
                    >
                      {new Date(res?.installment_date).getDate()}
                    </div>
                    <div className="text-sm font-medium text-gray-800 mt-1">
                      {months[new Date(res?.installment_date).getMonth()]}
                    </div>
                  </div>
                  <div>
                    <div
                      className="ml-2"
                      style={{
                        lineHeight: "15px",
                        fontSize: 16,
                        fontWeight: "bold",
                        color:
                          res.installment_num <= data.paid_installments
                            ? "green"
                            : res.installment_num === data.paid_installments + 1
                            ? "#eba300"
                            : "gray",
                      }}
                    >
                      {ordinal(res?.installment_num)} Installment
                    </div>
                    <div className="ml-2 font-bold text-black-600 text-sm mt-1">
                      {"₹ " +
                        parseFloat(res?.installment_amount).toLocaleString(
                          "en-IN",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                    </div>
                  </div>
                </div>
                <div>
                  {res.installment_num === data.paid_installments + 1 ? (
                    <div
                      style={{
                        width: "80px",
                        height: "35px",
                        backgroundColor: "#eba300",
                        borderRadius: "8px",
                        display: "flex",
                        color: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "12px",
                        fontWeight: "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!loading) {
                          onPress();
                        }
                      }}
                    >
                      {loading ? <Loader size={30} /> : "Pay Now"}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="dotted-line my-2 ml-3.5"></div>
            </>
          );
        })
      )}
      <div className="flex items-center">
        <GrLocation size={30} />
        <div>
          <div
            className="ml-2"
            style={{
              lineHeight: "15px",
              fontSize: 16,
              fontWeight: "bold",
              color: data?.upcoming_payment_status === 0 ? "#eba300" : "black",
            }}
          >
            Buyer
          </div>
          <div className="ml-2 text-medium text-black-600 text-sm">
            You will get notified on delivery day
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFlow;
