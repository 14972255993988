import React from "react";
import { useNavigate } from "react-router-dom";
import Empty from "../assets/lottie/Empty";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="add-white-card m-5per">
      <div className="flex items-center justify-center flex-col mt-10">
        <Empty />
        <button
          onClick={() => {
            navigate("/");
          }}
          style={{
            backgroundColor: "#1287c5",
            height: 40,
            justifyContent: "center",
            margin: 30,
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Rubik",
              textAlign: "center",
              color: "#fff",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            Go to Home
          </div>
        </button>
      </div>
    </div>
  );
};
export default Success;
