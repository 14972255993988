import React from "react";
import footer_bg from "../assets/images/footer_bg.svg";
import footer_avtar from "../assets/images/footer_avtar.svg";
import next_arrow from "../assets/images/next_arrow.svg";
import "react-phone-number-input/style.css";
const Footer = () => {
  return (
    <>
      <footer>
        <div
          className="bg-white mt-8"
          style={{
            height: "491px",
            backgroundImage: `url(${footer_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

            position: "relative",
          }}
        >
          <img
            src={footer_avtar}
            alt="avtar"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "0",
            }}
          />

          <div className="bg-transparent z-1 w-full h-full flex flex-col align items-center p-4 md:p-10 pt-10">
            <h3 className="footer-heading">
              Invite your friends and get exciting rewards
            </h3>
            <p className="footer-text">
              upon completing your friend's first five transactions
              <br />
              you and your friends will receive exciting rewards
            </p>

            <div className="number-input-container">
              <div className="country-code">+91</div>

              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Enter Friends mobile Number"
                className="w-full h-full pl-4"
              />
              <button className="footer-button">
                <img src={next_arrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
