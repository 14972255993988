import React, { useState } from "react";

const BiddingModal = ({
  setShowModal,
  showModal,
  setValue,
  value,
  onClick,
  data,
}) => {
  const [disabled, setDisabled] = useState(true);
  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          {/* <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">Bidding</h3>
          </div> */}
          {/* ---------- main body--------- */}
          <div className="flex justify-center flex-col pb-6">
            <label className="input-label">Bidding amount</label>
            <input
              className="custom-input"
              placeholder="Enter bidding amount"
              required
              value={value}
              onChange={(e) => {
                if (
                  parseInt(e.target.value) >=
                    parseInt(data?.min_listing_price) &&
                  parseInt(e.target.value) <= parseInt(data?.max_listing_price)
                ) {
                  setDisabled(false);
                } else setDisabled(true);
                setValue(e.target.value.replace(/[^0-9]/g, ""));
              }}
            />
            <div className="info-red-text">
              Amount shouldn't be smaller than
              {" ₹" + data?.min_listing_price} and greater than
              {" ₹" + data?.max_listing_price}
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
            <button
              disabled={disabled}
              onClick={onClick}
              className={disabled ? "disabled-upload-button" : "upload-button"}
            >
              Bid
            </button>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default BiddingModal;
