import { toast } from "react-toastify";
import { AuthInstance } from "../../utils/axios";
import { setCookie, getCookie } from "../../utils/CookieHandler";

export const LOGIN_USER = "LOGIN_USER";
export const STOP_LOADER = "STOP_LOADER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_LOGIN = "SET_LOGIN";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_CITIES = "GET_CITIES";
export const SET_SIGNUPPAGE = "SET_SIGNUPPAGE";

export const loginUser = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("auth/login", data);
    if (res) {
      console.log("data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};
export const resendOtp = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("auth/resend_otp", data);
    if (res) {
      console.log("data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const validateLoginOTP = async (data) => {
  console.log("in_book_action");
  const res = await AuthInstance.post("auth/verifyingOTP", data);
  console.log(res);
  if (res) {
    console.log("data_is_here");
    setCookie("token", res.data.token);
    setCookie("user", JSON.stringify(res.data.data));
    return res.data;
  }
};

export const getProfile = async () => {
  const res = await AuthInstance.get("auth/getProfile");
  if (res) {
    console.log("user_data_is_here", res.data);
    setCookie("user", JSON.stringify(res.data.data));
    return res.data.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
    return null;
  }
};

export const SetSignupPage = (res) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SIGNUPPAGE,
      payload: res,
    });
  };
};
export const getCities = () => {
  try {
    return async (dispatch) => {
      console.log("in_book_action");
      const res = await AuthInstance.get("api/fetch-cities");
      if (res) {
        dispatch({
          type: GET_CITIES,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const SignUpUserAPI = async (data) => {
  try {
    const res = await AuthInstance.post("auth/updateUser", data);
    if (res) {
      console.log("data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return error;
  }
};

export const setUserLogin = () => {
  console.log("Set_user_logoinisn");
  try {
    return async (dispatch) => {
      const d = getCookie("user");
      const data = JSON.parse(d);
      console.log("set_user_login", data);
      dispatch({
        type: SET_LOGIN,
        payload: data,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getUserAddress = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("user/list-address");
      if (res) {
        console.log("set_user_login", res.data);
        dispatch({
          type: GET_ADDRESS,
          payload: res.data,
        });
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const addAddress = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("user/add-address", data);
    if (res) {
      console.log("data_is_here", res.data);
      toast("Address added successfully", {
        progressStyle: { background: "#eba300" },
      });
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const deleteAddress = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("api/delete_address", data);
    if (res) {
      toast(res.data.message, {
        progressStyle: { background: "#eba300" },
      });
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const updateAddress = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("user/update-address", data);
    if (res) {
      console.log("data_is_here", res.data);
      toast("Address updated successfully", {
        progressStyle: { background: "#eba300" },
      });
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const primaryAddress = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("user/make-address-primary", data);
    if (res) {
      console.log("data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const logOutApi = () => {
  return async (dispatch) => {
    console.log("in_logout_function");
    const res = { token: null };
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.clear();
    dispatch({
      type: LOGOUT_USER,
      payload: res,
    });
  };
};

export const createKycOrder = async (data) => {
  try {
    console.log("in_login_action");
    const res = await AuthInstance.post("auth/createKycOrder", {});
    if (res) {
      console.log("data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};
