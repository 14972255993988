import React, { useState, useEffect } from "react";
import { getMyLoans } from "../../redux/actions/LoanAction";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import Empty from "../../assets/lottie/Empty.jsx";
import Loader from "../../assets/lottie/Loader.jsx";
import { useNavigate } from "react-router-dom";

const MyLoans = () => {
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    _getLoans();
  }, []);
  const [data, setData] = useState([]);

  const _getLoans = async () => {
    const res = await getMyLoans();
    if (res) {
      setloading(false);
      setData(res);
      console.log(res);
    } else {
      setloading(false);
    }
  };

  return (
    <div className="bg-white mrlb-5per mt-10 box-shadow">
      <TitleWithSeemore title="My Loans" viewall={false} />
      {loading ? (
        <div className="my-20">
          <Loader size={200} />
        </div>
      ) : (
        <div className="flex flex-wrap p-2 md:p-5 justify-between">
          {data?.length === 0 ? (
            <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
              <Empty />
              <div className="flex flex-col">
                <span className="no-asset mt-10">You have no Active Loans</span>
              </div>
            </div>
          ) : (
            data?.map((asset) => {
              if (asset?.folderId) {
                return (
                  <div
                    onClick={() => {
                      localStorage.setItem("loandetail", JSON.stringify(asset));
                      navigate("/myloandetail");
                    }}
                    key={asset.id}
                    className="active-loan-card double-card"
                  >
                    <div className="flex items-center justify-between mb-6 border-bottom">
                      <div className="flex flex-col items-center gap-1">
                        <span className="loan-main-text">
                          {"₹ " +
                            parseFloat(asset?.totalWithdrawals).toLocaleString(
                              "en-IN"
                            )}
                        </span>
                        <span className="loan-sub-text">loan amount</span>
                      </div>
                      <div className="flex flex-col items-center gap-1">
                        <span className="loan-main-text">
                          {asset?.nextRepaymentDate === null
                            ? "No Due"
                            : asset?.nextRepaymentDate?.slice(0, 10)}
                        </span>
                        <span className="loan-sub-text">Due date</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col items-center gap-1">
                        <span className="loan-main-text">{asset.folderId}</span>
                        <span className="loan-sub-text">loan ID</span>
                      </div>
                      <div className="flex flex-col items-center gap-1">
                        <span className="loan-main-text">
                          {parseFloat(asset?.totalNetWeight).toLocaleString(
                            "en-IN"
                          ) + " gms"}
                        </span>
                        <span className="loan-sub-text">Jewellery</span>
                      </div>
                      <div className="flex flex-col items-center gap-1">
                        <span className="loan-main-text">
                          {"₹ " +
                            parseFloat(asset?.foreclosureAmount).toLocaleString(
                              "en-IN"
                            )}
                        </span>
                        <span className="loan-sub-text">Closing Amount</span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
          )}
        </div>
      )}
    </div>
  );
};

export default MyLoans;
