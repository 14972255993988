import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import KYC from "../../assets/lottie/Kyc.jsx";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { createKycOrder } from "../../redux/actions/AuthAction.js";
import Loader from "../../assets/lottie/Loader.jsx";

const StartKyc = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState();
  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title="Verify Identity" viewall={false} onBack={true} />
      <div
        style={{
          padding: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <div>
          <KYC size={window.innerWidth > 800 ? 500 : 300} />
        </div>
        <div
          style={{
            width: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <div className="profile-text mb-6" style={{ textAlign: "center" }}>
            Verify Identity !
          </div>
          <div
            className="profile-sub-text mb-10"
            style={{ textAlign: "center" }}
          >
            Please verify your identity to buy and sell Jewellery. It will just
            take only 2 minutes to verify !
          </div>
          {loading ? (
            <Loader size={200} />
          ) : (
            <button
              onClick={async () => {
                const res = await createKycOrder();
                setloading(true);
                if (res) {
                  setloading(false);
                  window.open(res.data.url);
                  // navigate("/KycWebView");
                }
              }}
              className="next-auth-button"
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartKyc;
