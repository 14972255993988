import React from "react";
import { useNavigate } from "react-router-dom";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";

const OrdersCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      className="add-white-card p-2 md:p-5 flex mb-6 items-center flex double-card flex-wrap items-center cursor-pointer"
      onClick={() => {
        navigate(`/orderdetail/${data.order_id}`);
      }}
    >
      <div className="listing_title mb-4 md:mb-2">#{data.order_id}</div>
      <div className="ml-0 md:m-4 flex items-center justify-between min-w-40">
        <div className="flex">
          <img className="orders-image" src={data.image_url} alt="" />
          <div className="ml-4 md:ml-12">
            <div className="listing_name mb-2 flex items-center">
              {data.jewellery_name}
              <div className="ml-2 gross_weight">
                ({data?.jewellery_gross_weight}GM)
              </div>
            </div>
            <div className="flex">
              <div>
                <span className="listing_price_dark">
                  ₹ {data?.deal_amount}
                </span>
                <div className="listing_title">Deal amount</div>
              </div>
            </div>
            {data?.scheme_id === 0 ? (
              data?.upcoming_payment_status === 0 ? (
                <div className="flex mt-4 items-center">
                  <div className="green-dot"></div>
                  <div className="ml-4 text-gray-600 text-sm">
                    All payment is completed
                  </div>
                </div>
              ) : (
                <div className="flex mt-4 items-center">
                  <div className="yellow-dot"></div>
                  <div className="ml-4 text-gray-600 text-sm">
                    Pay remaining amount
                  </div>
                </div>
              )
            ) : (
              <>
                <div className="flex mt-4 items-center">
                  <div className="yellow-dot"></div>
                  <div className="ml-4 text-gray-600 text-sm">
                    Next installment on {data?.next_installment_date}
                  </div>
                </div>
                <div className="text-xs ml-6 text-gray-400">
                  (Payment of ₹{data?.next_installment_amount})
                </div>
              </>
            )}
          </div>
        </div>
        <div className="">
          <PieChart
            style={{
              fontFamily:
                '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
              fontSize: "8px",
              height: "100px",
              width: "100px",
              marginTop: "20px",
              flex: 1,
            }}
            radius={pieChartDefaultProps.radius - 6}
            lineWidth={50}
            segmentsStyle={{
              transition: "stroke .3s",
              cursor: "pointer",
            }}
            animate
            label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
            labelPosition={100 - 50 / 2}
            labelStyle={{
              fill: "#fff",
              pointerEvents: "none",
            }}
            data={
              data?.scheme_id === 0
                ? [
                    {
                      title: "Paid amount",
                      value: data?.paid_amount,
                      color: "green",
                    },
                    {
                      title: "Total due",
                      value: data?.total_due,
                      color: "#eba300",
                    },
                  ]
                : [
                    {
                      title: "Paid amount",
                      value: data?.paid_installments,
                      color: "green",
                    },
                    {
                      title: "Total due",
                      value: data?.total_installment - data?.paid_installments,
                      color: "#eba300",
                    },
                  ]
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;
