import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  deleteAddress,
  getCities,
  getUserAddress,
} from "../redux/actions/AuthAction";
import CustomAlert from "./CustomAlert";

const UpdateAddressModal = ({
  setShowModal,
  showModal,
  setValue,
  value,
  onClick,
  resetState,
  id,
}) => {
  const dispatch = useDispatch();
  const { address_line_1, address_line_2, pincode, landmark, friendly_name } =
    value;
  console.log(value);
  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const [show, setshow] = useState(false);

  const DeleteAddress = () => {
    deleteAddress({ address_id: id }).then((res) => {
      if (res) {
        dispatch(getUserAddress());
        setShowModal(false);
        setshow(false);
        resetState();
      }
    });
  }

  const { cities } = useSelector((state) => state?.authReducer);
  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <CustomAlert
          show={show}
          setShow={setshow}
          buttonText="Delete"
          text="Are you sure you want to delete ?"
          onClick={() => {
           DeleteAddress();
          }}
        />
        <div className="custom-modal relative bg-white rounded-lg shadow">
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">
              Add Address
            </h3>
            <button
              onClick={() => {
                setshow(true);
              }}
            >
              <RiDeleteBinLine size={30} color="#eba300" />
            </button>
          </div>
          {/* ---------- main body--------- */}
          <div className="flex flex-col justify-center">
            <input
              type="text"
              className="custom-input-address"
              placeholder="House / Flat / Floor no"
              required
              value={address_line_1}
              onChange={(e) =>
                setValue({ ...value, address_line_1: e.target.value })
              }
            />
            <input
              type="text"
              className="custom-input-address"
              placeholder="Apartment / Road / Area"
              required
              value={address_line_2}
              onChange={(e) =>
                setValue({ ...value, address_line_2: e.target.value })
              }
            />
            <div className="flex m-auto w-90per">
              <div className="flex flex-2 items-end justify-center">
                <select
                  className="select-city"
                  onChange={(e) => {
                    let cityid;
                    cities.map((i) => {
                      if (i.name === e.target.value) {
                        cityid = i.id;
                        return null;
                      }
                      return null;
                    });
                    setValue({ ...value, city: cityid });
                  }}
                >
                  {cities?.map((i) => (
                    <option id={i.id}>{i.name}</option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                className="custom-input-address flex-1"
                placeholder="Pin Code"
                required
                value={pincode}
                onChange={(e) =>
                  setValue({
                    ...value,
                    pincode: e.target.value.replace(/[^0-9]/g, ""),
                  })
                }
              />
            </div>
            <div className="flex m-auto w-90per">
              <input
                type="text"
                className="custom-input-address mr-10"
                placeholder="LandMark"
                required
                value={landmark}
                onChange={(e) =>
                  setValue({ ...value, landmark: e.target.value })
                }
              />
              <input
                type="text"
                className="custom-input-address"
                placeholder="Home / Office"
                required
                value={friendly_name}
                onChange={(e) =>
                  setValue({ ...value, friendly_name: e.target.value })
                }
              />
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2">
            <button
              onClick={onClick}
              disabled={
                address_line_1.trim() !== "" &&
                address_line_2.trim() !== "" &&
                landmark.trim() !== "" &&
                pincode !== "" &&
                friendly_name.trim() !== ""
                  ? false
                  : true
              }
              className={
                address_line_1.trim() !== "" &&
                address_line_2.trim() !== "" &&
                landmark.trim() !== "" &&
                pincode !== "" &&
                friendly_name.trim() !== ""
                  ? "upload-button"
                  : "disabled-upload-button"
              }
            >
              Update Address
            </button>
            <button
              onClick={() => {
                setShowModal(false);
                resetState();
              }}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default UpdateAddressModal;
