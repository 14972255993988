import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./css/index.css";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "G-K3HQ0V0Z62",
};

// tracking utm and ip

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
