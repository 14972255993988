import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddAddressModal from "../../components/AddAddressModal";
import Empty from "../../assets/lottie/Empty";
import { addAddress, getUserAddress } from "../../redux/actions/AuthAction";
import { makeInstantBuy } from "../../redux/actions/MarketAction";
import { toast, ToastContainer } from "react-toastify";
import useRazorpay from "react-razorpay";
import { getCookie } from "../../utils/CookieHandler";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../assets/lottie/Loader.jsx";
import TitleWithSeemore from "../../components/TitleWithSeemore";

const BuyInstantAddress = () => {
  const RAZORPAY_API_KEY = localStorage.getItem("razorpaykey");
  const { id, scheme } = useParams();
  const navigate = useNavigate();
  const { userAddresses } = useSelector((state) => state.authReducer);
  const [showAddModal, setShowAddModal] = useState(false);
  const [primary, setPrimary] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    address_line_1: "",
    address_line_2: "",
    landmark: "",
    friendly_name: "",
    city: 1,
    pincode: "",
    type: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);
  // add adress-----------
  const AddAddress = () => {
    const res = addAddress(data);
    if (res) {
      dispatch(getUserAddress());
      setShowAddModal(false);
      setData({
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        friendly_name: "",
        city: 1,
        pincode: "",
        type: 0,
      });
    }
  };
  const setPrimaryAddress = (id) => {
    setPrimary(id);
  };

  //   -----payment function------

  const Razorpay = useRazorpay();

  const _onPress = async () => {
    setLoading(true);
    const formdata = {
      listing_id: id,
      scheme_id: scheme,
      address_id: primary,
    };
    const res = await makeInstantBuy(formdata);
    if (res) {
      const id = res.data.rzp_order_id;
      const data = JSON.parse(getCookie("user"));
      await _buyItem(data, id);
    }
  };

  const _buyItem = async (data, id) => {
    const options = {
      key: RAZORPAY_API_KEY,
      currency: "INR",
      name: "Ruptok",
      description: "Jewellery",
      image: "https://py.ruptokcap.in/ruptoklogo.png",
      order_id: id,
      prefill: {
        name: `${data.first_name}  ${data.last_name}`,
        email: data.email,
        contact: data.phone,
      },
      theme: { color: "#F37254" },
      handler: (res) => {
        console.log(res);
        navigate("/myorders");
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
    setLoading(false);
  };
  return (
    <div className="container mx-auto p-5per bg-white mt-5">
      <TitleWithSeemore title="Select Address" viewall={false} onBack={true} />
      <ToastContainer />
      <div className="flex flex-wrap p-2 md:p-5 justify-between">
        {userAddresses?.length === 0 ? (
          <div className="container no-biddings mb-20">
            <Empty />
            <span className="text-slate-300 text-xs md:text-sm mt-4">
              Currently there is no address Add an address
            </span>
          </div>
        ) : null}
        {userAddresses ? (
          userAddresses?.map((i) => {
            return (
              <div
                onClick={() => setPrimaryAddress(i.id)}
                key={i.id}
                className="add-white-card p-2 md:p-5 flex mb-6 items-center flex double-card flex-wrap items-center"
              >
                <div className="mr-5 md:mr-10">
                  <div
                    className={
                      primary === i.id ? "selected-radio-icon" : "radio-icon"
                    }
                  ></div>
                </div>
                <div>
                  <div className="address_title">{i.friendly_name}</div>
                  <div className="address_name">
                    {i.address_line_1 + " " + i.address_line_2}
                  </div>
                  <div className="address_name">{i.landmark}</div>
                  <div className="address_name">
                    {i.city_name + " - " + i.pincode}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Loader size={100} />
        )}
      </div>
      <div className="flex">
        <button
          onClick={() => {
            setShowAddModal({ show: true });
          }}
          className="sticky-white-button ml-2 md:ml-10"
        >
          Add Address
        </button>

        <button
          onClick={() => {
            if (primary === undefined) {
              toast("Select an address", {
                progressStyle: { background: "#eba300" },
              });
            } else {
              _onPress();
            }
          }}
          className="upload-button ml-2 md:ml-10"
        >
          {loading ? <Loader size={40} /> : "Buy Now"}
        </button>
      </div>
      <AddAddressModal
        setShowModal={setShowAddModal}
        showModal={showAddModal}
        setValue={setData}
        value={data}
        onClick={AddAddress}
      />
    </div>
  );
};

export default BuyInstantAddress;
