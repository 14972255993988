import React, { useState, useEffect } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentDetails } from "../../redux/actions/AssetAction";
import Confirm from "../../assets/lottie/Confirm.jsx";
import Moment from "moment";
import { VscCopy } from "react-icons/vsc";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";

const PaymentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    PaymentDetails(id).then((res) => {
      if (res.data.message === "payment details not found") {
        navigate("/payments");
      } else {
        setOrderData(res.data.data.order_details);
        setPaymentData(res.data.data.payment_details);
      }
    });
  }, []);

  return (
    <div className="bg-white mrlb-5per mt-6">
      <ToastContainer />
      <TitleWithSeemore
        title={`Payment details`}
        viewall={false}
        onBack={true}
      />
      <div className="flex flex-1 flex-wrap p-0 md:px-10 md:py-5">
        <div className="product-container">
          <img
            src={orderData?.image_url}
            alt="Product"
            className="product-image"
          />
        </div>
        <div className="flex-1 flex flex-wrap pl-2">
          <div className="white-card">
            <div className="product-title mb-2 flex items-center">
              <div>
                Paid
                <span className="text-yellow-500 ml-3">
                  ₹{paymentData?.amount}
                </span>
              </div>
              <div className="ml-5">
                <Confirm size={60} />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-xl font-bold text-gray-600">
                {paymentData?.type === 0
                  ? "Advance Amount Paid"
                  : paymentData?.type === 1
                  ? "Installment Paid"
                  : "Full Amount Paid"}
              </div>
              <div className="text-lg font-bold text-gray-400">
                on {Moment(paymentData?.created_at).format("LL")}
              </div>
            </div>
            <div className="mt-5 flex flex-col">
              <div className="text-xl font-bold text-gray-600">
                Transaction ID
              </div>
              <div className="text-lg font-medium text-gray-400 flex items-center">
                {paymentData?.rzp_payment_id}
                <CopyToClipboard
                  text={paymentData?.rzp_payment_id}
                  onCopy={() => {
                    toast("Transaction ID copied", {
                      progressStyle: { background: "#eba300" },
                    });
                  }}
                >
                  <VscCopy size={25} color="#000" className="ml-4" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="white-card mt-4">
            <div className="flex items-center justify-between w-full">
              <div className="product-title mb-4">Order Details</div>
              <div
                className="text-yellow-500 text-xs font-bold mb-2 cursor-pointer"
                onClick={() => {
                  navigate(`/orderdetail/${paymentData.order_id}`);
                }}
              >
                View Details
              </div>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Jewellery Name </span>
              <span className="detail-value">
                : {orderData?.jewellery_name}
              </span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Order ID </span>
              <span className="detail-value">: {orderData?.order_id}</span>
            </div>
            <div className="flex justify-between mt-1 mb-4">
              <span className="detail-name">Gross Weight </span>
              <span className="detail-value">
                : {orderData?.jewellery_gross_weight} GM
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
