import {
  GET_MARKETPLACE_DATA,
  GET_ITEM_BIDS,
  GET_JEWELS_CATEGORY,
  MORE_MARKETPLACE_DATA,
} from "../actions/MarketAction";

const initialState = {
  marketPlaceData: [],
  marketPlacepageData: {},
  bidList: [],
  jewelsCategory: null,
};

function marketReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MARKETPLACE_DATA:
      return {
        ...state,
        marketPlaceData: action.payload.data,
        marketPlacepageData: action.payload,
      };
    case MORE_MARKETPLACE_DATA:
      const array = [...state.marketPlaceData, ...action.payload.data];
      return {
        ...state,
        marketPlaceData: array,
      };
    case GET_ITEM_BIDS:
      return { ...state, bidList: action.payload.data };
    case GET_JEWELS_CATEGORY:
      return { ...state, jewelsCategory: action.payload.data };
    default:
      return state;
  }
}

export default marketReducer;
