import React, { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/CookieHandler";
import {
  FiLogOut,
  FiCreditCard,
  FiPieChart,
  FiBox,
  FiUser,
  FiChevronRight,
  FiChevronDown,
  FiPhone,
  FiMail,
  FiHome,
  FiBriefcase,
} from "react-icons/fi";
import { HiOutlineKey } from "react-icons/hi";
import { BiRupee } from "react-icons/bi";
import { SiHandshake } from "react-icons/si";
import { FaRegAddressCard, FaRegMoneyBillAlt } from "react-icons/fa";
import { logOutApi } from "../../redux/actions/AuthAction";
import { SetSignupPage } from "../../redux/actions/AuthAction";

const SideDrawer = ({ show, click }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let drawerClasses = ["side-drawer"];

  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  useEffect(() => {
    const res = getCookie("user");
    if (res) {
      setUserInfo(JSON.parse(res));
    }
  }, [isLoggedIn]);

  if (show) {
    drawerClasses = ["side-drawer", "open"];
  }
  return (
    <nav className={drawerClasses.join(" ")}>
      <div className="add-white-card px-0 md:px-5 md:m-auto mx-4 mrlb-5per mt-5 md:mt-10">
        <div className="flex justify-between items-start p-4 rounded-t border-b">
          <h3 className="text-xl  font-semibold text-black-900">
            Hi,{" "}
            {userInfo?.first_name
              ? userInfo?.first_name + " " + userInfo?.last_name
              : "User"}
          </h3>
          <button onClick={click}>
            <GrClose size={20} />
          </button>
        </div>
        {/* ---------- main body--------- */}
        {!isLoggedIn ? (
          <div className="flex justify-center flex-col p-6 gap-6">
            <button
              onClick={() => {
                navigate("/");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiHome className="profile-icon" />
                <span className="profile-text">Home</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/market");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <img
                  src={require("../../assets/icons/market.png")}
                  className="profile-icon"
                  alt=""
                />
                <span className="profile-text">Marketplace</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/portfolio");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiBriefcase className="profile-icon" />
                <span className="profile-text">Schemes</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/addresses");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FaRegAddressCard className="profile-icon" />
                <span className="profile-text">Apply Now</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <div className="flex items-center justify-between mt-5">
              <NavLink
                to="/"
                className="signup-button flex flex-1 mr-2"
                onClick={() => {
                  dispatch(SetSignupPage(false));
                }}
              >
                Login
              </NavLink>

              {/* <NavLink
                to="/"
                onClick={() => {
                  // dispatch(SetSignupPage(true));
                }}
                className="signup-button flex flex-1 ml-2"
              >
                Sign up
              </NavLink> */}
            </div>
          </div>
        ) : (
          <div className="flex justify-center flex-col p-6 gap-6">
            <button
              onClick={() => setShowInfo(!showInfo)}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiUser className="profile-icon" />
                <span className="profile-text">Your Info </span>
              </div>
              {showInfo ? (
                <FiChevronDown className="profile-icon" />
              ) : (
                <FiChevronRight className="profile-icon" />
              )}
            </button>
            {showInfo ? (
              <div className="w-full ml-10">
                <div className="flex items-center gap-5 mb-4">
                  <FiPhone className="profile-icon" />
                  <span className="profile-sub-text">{userInfo?.phone}</span>
                </div>
                <div className="flex items-center gap-5 mb-4">
                  <FiMail className="profile-icon" />
                  <span className="profile-sub-text">{userInfo?.email}</span>
                </div>
                {userInfo?.is_business === 1 ? (
                  <div className="flex items-center gap-5">
                    <SiHandshake className="profile-icon" />
                    <span className="profile-sub-text">Buisness Account</span>
                  </div>
                ) : null}
              </div>
            ) : null}
            <button
              onClick={() => {
                navigate("/");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiHome className="profile-icon" />
                <span className="profile-text">Home</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() =>
                userInfo?.is_kyc === 0
                  ? navigate("/startkyc")
                  : navigate("/verifiedkyc")
              }
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <HiOutlineKey className="profile-icon" />
                <span className="profile-text">E KYC</span>
              </div>
              {userInfo?.is_kyc === 0 ? (
                <div className="pending-tag">Pending</div>
              ) : (
                <div className="verified-tag">Verified</div>
              )}
            </button>
            <button
              onClick={() => {
                navigate("/market");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <img
                  src={require("../../assets/icons/market.png")}
                  className="profile-icon"
                  alt=""
                />
                <span className="profile-text">Market</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/portfolio");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiBriefcase className="profile-icon" />
                <span className="profile-text">Portfolio</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/addresses");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FaRegAddressCard className="profile-icon" />
                <span className="profile-text">Addresses</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/mybids");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FaRegMoneyBillAlt className="profile-icon" />
                <span className="profile-text">Bids</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/myorders");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiBox className="profile-icon" />
                <span className="profile-text">Orders</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/payments");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <BiRupee className="profile-icon" />
                <span className="profile-text">Payments</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/mylistings");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiPieChart className="profile-icon" />
                <span className="profile-text">Listings</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                navigate("/myloans");
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiCreditCard className="profile-icon" />
                <span className="profile-text">Loans</span>
              </div>
              <FiChevronRight className="profile-icon" />
            </button>
            <button
              onClick={() => {
                dispatch(logOutApi());
                click();
              }}
              className="flex justify-between items-center"
            >
              <div className="flex items-center justify-center gap-5">
                <FiLogOut className="profile-icon" />
                <span className="profile-text">Log out</span>
              </div>
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};
export default SideDrawer;
