import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getNextDate } from "../../redux/actions/LoanAction";
import next from "../../assets/icons/next.png";

const nonTime = [
  {
    id: 0,
    Time: "12:00",
  },
  {
    id: 1,
    Time: "12:30",
  },
  {
    id: 2,
    Time: "01:00",
  },
  {
    id: 3,
    Time: "01:30",
  },
  {
    id: 4,
    Time: "02:00",
  },
  {
    id: 5,
    Time: "02:30",
  },
  {
    id: 6,
    Time: "03:00",
  },
  {
    id: 7,
    Time: "03:30",
  },
];

const eveningTime = [
  {
    id: 8,
    Time: "04:00",
  },
  {
    id: 9,
    Time: "04:30",
  },
  {
    id: 10,
    Time: "05:00",
  },
  {
    id: 11,
    Time: "05:30",
  },
];

const morningTime = [
  {
    id: 12,
    Time: "09:30",
  },
  {
    id: 13,
    Time: "10:00",
  },
  {
    id: 14,
    Time: "10:30",
  },
  {
    id: 15,
    Time: "11:00",
  },
  {
    id: 16,
    Time: "11:30",
  },
];

const SelectDateTime = () => {
  const { days } = useSelector((state) => state.loanReducer);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNextDate());
  }, []);
  return (
    <div className="add-white-card mrlb-5per mt-5 md:mt-10 p-5per">
      <div className="add-white-card  px-2 md:px-10">
        Select Date
        <div className="flex overflow-scroll px-2  md:px-10">
          {days?.map((i) => (
            <div
              onClick={() => {
                setSelectedDay(i);
                if (selectedTime !== null) setDisabled(false);
              }}
              key={i.date}
              className={
                i?.date === selectedDay?.date
                  ? "selected-date-container"
                  : "date-container"
              }
            >
              <span
                className={
                  i?.date === selectedDay?.date
                    ? "selected-week-text"
                    : "select-week-text"
                }
              >
                {i.week}
              </span>
              <span
                className={
                  i?.date === selectedDay?.date
                    ? "selected-day-text"
                    : "select-day-text"
                }
              >
                {i.day}
              </span>
              <span
                className={
                  i?.date === selectedDay?.date
                    ? "selected-month-text"
                    : "select-month-text"
                }
              >
                {i.month}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="add-white-card p-5per flex-col flex mt-5">
        Select Time Slot
        <div className="select-day-text my-2">Morning</div>
        <div className="flex flex-wrap">
          {morningTime?.map((i) => (
            <span
              onClick={() => {
                setSelectedTime(i);
                if (selectedDay !== null) setDisabled(false);
              }}
              key={i.id}
              className={
                i?.id === selectedTime?.id
                  ? "selected-time-text"
                  : "select-time-text"
              }
            >
              {i.Time}
            </span>
          ))}
        </div>
        <div className="select-day-text my-2">Noon</div>
        <div className="flex flex-wrap">
          {nonTime?.map((i) => (
            <span
              onClick={() => {
                setSelectedTime(i);
                if (selectedDay !== null) setDisabled(false);
              }}
              key={i.id}
              className={
                i?.id === selectedTime?.id
                  ? "selected-time-text"
                  : "select-time-text"
              }
            >
              {i.Time}
            </span>
          ))}
        </div>
        <div className="select-day-text my-2">Evening</div>
        <div className="flex flex-wrap">
          {eveningTime?.map((i) => (
            <span
              onClick={() => {
                setSelectedTime(i);
                if (selectedDay !== null) setDisabled(false);
              }}
              key={i.id}
              className={
                i?.id === selectedTime?.id
                  ? "selected-time-text"
                  : "select-time-text"
              }
            >
              {i.Time}
            </span>
          ))}
        </div>
      </div>
      <div className="sticky-white-card">
        <button
          disabled={disabled}
          onClick={() => {
            navigate(`/selectaddress/${id}`);
          }}
          className={disabled ? "disabled-upload-button" : "upload-button"}
        >
          Select Address
          <img alt="next" src={next} className="ml-4" />
        </button>
      </div>
    </div>
  );
};

export default SelectDateTime;
