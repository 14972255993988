import React from "react";
// import JewelleryDetailsCard from "../JewelleryDetailsCard";
import TitleWithSeemore from "../TitleWithSeemore";
import noJewellery from "../../assets/images/no-jewellery.png";
import { FiChevronDown } from "react-icons/fi";
import Loader from "../../assets/lottie/Loader.jsx";
import JewelleryCard from "./JewelleryCard";

const MyJewellery = ({ data, pageChanged, loading, loadMore, total }) => {
  return (
    <>
      <div className="bg-white mrlb-5per box-shadow">
        <TitleWithSeemore
          title={`My jewellery (${total === undefined ? 0 : total})`}
          viewall={false}
          content={"Sale value may vary subject to the market value of article"}
        />

        {data === null ? (
          <Loader size={200} />
        ) : (
          <div className="flex flex-wrap p-2 md:p-5">
            {data.length === 0 ? (
              <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
                <div className="flex flex-col">
                  <span className="no-asset">
                    You have no Jewellery in your list
                  </span>
                  <span className="no-asset-content mt-6 mb-10">
                    Looks for jewellery in market place to add them to your
                    List.{" "}
                  </span>
                </div>
                <img src={noJewellery} alt="no asset" />
              </div>
            ) : (
              data.map((asset) => {
                // return <JewelleryDetailsCard asset={asset} key={asset.id} />;
                return <JewelleryCard data={asset} key={asset.id} />;
              })
            )}
            {loading ? (
              <Loader size={100} />
            ) : loadMore ? (
              <div
                onClick={() => {
                  pageChanged();
                }}
                className="load-more-btn"
              >
                <h2>Load more</h2>
                <FiChevronDown className="profile-icon ml-4" />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default MyJewellery;
