import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils/CookieHandler";

const SellModal = ({
  setShowModal,
  showModal,
  setAskingPrice,
  type,
  setType,
  bind,
  setBind,
  AskingPrice,
  date,
  setDate,
  onClick,
  data,
  makingCharges,
  stoneCharges,
  setMakingCharges,
  setStoneCharges,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [IsBuisness, setIsBuisness] = useState(null);

  const formatDate = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  useEffect(() => {
    const res = getCookie("user");
    if (res) {
      const data = JSON.parse(res);
      console.log(data);
      setIsBuisness(data?.is_business);
    }
  }, [showModal]);

  useEffect(() => {
    if (
      parseInt(AskingPrice) >= parseInt(data?.min_listing_price) &&
      parseInt(AskingPrice) <= parseInt(data?.max_listing_price) &&
      date?.trim() !== "" &&
      (bind === 0 || bind === 1)
    ) {
      if (type === 0) {
        setDisabled(false);
      } else {
        if (stoneCharges !== "" && makingCharges !== "") {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } else setDisabled(true);
  }, [AskingPrice, date, bind, type, stoneCharges, makingCharges, data]);

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          {/* <div className="flex justify-between items-start p-4 rounded-t border-b">
            <h3 className="text-xl font-semibold text-black-900">
              Sell this item
            </h3>
          </div> */}
          {/* ---------- main body--------- */}
          <div className="flex flex-col justify-center pb-6">
            <label className="input-label">Asking Price</label>
            <input
              type="text"
              className="custom-input"
              placeholder="Enter Asking Price"
              value={AskingPrice}
              onChange={(e) => {
                setAskingPrice(e.target.value);
              }}
            />
            <div className="info-red-text">
              Amount shouldn't be smaller than
              {" ₹" + data?.min_listing_price} and greater than
              {" ₹" + data?.max_listing_price}
            </div>
            <label className="input-label mt-4">
              Select date to end listing
            </label>
            <input
              type="date"
              min={formatDate()}
              value={date}
              className="custom-input"
              placeholder="Select Date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                width: "90%",
                alignSelf: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <label className="input-label ml-0 mt-4">
                  Bind asking price with market price
                </label>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    onClick={() => {
                      setBind(1);
                    }}
                    className={
                      bind === 1 ? "selected-select-option" : "select-option"
                    }
                  >
                    Yes
                  </div>
                  <div
                    onClick={() => {
                      setBind(0);
                    }}
                    className={
                      bind === 0 ? "selected-select-option" : "select-option"
                    }
                  >
                    no
                  </div>
                </div>
              </div>
              {IsBuisness === 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <label className="input-label ml-0 mt-4">
                    Select Jewellery type
                  </label>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div
                      onClick={() => {
                        setType(0);
                      }}
                      className={
                        type === 0 ? "selected-select-option" : "select-option"
                      }
                    >
                      Pre-Owned
                    </div>
                    <div
                      onClick={() => {
                        setType(1);
                      }}
                      className={
                        type === 1 ? "selected-select-option" : "select-option"
                      }
                    >
                      New
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {type === 1 ? (
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  alignSelf: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <label className="input-label ml-0">Making Charges (%)</label>
                  <input
                    type="number"
                    className="custom-input h-10 mt-2 ml-0"
                    placeholder="Enter Making Charges"
                    value={makingCharges}
                    onChange={(e) => {
                      setMakingCharges(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <label className="input-label ml-0">Stone Charges (₹)</label>
                  <input
                    type="number"
                    className="custom-input h-10 mt-2 ml-0"
                    placeholder="Enter Stone Charges"
                    value={stoneCharges}
                    onChange={(e) => {
                      setStoneCharges(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200">
            <button
              disabled={disabled}
              onClick={() => {
                onClick();
                setShowModal(false);
              }}
              className={disabled ? "disabled-upload-button" : "upload-button"}
            >
              Sell item
            </button>
            <button
              onClick={() => {
                setAskingPrice();
                setDate("");
                setShowModal(false);
                setDisabled(true);
              }}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default SellModal;
