import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "../components/header/Header";
import Home from "../screens/home";
import Portfolio from "../screens/portfolio/Portfolio";
import Market from "../screens/market/Market";
import MarketProduct from "../screens/market/MarketProduct";
import PortfolioProduct from "../screens/portfolio/PortfolioProduct";
import Addresses from "../screens/Addresses";
import SellItemAddress from "../screens/portfolio/SellItemAddress";
import JewelleryClarity from "../screens/AddJewellery/JewelleryClarity";
import JewelleryType from "../screens/AddJewellery/JewelleryType";
import JewelleryInfo from "../screens/AddJewellery/JewelleryInfo";
import MyListings from "../screens/profile/MyListings";
import MyBids from "../screens/profile/MyBids";
import MyLoans from "../screens/loans/MyLoans";
import SchemeSelection from "../screens/applyLoan/SchemeSelection";
import SelectAddress from "../screens/applyLoan/SelectAddress";
import SelectDateTime from "../screens/applyLoan/SelectDateTime";
import Success from "../screens/Success";
import MyLoanDetail from "../screens/loans/MyLoanDetail";
import ListingDetail from "../screens/profile/ListingDetail";
import BuyInstantAddress from "../screens/market/BuyInstantAddress";
import BidAddress from "../components/profile/BidAddress";
import EditProduct from "../screens/portfolio/EditProduct";
import StartKyc from "../screens/KYC/StartKyc";
import KycWebView from "../screens/KYC/KycWebView";
import VerifiedKyc from "../screens/KYC/VerifiedKyc";
import AllPayments from "../screens/payments/AllPayments";
import { MyOrders, OrderDetail } from "../screens/orders";
import BuySchemeOptions from "../screens/market/BuySchemeOptions";
import BidSchemeOptions from "../screens/profile/BidSchemeOptions";
import PaymentDetail from "../screens/payments/paymentDetails";

const Main = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* {width > 750 ? (
                    <Route path="/profile" element={<Profile />} />
                  ) : null} */}
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/market" element={<Market />} />
        <Route path="/marketproduct/:id" element={<MarketProduct />} />
        <Route
          path="/buyinstantaddress/:id/:scheme"
          element={<BuyInstantAddress />}
        />
        <Route path="/portfolioproduct/:id" element={<PortfolioProduct />} />
        <Route path="/editproduct/:id" element={<EditProduct />} />
        <Route path="/addresses" element={<Addresses />} />
        <Route path="/sellitemaddress" element={<SellItemAddress />} />
        <Route path="/mylistings" element={<MyListings />} />
        <Route path="/listingdetail/:id" element={<ListingDetail />} />
        <Route path="/mybids" element={<MyBids />} />
        <Route path="/bidaddress/:id/:scheme" element={<BidAddress />} />
        <Route path="/myorders" element={<MyOrders />} />
        <Route path="/orderdetail/:id" element={<OrderDetail />} />
        <Route path="/payments" element={<AllPayments />} />
        <Route path="/paymentdetail/:id" element={<PaymentDetail />} />
        <Route path="/myloans" element={<MyLoans />} />
        <Route path="/myloandetail" element={<MyLoanDetail />} />
        <Route path="/jewellerytype" element={<JewelleryType />} />
        <Route path="/jewelleryclarity" element={<JewelleryClarity />} />
        <Route path="/jewelleryinfo" element={<JewelleryInfo />} />
        <Route path="/schemeselection" element={<SchemeSelection />} />
        <Route path="/selectaddress/:id" element={<SelectAddress />} />
        <Route path="/selectdatetime/:id" element={<SelectDateTime />} />
        <Route path="/success" element={<Success />} />
        <Route path="/startkyc" element={<StartKyc />} />
        <Route path="/verifiedkyc" element={<VerifiedKyc />} />
        <Route path="/KycWebView" element={<KycWebView />} />
        <Route path="/buyschemes/:id" element={<BuySchemeOptions />} />
        <Route path="/bidschemes/:id" element={<BidSchemeOptions />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default Main;
