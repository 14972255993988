import React from "react";
import { useNavigate } from "react-router-dom";
import newTaG from "../../assets/icons/newtag.png";

const JewelleryCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(`/marketproduct/${data?.listing_id}`);
      }}
      className="flex flex-col my-2 md:my-4 ml-2 md:ml-8 rounded jewellery-card-new"
    >
      <div className="jewellery-image-container-new">
        {data?.listing_type === 1 ? (
          <img
            src={newTaG}
            alt="new"
            style={{
              height: 80,
              width: 80,
              position: "absolute",
              transform: "rotate(270deg)",
              marginTop: -10,
              marginLeft: -10,
              zIndex: 900,
            }}
          />
        ) : null}
        {data?.thumbnail_url ? (
          <img
            src={data?.thumbnail_url}
            alt="Jewellery"
            className="jewellery-image-new"
          />
        ) : (
          <img
            src={data?.image_url}
            alt="Jewellery"
            className="jewellery-image-new"
          />
        )}
        <div className="gradient-color"></div>
      </div>
      <div className="w-full p-3 card-info">
        <div className="card-title">
          {"₹ " +
            parseFloat(data?.asking_price).toLocaleString("en-IN", {
              maximumFractionDigits: 0,
            })}
        </div>
        <div
          className="flex items-center"
          style={{ justifyContent: "space-between" }}
        >
          <span className="asset-amount">{data?.name}</span>
          {/* <div className="weight-card"> */}
          <span className="asset-weight">{data?.net_weight} GM</span>
          {/* </div> */}
        </div>
      </div>
    </button>
  );
};

export default JewelleryCard;
