import React, { useEffect, useState } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { BsCheck2 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { BuyingSchemes } from "../../redux/actions/AssetAction";
import Loader from "../../assets/lottie/Loader.jsx";

const BuySchemeOptions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedSchemeId, setselectedSchemeId] = useState(1);
  const [data, setData] = useState();
  useEffect(() => {
    BuyingSchemes(id).then((res) => {
      setData(res.data.data);
    });
  }, []);
  return (
    <div className="bg-white mrlb-5per mt-6 pb-5">
      <TitleWithSeemore
        title="Gold saver scheme"
        viewall={false}
        onBack={true}
      />
      {data ? (
        <div className="flex flex-wrap flex-col items-center justify-center scheme-container">
          <div className="flex items-center jusitfy-center flex-col">
            <div className="text-gray-500 font-medium">Advance Amount</div>
            <div className="text-black text-4xl mt-2 font-extrabold">
              {"₹ " +
                parseFloat(
                  data?.scheme_option[selectedSchemeId]?.advance_amount
                ).toLocaleString("en-IN", {
                  maximumFractionDigits: 2,
                })}
            </div>
          </div>
          <div className="mt-10 pb-20 md:pb-2 w-full">
            {data?.scheme_option?.map((i) => (
              <div
                onClick={() => {
                  setselectedSchemeId(i.scheme_id);
                }}
                className={
                  selectedSchemeId === i?.scheme_id
                    ? "selected-scheme-full-card flex justify-between px-6 py-4 cursor-pointer mb-5"
                    : "scheme-full-card flex justify-between px-6 py-4 cursor-pointer mb-5"
                }
              >
                {selectedSchemeId === 1 ? (
                  i?.scheme_id === selectedSchemeId ? (
                    <div className="recommended-tag">RECOMMMENDED</div>
                  ) : null
                ) : null}
                <div
                  className={
                    selectedSchemeId === i?.scheme_id
                      ? "selected-radio-icon mr-6 mt-3"
                      : "radio-icon mr-6 mt-3"
                  }
                ></div>
                <div className="w-full h-full pt-2">
                  <div className="border-bottom pb-3 mb-3 flex items-center justify-between">
                    <div className="text-black text-sm font-bold">
                      {i?.scheme_name}
                    </div>
                    <div className="text-black text-sm font-bold">
                      {"₹ " +
                        parseFloat(i?.amount).toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        }) +
                        " "}
                      {i?.scheme_id === 0 ? null : "/ Month"}
                    </div>
                  </div>
                  <div>
                    {i.scheme_id === 0
                      ? i?.description.map((res) => (
                          <div className="text-gray-400 text-sm font-medium mt-2 flex items-center">
                            <BsCheck2
                              size={20}
                              className="mr-2"
                              color={
                                selectedSchemeId === i.scheme_id
                                  ? "#eba300"
                                  : null
                              }
                            />
                            {res}
                          </div>
                        ))
                      : i?.description.slice(1).map((res) => (
                          <div className="text-gray-400 text-sm font-medium mt-2 flex items-center">
                            <BsCheck2
                              size={20}
                              className="mr-2"
                              color={
                                selectedSchemeId === i.scheme_id
                                  ? "#eba300"
                                  : null
                              }
                            />
                            {res}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            ))}
            {/* {data?.scheme_option?.slice(1).map((res) => (
              <div
                onClick={() => {
                  setselectedSchemeId(res?.scheme_id);
                }}
                className={
                  res?.scheme_id === selectedSchemeId
                    ? "selected-scheme-sub-card flex items-center justify-between p-6 cursor-pointer"
                    : "scheme-sub-card flex items-center justify-between p-6 cursor-pointer"
                }
              >
                
                <div className="flex items-center">
                  <div
                    className={
                      res?.scheme_id === selectedSchemeId
                        ? "selected-radio-icon mr-6"
                        : "radio-icon mr-6"
                    }
                  ></div>
                  <div>
                    <div className="text-black font-bold">
                      {res?.tenure} Months
                    </div>
                    <div className="text-gray-400 text-sm font-medium mt-1">
                      {res?.scheme_name}
                    </div>
                  </div>
                </div>

                <div className="text-black font-bold">
                  {"₹ " +
                    parseFloat(res?.amount).toLocaleString("en-IN", {
                      maximumFractionDigits: 2,
                    })}
                  <span className="text-gray-500 font-medium">
                    <span className="mx-1">/</span>
                    month
                  </span>
                </div>
              </div>
            ))} */}
          </div>
          <div
            className="sticky-white-card px-4 md:px-0"
            onClick={() => {
              navigate(`/buyinstantaddress/${id}/${selectedSchemeId}`);
            }}
          >
            <button className="buy-button w-full">Save and continue</button>
          </div>
        </div>
      ) : (
        <Loader size={20} />
      )}
    </div>
  );
};

export default BuySchemeOptions;
