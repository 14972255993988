import React, { useEffect, useState } from "react";
import MyJewellery from "../../components/portfolio/MyJewellery";
import NetWorthCard from "../../components/home/NetWorthCard";
import { getPaginationAssets } from "../../redux/actions/AssetAction";

const Portfolio = () => {
  const [data, setdata] = useState(null);
  const [pagesData, setPagesData] = useState(null);
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPaginationAssets(1).then((res) => {
      setdata([...res.data]);
      setPagesData(res);
      if (res.current_page >= res.total_pages) {
        setLoadMore(false);
      }
    });
  }, []);

  const pageChanged = () => {
    setLoading(true);
    if (pagesData.current_page === pagesData.total_pages) {
      setLoadMore(false);
      setLoading(false);
    } else {
      getPaginationAssets(pagesData.current_page + 1).then((res) => {
        setdata([...data, ...res.data]);
        setPagesData(res);
        setLoading(false);
      });
    }
  };

  return (
    <div className="mt-4 md:mt-10">
      <NetWorthCard MyJewellery={false} />
      <div className="my-2" />
      <MyJewellery
        data={data}
        pageChanged={pageChanged}
        loadMore={loadMore}
        loading={loading}
        total={pagesData?.total_records}
      />
    </div>
  );
};

export default Portfolio;
