import React, { useEffect } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import Empty from "../../assets/lottie/Empty";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrders } from "../../redux/actions/AssetAction";
import OrdersCard from "../../components/profile/OrdersCard";
import SkeletonLoadingCards from "../../components/SkeletonLoadingCards";

const MyOrders = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);
  const { myOrders } = useSelector((state) => state?.assetsReducer);
  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title="My Orders" viewall={false} onBack={true} />
      {myOrders === null ? (
        <SkeletonLoadingCards />
      ) : (
        <div className="flex flex-wrap p-2 md:p-5 justify-between">
          {myOrders?.length === 0 ? (
            <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
              <Empty />
              <div className="flex flex-col">
                <span className="no-asset">You have no Orders</span>
              </div>
            </div>
          ) : myOrders ? (
            myOrders?.map((asset) => {
              return <OrdersCard data={asset} key={asset.id} />;
            })
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MyOrders;
