import React, { useState, useEffect } from "react";
import back from "../../assets/icons/back_asset.png";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAssets,
  getAllAssets,
  getUserPortfolio,
  updateAssets,
  UploadImages,
} from "../../redux/actions/AssetAction";
import { TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../assets/lottie/Loader.jsx";
import Add from "../../assets/icons/add.png";
import {
  getPortfolioProduct,
  removePortfolioImage,
} from "../../redux/actions/MarketAction";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import CustomAlert from "../../components/CustomAlert";

const EditProduct = () => {
  const { id } = useParams();
  useEffect(() => {
    getPortfolioProduct(id).then((res) => {
      setfile(res.data?.image_url);
      setImages(res.data?.jewellery_images);
      setNetWeight(res.data?.net_weight);
      setGrossWeight(res.data?.gross_weight);
      setname(res.data?.name);
      setDescription(res.data?.jewellery_description);
    });
  }, [id]);

  const [Loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [file, setfile] = useState(null);
  const [images, setImages] = useState(null);
  const [newimages, setNewImages] = useState([]);
  const [netWeight, setNetWeight] = useState(null);
  const [grossWeight, setGrossWeight] = useState(null);
  const [name, setname] = useState(null);
  const [description, setDescription] = useState(null);
  
  // const [type, setType] = useState(0);
  // const [bind, setBind] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // ------delete assets ------
  const DeleteAssets = () => {
    deleteAssets(id).then((res) => {
      if (res) {
        toast(res.message, {
          progressStyle: { background: "#eba300" },
        });
        console.log(res);
        navigate("/portfolio");
        setshow(false);
      }
    });
  };
  // ------handle press-----
  const _onPress = async () => {
    if (netWeight > grossWeight) {
      toast("Net weight can't be greater than gross weight", {
        progressStyle: { background: "#eba300" },
      });
      setLoading(false);
    } else if (parseFloat(netWeight) === 0) {
      toast("Net weight can't be Zero", {
        progressStyle: { background: "#eba300" },
      });
    } else if (netWeight > 10000 || grossWeight > 10000) {
      toast("Weight can't be greater than 10Kg", {
        progressStyle: { background: "#eba300" },
      });
    } else {
      setLoading(true);

      var data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("gross_weight", grossWeight);
      data.append("net_weight", netWeight);
      data.append("asset_id", id);
      data.append("jewellery_description", description);
      // data.append("listing_type", type);
      // data.append("market_bind_status", bind);
      if (typeof file === "string") {
        data.append("is_image", 0);
      } else {
        data.append("is_image", 1);
        data.append("image", file);
      }
      if (newimages.length > 0) {
        var imagesData = new FormData();
        imagesData.append("assetId", id);
        for (var i = 0; i < newimages.length; i++) {
          imagesData.append("images", newimages[i]);
        }
        await UploadImages(imagesData);
      }
      const res = await updateAssets(data);
      if (res) {
        toast(JSON.parse(res).message, {
          progressStyle: { background: "#eba300" },
        });
        setLoading(false);
        dispatch(getAllAssets());
        dispatch(getUserPortfolio());
        navigate("/portfolio");
      } else {
        toast("Some Error Occured", {
          progressStyle: { background: "#eba300" },
        });
      }
    }
  };

  const RemoveImage = (link) => {
    const response = removePortfolioImage(link);
    if (response)
      getPortfolioProduct(id).then((res) => {
        setImages(res.data?.jewellery_images);
      });
  };

  if (name === null) {
    return <Loader size={200} />;
  } else
    return (
      <div className="mrlb-5per mt-4">
        <CustomAlert
          show={show}
          text="Are you sure you want to delete?"
          buttonText="Delete"
          setShow={setshow}
          onClick={() => {
            DeleteAssets();
          }}
        />
        <ToastContainer />
        <div className="add-white-card pb-5">
          <div className="flex items-center py-4 md:py-8 px-5 md:px-10">
            <div
              onClick={() => {
                window.history.back();
              }}
              className="back-container"
            >
              <img src={back} alt="back" />
            </div>
            <span className="add-title">Update Product</span>
          </div>
          <div className="border-bottom-grey" />
          <div className="sub-white-card mx-5 px-5 gap-20 flex flex-wrap">
            <div className="flex flex-col gap-5 flex-1">
              <div className="select-title mb-8">
                Update Jewellery information
              </div>
              <TextField
                id="outlined-basic"
                label="Jewellery name"
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setname(e.target.value)}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Net Weight"
                variant="outlined"
                type="number"
                value={netWeight}
                onChange={(e) => {
                  setNetWeight(e.target.value.replace(/[^0-9|.]/g, ""));
                }}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Gross Weight"
                variant="outlined"
                type="number"
                value={grossWeight}
                onChange={(e) =>
                  setGrossWeight(e.target.value.replace(/[^0-9|.]/g, ""))
                }
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="flex-1 min-width-300"
              />
              {/* <FormControl>
                <InputLabel>Jewllery Type</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Jewllery Type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={0}>Old</MenuItem>
                  <MenuItem value={1}>New</MenuItem>
                </Select>
              </FormControl> */}
            </div>

            <div className="flex flex-col">
              <div className="select-title mb-8">Cover Image</div>
              <label
                style={{
                  position: "absolute",
                  marginLeft: 270,
                  marginTop: 40,
                  borderRadius: 100,
                  backgroundColor: "white",
                  padding: 10,
                  boxShadow: "11px 13px 74px rgba(0, 0, 0, 0.3)",
                }}
              >
                <input
                  type="file"
                  title="Add image"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setfile(e.target.files[0]);
                  }}
                />
                <MdOutlineModeEditOutline size={30} color="#eba300" />
              </label>
              {typeof file === "string" ? (
                <img
                  src={file}
                  alt="add"
                  style={{
                    objectFit: "cover",
                    width: 300,
                    height: 300,
                    margin: "auto",
                    borderRadius: 10,
                  }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(file)}
                  alt="add"
                  style={{
                    objectFit: "cover",
                    width: 300,
                    height: 300,
                    margin: "auto",
                    borderRadius: 10,
                  }}
                />
              )}
            </div>
            <div className="flex-1 flex flex-col">
              <div className="select-title mb-8">Additional images</div>
              <div className="flex flex-wrap flex-1">
                {images?.length === 0
                  ? null
                  : images?.map((i) => (
                      <div>
                        <button
                          onClick={() => {
                            RemoveImage(i);
                          }}
                          style={{
                            position: "absolute",
                            marginLeft: 110,
                            backgroundColor: "white",
                            borderRadius: 100,
                            boxShadow: "11px 13px 74px rgba(0, 0, 0, 0.5)",
                            padding: 10,
                          }}
                        >
                          <GrClose size={10} />
                        </button>
                        <img
                          src={i}
                          alt="add"
                          className="white-card m-3"
                          style={{
                            objectFit: "cover",
                            width: 120,
                            height: 120,
                          }}
                        />
                      </div>
                    ))}
                {newimages?.length === 0
                  ? null
                  : newimages?.map((i) => (
                      <div>
                        <button
                          onClick={() => {
                            const arr = newimages.filter((item) => item !== i);
                            setNewImages(arr);
                          }}
                          style={{
                            position: "absolute",
                            marginLeft: 110,
                            backgroundColor: "white",
                            borderRadius: 100,
                            boxShadow: "11px 13px 74px rgba(0, 0, 0, 0.5)",
                            padding: 10,
                          }}
                        >
                          <GrClose size={10} />
                        </button>
                        <img
                          src={URL.createObjectURL(i)}
                          alt="add"
                          className="white-card m-3"
                          style={{
                            objectFit: "cover",
                            width: 120,
                            height: 120,
                          }}
                        />
                      </div>
                    ))}
                <label className="add-image-button white-card mb-3">
                  <input
                    type="file"
                    title="Add image"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setNewImages([...newimages, e.target.files[0]]);
                    }}
                  />
                  <div className="flex items-center justify-center flex-col">
                    <img src={Add} alt="Add-icon" height={30} width={30} />
                    <div className="mt-2 text-xs text-yellow-600">
                      Add image
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          {Loading ? (
            <Loader size={200} />
          ) : (
            <div className="flex">
              <button onClick={_onPress} className="next-button m-8">
                <span className="next-text">Update</span>
              </button>
              <button
                onClick={() => {
                  setshow(true);
                }}
                className="delete-button m-8"
              >
                <span className="next-text">Delete</span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
};

export default EditProduct;
