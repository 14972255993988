import React from "react";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/icons/icon.png";
import Moment from "moment";

const PaymentCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      className="add-white-card py-2 md:p-5 flex mb-6 items-center flex double-card flex-wrap items-center text-medium text-gray-500 text-sm cursor-pointer"
      onClick={() => {
        navigate(`/paymentdetail/${data.rzp_order_id}`);
      }}
    >
      {data?.order_id}
      <div className="ml-0 md:m-4 flex min-w-40">
        <img className="payment-image" src={icon} alt="" />
        <div className="ml-10 md:ml-12">
          <span className="payment-name">₹ {data?.amount}</span>
          <div className="payment-price mt-2">
            {data?.type === 0
              ? "Advance Payment"
              : data?.type === 1
              ? "Installment Payment"
              : "Full Payment"}
          </div>
          <span className="text-xs text-gray-400">
            {Moment(data?.created_at).format("LL")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
