import axios from "axios";
import { getCookie } from "./CookieHandler";
import { APP_URL, GOLD_UNO_URL, LOAN_SCHEMES, Loan_API_URL } from "./config";
// import { toast } from "react-toastify";

export const AuthInstance = axios.create({
  baseURL: APP_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout: 1000,
  },
  // .. other options
});

AuthInstance.interceptors.request.use(
  async (config) => {
    const token = getCookie("token");
    config.headers.Authorization = "Bearer " + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AuthInstance.interceptors.response.use(
  (response) => {
    // Any status code from range of 2xx
    // Do something with response data
    console.log("response from axios", response);
    return response;
  },
  (error) => {
    // Any status codes outside range of 2xx
    // Do something with response error
    if (error.response) {
      // toast(error.response.data.message, {
      //   progressStyle: { background: "#eba300" },
      // });

      console.log(error.response);
      if (error.response.status === 401) {
        document.cookie.split(";").forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });
        localStorage.clear();
        window.location.reload();
      }
      return error.response;
    } else {
      console.log("errrrrr", error);
      alert("Some error occured");
    }
    return null;
  }
);

export const UserInstance = axios.create({
  baseURL: APP_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    timeout: 1000,
  },
  // .. other options
});

export const GoldUnoInstance = axios.create({
  baseURL: GOLD_UNO_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout: 4000,
    Secretkey: "asas@#$$Aaaa89",
  },
  // .. other options
});

export const LoanSchemesInstance = axios.create({
  baseURL: LOAN_SCHEMES,
  headers: {
    "Content-Type": "application/json",
    timeout: 1000,
  },
});

export const formDataPostAPI = async (route, formdata) => {
  const token = await getCookie("token");
  return new Promise((resolve, reject) => {
    console.log("Obj ::::", formdata);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(APP_URL + route, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("Result P ::::", result);
        resolve(result);
      })
      .catch((error) => {
        console.log("error", error);
        resolve(null);
      });
  });
};

export const LoanInstance = axios.create({
  baseURL: Loan_API_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout: 1000,
  },
  // .. other options
});

// export const formDataPostAPIS2 = async (route, formdata) => {
//   const token = await getCookie("token");

//   return new Promise((resolve, reject) => {
//     console.log("Obj ::::", formdata);
//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + token);
//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//       redirect: "follow",
//     };

//     fetch("https://s2dev.ruptokcap.in/" + route, requestOptions)
//       .then((response) => response.text())
//       .then((result) => {
//         console.log("Result P ::::", result);
//         resolve(result);
//       })
//       .catch((error) => {
//         console.log("error", error);
//         resolve(null);
//       });
//   });
// };
