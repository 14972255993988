import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/AuthReducer";
import assetsReducer from "./reducers/AssetReducer";
import loanReducer from "./reducers/LoanReducer";
import marketReducer from "./reducers/MarketPlaceReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  assetsReducer: assetsReducer,
  loanReducer: loanReducer,
  marketReducer: marketReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
