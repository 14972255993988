import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddAddressModal from "../../components/AddAddressModal";
import Empty from "../../assets/lottie/Empty";
import { useNavigate } from "react-router-dom";
import { addAddress, getUserAddress } from "../../redux/actions/AuthAction";
import { listAsset, getListedAssets } from "../../redux/actions/AssetAction";
import { toast, ToastContainer } from "react-toastify";
import TitleWithSeemore from "../../components/TitleWithSeemore";

const SellItemAddress = () => {
  const { userAddresses } = useSelector((state) => state.authReducer);
  const [showModal, setShowModal] = useState(false);
  const [primary, setPrimary] = useState();
  const [data, setData] = useState({
    address_line_1: "",
    address_line_2: "",
    landmark: "",
    friendly_name: "",
    city: 1,
    pincode: "",
    type: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);

  const navigate = useNavigate();
  // add adress-----------
  const AddAddress = () => {
    const res = addAddress(data);
    if (res) {
      dispatch(getUserAddress());
      setShowModal(false);
      setData({
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        friendly_name: "",
        city: 1,
        pincode: "",
        type: 0,
      });
    }
  };
  const setPrimaryAddress = (id) => {
    setPrimary(id);
  };
  return (
    <div className="container mx-auto p-5per bg-white">
      <ToastContainer />
      <TitleWithSeemore title="Select Address" viewall={false} onBack={true} />

      <div className="flex flex-wrap p-2 md:p-5 justify-between">
        {userAddresses?.length === 0 ? (
          <div className="container no-biddings mb-20">
            <Empty />
            <span className="text-slate-300 text-xs md:text-sm mt-4">
              Currently there is no address Add an address
            </span>
          </div>
        ) : null}
        {userAddresses?.map((i) => {
          return (
            <div
              onClick={() => setPrimaryAddress(i.id)}
              key={i.id}
              className="add-white-card p-2 md:p-5 flex mb-6 items-center flex double-card flex-wrap items-center"
            >
              <div className="mr-5 md:mr-10">
                <div
                  className={
                    primary === i.id ? "selected-radio-icon" : "radio-icon"
                  }
                ></div>
              </div>
              <div>
                <div className="address_title">{i.friendly_name}</div>
                <div className="address_name">
                  {i.address_line_1 + " " + i.address_line_2}
                </div>
                <div className="address_name">{i.landmark}</div>
                <div className="address_name">
                  {i.city_name + " - " + i.pincode}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex">
        <button
          onClick={() => {
            setShowModal({ show: true });
          }}
          className="upload-button ml-2 md:ml-10"
        >
          Add Address
        </button>
        <button
          onClick={() => {
            if (primary === undefined) {
              toast("select an address", {
                progressStyle: { background: "#eba300" },
              });
            } else {
              const data = JSON.parse(localStorage.getItem("sellProduct"));
              data.address_id = primary;
              listAsset(data).then((res) => {
                if (res.status === 200) {
                  dispatch(getListedAssets());
                  navigate("/mylistings");
                } else {
                  toast("please select an address", {
                    progressStyle: { background: "#eba300" },
                  });
                }
              });
            }
          }}
          className="sticky-white-button ml-2 md:ml-10"
        >
          Sell Item
        </button>
      </div>
      <AddAddressModal
        setShowModal={setShowModal}
        showModal={showModal}
        setValue={setData}
        value={data}
        onClick={AddAddress}
      />
    </div>
  );
};

export default SellItemAddress;
