import React, { useEffect, useState } from "react";
import back from "../../assets/icons/back_asset.png";
import next from "../../assets/icons/next.png";
import { getJewelsCategory } from "../../redux/actions/MarketAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAssetData } from "../../redux/actions/AssetAction";

const JewelleryType = () => {
  const { AddAssetData } = useSelector((state) => state.assetsReducer);

  const [Category, setCategory] = useState(null);
  const [JewelsCategory, setJewelsCategory] = useState(null);
  const [isDiamond, setIsDiamond] = useState(null);
  const category = [
    { id: 1, name: "GOLD" },
    { id: 2, name: "SILVER" },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    if (AddAssetData !== null && AddAssetData?.category !== undefined) {
      setCategory(AddAssetData?.Category);
      setJewelsCategory(AddAssetData?.JewelsCategory);
      setIsDiamond(AddAssetData?.isDiamond);
    }
    dispatch(getJewelsCategory());
  }, [dispatch]);
  const { jewelsCategory } = useSelector((state) => state.marketReducer);
  console.log("data----dfata", AddAssetData);

  const navigate = useNavigate();

  return (
    <div className="mrlb-5per mt-4">
      <div className="add-white-card pb-10">
        <div className="flex items-center py-4 md:py-8 px-5 md:px-10">
          <div
            onClick={() => {
              navigate("/");
              dispatch(addAssetData(null));
            }}
            className="back-container"
          >
            <img src={back} alt="back" />
          </div>
          <span className="add-title">Adding jewellery</span>
        </div>
        <div className="border-bottom-grey" />
        <div className="px-5 pt-8 pb-4 md:px-10 flex items-center justify-between">
          <div className="flex flex-col">
            <div className="select-title">Select Jewellery type</div>
            {/* <div className="select-content">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor
            </div> */}
          </div>
          <div className="select-step">Step 1/3</div>
        </div>
        <div className="sub-white-card mx-2 md:mx-5">
          <div className="sub-card-title mb-2 md:mb-4">
            Select what kind of jewellery you are adding ?
          </div>
          <div className="flex flex-wrap">
            {category?.map((i) => (
              <div
                key={i.id}
                onClick={() => setCategory(i.id)}
                className={
                  Category === i.id ? "selected-select-option" : "select-option"
                }
              >
                {i.name}
              </div>
            ))}
          </div>
          <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
            Select suitable category for jewellery ?
          </div>
          <div className="flex flex-wrap">
            {jewelsCategory?.map((i) => (
              <div
                key={i.id}
                onClick={() => setJewelsCategory(i.id)}
                className={
                  JewelsCategory === i.id
                    ? "selected-select-option"
                    : "select-option"
                }
              >
                {i.name}
              </div>
            ))}
          </div>

          <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
            Is there any diamonds ?
          </div>
          <div className="flex flex-wrap">
            <div
              onClick={() => setIsDiamond(1)}
              className={
                isDiamond === 1 ? "selected-select-option" : "select-option"
              }
            >
              Yes
            </div>
            <div
              onClick={() => setIsDiamond(0)}
              className={
                isDiamond === 0 ? "selected-select-option" : "select-option"
              }
            >
              No
            </div>
          </div>
          <button
            onClick={() => {
              dispatch(
                addAssetData({
                  Category: Category,
                  JewelsCategory: JewelsCategory,
                  isDiamond: isDiamond,
                })
              );
              navigate("/jewelleryclarity");
            }}
            disabled={
              Category !== null && JewelsCategory !== null && isDiamond !== null
                ? false
                : true
            }
            className={
              Category !== null && JewelsCategory !== null && isDiamond !== null
                ? "next-button mt-5"
                : "disabled-next-button mt-5"
            }
          >
            <span className="next-text">Next</span>
            <img className="next" src={next} alt="next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default JewelleryType;
