import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "../components/header/Header";
import Login from "../screens/auth/Signin";
import Market from "../screens/market/Market";
import MarketProduct from "../screens/market/MarketProduct";

const Auth = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Login />} />
        <Route path="/market" element={<Market />} />
        <Route path="/marketproduct/:id" element={<MarketProduct />} />
      </Routes>
    </>
  );
};

export default Auth;
