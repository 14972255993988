import React, { useState, useEffect } from "react";
import {
  LOGIN_USER,
  resendOtp,
  SetSignupPage,
  validateLoginOTP,
} from "../../redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import lock from "../../assets/icons/red-lock.png";
import { toast, ToastContainer } from "react-toastify";
import { setCookie } from "../../utils/CookieHandler";

const OTP = ({ phone, setOtpPage, handleModal }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [wrongOtp, setWrongOtp] = useState(false);
  const [seconds, setSeconds] = useState(50);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      <ToastContainer />
      <div className="font-bold text-xl mb-8 text-center">
        Verify Phone number
      </div>
      <img src={lock} alt="" />
      <div className="font-bold text-xl mt-4 mb-2 text-center">Enter Code</div>
      <div className="light-grey-text flex items-center mb-6">
        We sent code to {phone}{" "}
        <span
          className="ml-3 text-yellow-500 cursor-pointer"
          onClick={() => {
            setOtpPage(false);
          }}
        >
          Edit
        </span>
      </div>
      <OtpInput
        value={otp}
        onChange={(otp) => {
          setOtp(otp.replace(/[^0-9]/g, ""));

          setWrongOtp(false);
        }}
        numInputs={4}
        className="mb-4"
        inputStyle={{
          height: 50,
          width: 50,
          margin: 10,
          background: "#F4F4F4",
          border: wrongOtp ? "1px solid red" : "1px solid #ECECEC",
          borderRadius: 5,
          marginBottom: "20px",
        }}
      />
      <div>
        Didn’t recieved code ?{" "}
        {seconds === 0 ? (
          <span
            className="ml-2 text-yellow-500 cursor-pointer"
            onClick={() => {
              resendOtp({ phone: phone });
              setSeconds(50);
            }}
          >
            Resend
          </span>
        ) : (
          <span className="ml-2 text-yellow-500">
            00:
            {seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        )}
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          validateLoginOTP({ otp: otp, phone: phone }).then((res) => {
            if (res.status === 400) {
              setWrongOtp(true);
              setOtp("");
              toast(res.message, {
                progressStyle: { background: "#eba300" },
              });
            } else if (res.profile_completion_status === 1) {
              dispatch({
                type: LOGIN_USER,
                payload: true,
              });
              setOtpPage(false);
              handleModal();
            } else if (res.profile_completion_status === 0) {
              setCookie("token", res.token);
              dispatch(SetSignupPage(true));
              setOtpPage(false);
            } else return null;
          });
        }}
        className="next-auth-button mt-10"
        disabled={otp?.length === 4 ? false : true}
      >
        Next
      </button>
    </>
  );
};

export default OTP;
