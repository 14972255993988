import React from "react";
import Lottie from "react-lottie";

const KYC = ({ size }) => {
  const options = {
    animationData: require("./kyc.json"),
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie options={options} width={size} height={size} />;
};

export default KYC;
