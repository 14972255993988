import { getCookie } from "../../utils/CookieHandler";
import {
  AuthInstance,
  GoldUnoInstance,
  LoanInstance,
  LoanSchemesInstance,
} from "../../utils/axios";
import Moment from "moment";

export const GET_ACTIVE_LOANS = "GET_ACTIVE_LOANS";
export const GET_DAYS = "GET_DAYS";

export const getActiveLoans = () => {
  try {
    return async (dispatch) => {
      const d = await getCookie("user");
      const user = JSON.parse(d);
      const formData = new FormData();
      formData.append("mobile_no", user.phone);
      const res = await GoldUnoInstance.post("new_myloan/list", formData);
      if (res) {
        console.log("data_is_here_loans____active_______", res.data.data);
        dispatch({
          type: GET_ACTIVE_LOANS,
          payload: res.data.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getMyLoans = async () => {
  try {
    const res = await AuthInstance.get("/user/fetch-gold-loans");
    if (res) {
      console.log("data_is_here_loans____active", res.data.data.active_loan);
      return res.data.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor loan maI", error);
    return null;
  }
};

export const initiateLoanPayment = async (formData) => {
  try {
    console.log("initiate_list_assets_action");
    const res = await GoldUnoInstance.post(
      "repayment_payment_new/initiate",
      formData
    );
    if (res) {
      console.log("data_is_here_loans____active", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const updateLoanPayment = async (formData) => {
  try {
    console.log("initiate_list_assets_action");
    const res = await GoldUnoInstance.post(
      "repayment_payment_new/update",
      formData
    );
    if (res) {
      console.log("update_____is_here_loans____active", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const getSchemesByAmount = async (data) => {
  try {
    console.log("in_get_loan_schemes");
    const res = await LoanSchemesInstance.post(
      "ruptok/get-all-schemes-by-amount",
      data
    );
    if (res) {
      console.log("data_is_here_scemesss_active", res.data.result);
      return res.data.result;
    } else {
      console.log("error_schememmesd");
      return null;
    }
  } catch (error) {
    console.log("errorrorororo+_____Schemes");
    return null;
  }
};

export const addLoanApi = async (data) => {
  try {
    console.log("in_add_loan_schemes");
    const res = await LoanSchemesInstance.post("ruptok/lsq/Add_Loan", data);
    if (res) {
      console.log("data_is_here_add_loan____scemesss_active", res.data);
      return res.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const initiateOrderID = async (formData) => {
  console.log("formdata", formData);
  try {
    console.log("initiate_list_assets_action");
    const res = await LoanInstance.post(
      "/api/app/repayment-against-loan",
      formData
    );
    if (res) {
      console.log("data_is_here_loans____active", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("in_______", error);
    return null;
  }
};

export const getNextDate = () => {
  try {
    return async (dispatch) => {
      let currentDate = new Moment();
      let date = currentDate;
      let dateComp = [];
      const maxDate = 30;
      for (
        let nextIncrementIndex = 0;
        nextIncrementIndex <= maxDate;
        nextIncrementIndex++
      ) {
        if (dateComp.length > 0) {
          date = date.add(1, "days");
        }
        const week = date.format("dddd");
        const month = date.format("MMMM");
        const day = date.format("DD");
        const payload = {
          week: week,
          month: month,
          day: day,
          date: date.format("DD-MM-yyyy"),
        };
        dateComp.push(payload);
      }

      dispatch({
        type: GET_DAYS,
        payload: dateComp,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};
