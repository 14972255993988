import React, { useEffect } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import Empty from "../../assets/lottie/Empty";
import { useDispatch, useSelector } from "react-redux";
import { getListedAssets } from "../../redux/actions/AssetAction";
import ListingCard from "../../components/profile/ListingCard";
import { Skeleton } from "@mui/material";

const MyListings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getListedAssets());
  }, [dispatch]);
  const { myListedAssets } = useSelector((state) => state?.assetsReducer);
  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title="My Listings" viewall={false} onBack={true} />
      {myListedAssets === null ? (
        <div className="flex flex-col p-4 md:p-5 justify-between">
          <div className="mb-10 flex flex-row">
            <Skeleton variant="rectangular" width={230} height={160} />
            <div className="grid gap-2 ml-20">
              <Skeleton
                className="mt-10"
                variant="rectangular"
                width={130}
                height={20}
              />
              <Skeleton variant="rectangular" width={100} height={15} />
              <Skeleton variant="rectangular" width={80} height={20} />
            </div>
          </div>
          <div className="mb-10 flex flex-row">
            <Skeleton variant="rectangular" width={230} height={230} />
            <div className="grid gap-2 ml-20">
              <Skeleton
                className="mt-10"
                variant="rectangular"
                width={130}
                height={20}
              />
              <Skeleton variant="rectangular" width={130} height={20} />
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap p-2 md:p-5 justify-between">
          {myListedAssets?.length === 0 ? (
            <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
              <Empty />
              <div className="flex flex-col">
                <span className="no-asset">You have no listings</span>
              </div>
            </div>
          ) : (
            myListedAssets?.map((asset) => {
              return <ListingCard data={asset} key={asset.id} />;
            })
          )}
        </div>
      )}
    </div>
  );
};

export default MyListings;
