import { Skeleton } from "@mui/material";
import React from "react";

const ProductPageSkelton = () => {
  return (
    <div className="product-page flex-1 flex flex-wrap">
      <div className="product-container">
        <Skeleton variant="rectangular" height="300px" width="100%" />
        <Skeleton
          variant="rectangular"
          height="50px"
          width="100%"
          className="mt-5"
        />
      </div>

      <div className="flex-1">
        <div className="white-card flex justify-between p-0">
          <Skeleton variant="rectangular" height="150px" width="100%" />
        </div>
        <div className="white-card flex justify-between p-0 mt-5">
          <Skeleton variant="rectangular" height="100px" width="100%" />
        </div>
        <div className="white-card flex justify-between p-0 mt-5">
          <Skeleton variant="rectangular" height="120px" width="100%" />
        </div>
        <div className="white-card flex justify-between p-0 mt-5">
          <Skeleton variant="rectangular" height="70px" width="100%" />
        </div>
        <div className="white-card flex justify-between p-0 mt-5">
          <Skeleton variant="rectangular" height="50px" width="100%" />
        </div>
      </div>
    </div>
  );
};

export default ProductPageSkelton;
