import React from "react";
import { useNavigate } from "react-router-dom";

const BidsCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="add-white-card p-2 md:p-5 flex mb-6 items-center double-card cursor-pointer"
        onClick={() => {
          if (data?.bid_status === 1) {
            navigate(`/bidschemes/${data.bid_id}`);
          }
        }}
      >
        <img
          className="portfolio-image"
          src={data?.s3_image ? data.s3_image : data.image_url}
          alt=""
        />
        <div className="ml-4 md:ml-10">
          <div className="listing_name">{data?.name}</div>
          <div className="listing_title">
            Bid price :{" "}
            <span className="listing_price">₹ {data?.quoted_amount}</span>
          </div>
          {data?.bid_status === 0 ? (
            <div className="is_approved_0">
              <div className="is_approved_text_0">{"PENDING "}</div>
            </div>
          ) : null}
          {data?.bid_status === 1 ? (
            <div className="is_approved_1 mt-2">
              <div className="is_approved_text_1">{"ACCEPTED"}</div>
            </div>
          ) : null}
          {data?.bid_status === 2 ? (
            <div className="is_approved_2">
              <div className="is_approved_text_2">{"REJECTED"}</div>
            </div>
          ) : data?.bid_status === 3 ? (
            <div className="is_approved_2">
              <div className="is_approved_text_2">{"PAYMENT DONE"}</div>
            </div>
          ) : null}
          {data?.bid_status === 1 ? (
            <div className="card-sub-text mt-2">
              Your bid is accepted , please click to make a payment.
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default BidsCard;
