import React from "react";
import { toast } from "react-toastify";
import { acceptBid } from "../../redux/actions/AssetAction";

const AcceptBidModal = ({ setShowModal, showModal, item, onClick }) => {
  const _onPress = async () => {
    const formData = {
      bid_id: item?.id,
    };
    const result = await acceptBid(formData);
    if (result) {
      toast("Successfully accpeted.", {
        progressStyle: { background: "#eba300" },
      });
      onClick();
    }
  };
  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          <div className="flex flex-col justify-center p-6">
            <div className="add-title mb-2">
              Accept Bid - ₹{item?.quoted_amount}
            </div>
            <div className="profile-sub-text">
              Are you sure you want to accept this bid.
            </div>
          </div>
          <div className="flex justify-betweeen items-center p-6 space-x-2 rounded-b border-t border-gray-200">
            <button onClick={_onPress} className="upload-button">
              Accept Bid
            </button>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default AcceptBidModal;
