import React, { useEffect, useState } from "react";
import { getSchemesByAmount } from "../../redux/actions/LoanAction";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TitleWithSeemore from "../../components/TitleWithSeemore";

const SchemeSelection = ({ navigation }) => {
  const navigate = useNavigate();

  const [schemes, setSchemes] = useState([]);
  const [loanAmount, setLoanAmount] = useState(50000);

  const _goToNextScreen = async (id) => {
    navigate(`/selectdatetime/${id}`);
  };

  useEffect(() => {
    const _getSchemes = async () => {
      const res = await getSchemesByAmount({
        loan_amount: loanAmount,
        repayment_type: "",
        sort_by: "DESC",
      });
      console.log("___schemes______", res);
      if (res) {
        await setSchemes(res);
      } else {
        toast("Some Error Occured", {
          progressStyle: { background: "#eba300" },
        });
      }
    };
    _getSchemes();
  }, [loanAmount]);

  return (
    <div className="add-white-card mrlb-5per mt-2 px-5 md:px-10">
      <ToastContainer />
      <TitleWithSeemore title="Select Loan" viewall={false} onBack={true} />
      <input
        type="text"
        className="custom-input mb-5 w-1/4 mt-7"
        placeholder="Enter loan amount"
        onChange={(e) => {
          if (e.target.value > 24999) setLoanAmount(e.target.value);
        }}
      />
      <div className="flex flex-wrap">
        {schemes.map((i) => (
          <div
            key={i.id}
            className="loan-card mb-5 double-card mr-4 cursor-pointer"
            onClick={() => {
              _goToNextScreen(i.id);
            }}
          >
            <div className="yellow-top-card ">
              {i.interest_rate_agreed}
              <span className="top-small-text"> % ROI / Month</span>
            </div>
            <div className="flex items-center justify-around p-5">
              <div className="flex flex-col justify-center">
                <span className="card-main-text">{i.loan_tenure} Months</span>
                <span className="card-sub-text">Loan Tenure</span>
              </div>
              <div className="flex flex-col justify-center">
                <span className="card-main-text">
                  {"₹ " +
                    parseFloat(i.loan_min).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
                <span className="card-sub-text">Loan Eligiblity</span>
              </div>
              <div className="flex flex-col justify-center">
                <span className="card-main-text">
                  {i.required_gold_rate} GM
                </span>
                <span className="card-sub-text">Required Net gold</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SchemeSelection;
