import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonLoadingCards = () => {
  return (
    <div className="ml-10">
      <div className="flex p-4 md:p-5 justify-between">
        <div className="mb-10 flex flex-row flex-1">
          <Skeleton variant="rectangular" width={230} height={160} />
          <div className="grid gap-2 ml-20">
            <Skeleton
              className="my-10"
              variant="rectangular"
              width={130}
              height={20}
            />
            <Skeleton variant="rectangular" width={100} height={15} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
          </div>
        </div>
        <div className="mb-10 flex flex-row flex-1">
          <Skeleton variant="rectangular" width={230} height={160} />
          <div className="grid gap-2 ml-20">
            <Skeleton
              className="my-10"
              variant="rectangular"
              width={130}
              height={20}
            />
            <Skeleton variant="rectangular" width={100} height={15} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
          </div>
        </div>
      </div>
      <div className="flex p-4 md:p-5 justify-between">
        <div className="mb-10 flex flex-row flex-1">
          <Skeleton variant="rectangular" width={230} height={160} />
          <div className="grid gap-2 ml-20">
            <Skeleton
              className="my-10"
              variant="rectangular"
              width={130}
              height={20}
            />
            <Skeleton variant="rectangular" width={100} height={15} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
          </div>
        </div>
        <div className="mb-10 flex flex-row flex-1">
          <Skeleton variant="rectangular" width={230} height={160} />
          <div className="grid gap-2 ml-20">
            <Skeleton
              className="my-10"
              variant="rectangular"
              width={130}
              height={20}
            />
            <Skeleton variant="rectangular" width={100} height={15} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
            <Skeleton variant="rectangular" width={80} height={10} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoadingCards;
