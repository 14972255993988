/* eslint-disable no-unreachable */
import { AuthInstance } from "../../utils/axios";

export const GET_MARKETPLACE_DATA = "GET_MARKETPLACE_DATA";
export const GET_ITEM_BIDS = "GET_ITEM_BIDS";
export const GET_JEWELS_CATEGORY = "GET_JEWELS_CATEGORY";
export const MORE_MARKETPLACE_DATA = "MORE_MARKETPLACE_DATA";

export const getPublicMarketPlace = async (data) => {
  try {
    const res = await AuthInstance.post(
      "api/fetch-marketplace-with-filters-public",
      {
        ...data,
        analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
      }
    );
    if (res) {
      console.log("market_data_is_here", res);
      return res;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const getMarketPlace = async (data) => {
  try {
    const res = await AuthInstance.post("api/fetch-marketplace-data", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      console.log("market_data_is_here", res);
      return res;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const getMakretProduct = async (id) => {
  try {
    const res = await AuthInstance.post("api/fetch-listing-details", {
      listing_id: id,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      console.log("market_data_is_here", res);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};
export const getMakretProductNew = async (id) => {
  try {
    const res = await AuthInstance.post("api/seller-listing-detail", {
      listing_id: id,
    });
    if (res) {
      console.log("market_data_is_here", res);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};
export const getPortfolioProduct = async (id) => {
  try {
    const res = await AuthInstance.post("api/portfolio-asset-details", {
      asset_id: id,
    });
    if (res) {
      console.log("market_data_is_here", res);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const removePortfolioImage = async (link) => {
  try {
    const res = await AuthInstance.post("api/remove-jewellery-image", {
      id: link,
    });
    if (res) {
      console.log("market_data_is_here", res);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const loadMoreMarket = (data) => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.post(
        "api/fetch-marketplace-with-filters",
        {
          ...data,
          analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
        }
      );
      if (res) {
        console.log("market_data_is_here", res.data);
        dispatch({
          type: MORE_MARKETPLACE_DATA,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getMarketPlaceData = async (data) => {
  try {
    const res = await AuthInstance.post("api/fetch-marketplace-with-filters", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      console.log("market_data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const getMarketPlaceByFilters = async (data) => {
  try {
    console.log("in_list_assets_action");
    const res = await AuthInstance.post("api/fetch-marketplace-with-filters", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      console.log("market_data_is_here", res.data);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const placeBid = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/make-bid", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const makeInstantBuy = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/instant_buy_with_advance_amount", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const makeEmiPayment = async (data) => {
  try {
    const res = await AuthInstance.post("api/repayment", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const makeBidPayment = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/buy_with_bid_won", {
      ...data,
      analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
    });
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const getItemBids = (data) => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.post("api/fetch-bids-for-listing", data);
      if (res) {
        console.log("market_data_is_here", res.data);
        dispatch({
          type: GET_ITEM_BIDS,
          payload: res.data,
        });
        localStorage.setItem("bids", JSON.stringify(res.data));
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getJewelsCategory = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/get-jewel-categories");
      if (res) {
        console.log("category_data_is_here", res.data);
        dispatch({
          type: GET_JEWELS_CATEGORY,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const setMarketProduct = (data) => {
  try {
    localStorage.setItem("mproduct", JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
};

export const setPortfolioProduct = (data) => {
  try {
    localStorage.setItem("pproduct", JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
};
