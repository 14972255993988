import React, { useState, useEffect } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { getCookie } from "../../utils/CookieHandler";
import useRazorpay from "react-razorpay";
import { useNavigate, useParams } from "react-router-dom";
import OrderFlow from "../../components/OrderFlow";
import { OrderDetails } from "../../redux/actions/AssetAction";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";
import { makeEmiPayment } from "../../redux/actions/MarketAction";
import Loader from "../../assets/lottie/Loader.jsx";

const OrderDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    OrderDetails(id).then((res) => {
      if (res.data.message === "order details not found") {
        navigate("/myorders");
      } else {
        setData(res.data.data);
      }
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const RAZORPAY_API_KEY = localStorage.getItem("razorpaykey");

  const _onPress = async () => {
    setLoading(true);
    const formdata = {
      order_id: id,
    };
    const res = await makeEmiPayment(formdata);
    if (res) {
      const id = res.data.rzp_order_id;
      const data = JSON.parse(getCookie("user"));
      await _buyItem(data, id);
    }
  };

  const Razorpay = useRazorpay();

  const _buyItem = async (data, id) => {
    const options = {
      key: RAZORPAY_API_KEY,
      currency: "INR",
      name: "Ruptok",
      description: "Jewellery",
      image: "https://py.ruptokcap.in/ruptoklogo.png",
      order_id: id,
      prefill: {
        name: `${data.first_name}  ${data.last_name}`,
        email: data.email,
        contact: data.phone,
      },
      theme: { color: "#F37254" },
      handler: (res) => {
        console.log(res);
        window.location.reload();
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
    setLoading(false);
  };

  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title={id} viewall={false} onBack={true} />
      <div className="flex flex-1 flex-wrap p-0 md:px-10 md:py-5">
        <div className="product-container">
          <img src={data?.image_url} alt="Product" className="product-image" />
          <div className="white-card-2 mt-4">
            <div className="product-title mb-2">Product Details</div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Jewellery Name </span>
              <span className="detail-value">: {data?.jewellery_name}</span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Order ID </span>
              <span className="detail-value">: {data?.order_id}</span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Purity </span>
              <span className="detail-value">: {data?.purity} karat</span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Net Weight </span>
              <span className="detail-value">: {data?.net_weight} GM</span>
            </div>
            <div className="flex justify-between mt-1 mb-4">
              <span className="detail-name">Gross Weight </span>
              <span className="detail-value">: {data?.gross_weight} GM</span>
            </div>
          </div>
          <div className="white-card-2 mt-4">
            <div className="product-title">All Payments</div>
            <div className="text-slate-300 text-xs md:text-sm">
              Payments are reflected only upon successful transfer*
            </div>
            {data?.payments?.map((i) => (
              <div
                className="flex justify-between mt-4 cursor-pointer"
                onClick={() => {
                  navigate(`/paymentdetail/${i.rzp_order_id}`);
                }}
              >
                <span className="bid-name">
                  {i?.type === 0
                    ? "Advance Payment"
                    : i?.type === 1
                    ? "Installment Payment"
                    : "Full Payment"}
                </span>
                <span className="bid-amount">
                  {"₹ " +
                    parseFloat(i?.amount).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
            ))}
          </div>
          {/* <div className="sticky-white-card mt-5">
            <button onClick={() => {}} className="bid-button">
              Rate us
            </button>

            <button onClick={() => {}} className="buy-button">
              Download
            </button>
          </div> */}
        </div>
        <div className="flex-1 flex flex-wrap pl-2">
          <div
            className="white-card flex mt-4 md:mt-0 items-start"
            style={
              window.innerWidth > 1200 ? null : { flexDirection: "column" }
            }
          >
            <OrderFlow data={data} onPress={_onPress} loading={loading} />
            <div
              className="flex flex-1"
              style={
                window.innerWidth > 1200
                  ? {
                      borderLeft: "1px solid black",
                      height: "100%",
                      marginLeft: 20,
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  : {
                      borderTop: "1px solid black",
                      width: "100%",
                      marginTop: 20,
                      padding: 20,
                    }
              }
            >
              <div>
                <div className="product-title mb-3 text-xl">
                  Delivery Address
                </div>
                <div className="detail-name mb-2">{data?.buyer_name}</div>
                <div className="detail-value">{data?.buyer_address_1}</div>
                <div className="detail-value">{data?.buyer_address_2}</div>
                <div className="detail-name mt-3 mb-1">Phone Number</div>
                <div className="detail-value">{data?.buyer_phone}</div>
              </div>
            </div>
          </div>

          {data?.upcoming_payment_status === 0 ? null : (
            <div className="white-card mt-4">
              <div className="flex items-center justify-between">
                <div className="product-title mb-2">Next Installment</div>
                <div>
                  {data?.next_installment_amount ? (
                    <div
                      style={{
                        width: "100px",
                        height: "40px",
                        backgroundColor: "#eba300",
                        borderRadius: "8px",
                        display: "flex",
                        color: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        fontWeight: "normal",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!loading) {
                          _onPress();
                        }
                      }}
                    >
                      {loading ? <Loader size={30} /> : "Pay Now"}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full items-center justify-between mt-6">
                <div className="flex flex-col justify-between">
                  <div className="text-sm font-medium text-gray-500">
                    ₹{data?.next_installment_amount}
                  </div>
                  <div className="text-lg font-bold text-black-900">
                    Installment Due
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div className="text-sm font-medium text-gray-500 ml-auto">
                    {data?.next_installment_date}
                  </div>
                  <div className="text-lg font-bold text-black-900">
                    Next Due Date
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="white-card mt-4 mb-20">
            <div className="product-title mb-2">Payment Details</div>
            <div className="flex">
              {data ? (
                <PieChart
                  style={{
                    fontFamily:
                      '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                    fontSize: "8px",
                    height: "200px",
                    width: "200px",
                    marginTop: "20px",
                    flex: 1,
                  }}
                  radius={pieChartDefaultProps.radius - 6}
                  lineWidth={60}
                  segmentsStyle={{
                    transition: "stroke .3s",
                    cursor: "pointer",
                  }}
                  animate
                  label={({ dataEntry }) =>
                    Math.round(dataEntry.percentage) + "%"
                  }
                  labelPosition={100 - 60 / 2}
                  labelStyle={{
                    fill: "#fff",
                    opacity: 0.75,
                    pointerEvents: "none",
                  }}
                  data={
                    data?.scheme_id === 0
                      ? [
                          {
                            title: "Paid amount",
                            value: data?.paid_amount,
                            color: "green",
                          },
                          {
                            title: "Total due",
                            value: data?.total_due,
                            color: "#eba300",
                          },
                        ]
                      : [
                          {
                            title: "Paid amount",
                            value: data?.paid_installments,
                            color: "green",
                          },
                          {
                            title: "Total due",
                            value:
                              data?.installments?.length -
                              data?.paid_installments,
                            color: "#eba300",
                          },
                        ]
                  }
                />
              ) : null}
              <div className="flex flex-1 flex-col justify-center w-full">
                {/* <div className="flex justify-between mt-4">
                  <span className="detail-name flex">
                    <div className="deal-box"></div>Deal amount{" "}
                  </span>
                  <span className="detail-value">
                    : ₹{parseFloat(data?.deal_amount).toFixed(2)}
                  </span>
                </div> */}
                <div className="flex justify-between mt-4">
                  <span className="detail-name flex">
                    <div className="paid-box"></div>Paid amount{" "}
                  </span>
                  <span className="detail-value">
                    : ₹{parseFloat(data?.paid_amount).toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between mt-4">
                  <span className="detail-name flex">
                    <div className="due-box"></div>Total due{" "}
                  </span>
                  <span className="detail-value">
                    : ₹{parseFloat(data?.total_due).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-between mt-6">
              <div className="flex flex-col justify-between">
                <div className="text-lg font-medium text-gray-500">
                  {data?.scheme_name}
                </div>
                <div className="text-xl font-bold text-black-900">
                  Scheme Name
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-lg font-medium text-gray-500 ml-auto">
                  {data?.tenure ? `${data.tenure} Months` : "1 Month"}
                </div>
                <div className="text-xl font-bold text-black-900">
                  Scheme Tenure
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
