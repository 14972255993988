import React, { useState } from "react";
import Loader from "../../assets/lottie/Loader.jsx";
import Signup from "./Signup";
import OTP from "./OTP";
import phoneIcon from "../../assets/icons/phone.png";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/AuthAction";

const ModalLogin = ({ setShowModal, showModal, handleModal }) => {
  const [phone, setPhone] = useState("");
  const [OtpPage, setOtpPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const signUpPage = useSelector((state) => state?.authReducer?.signUpPage);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await loginUser({ phone: phone });
    if (res) {
      setOtpPage(true);
      setLoading(false);
    }
  };

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal-2 relative bg-white rounded-lg shadow">
          <AiOutlineClose
            size={window.innerWidth > 700 ? 30 : 20}
            style={{ position: "absolute", right: 10, top: 10 }}
            onClick={() => {
              setShowModal(false);
            }}
          />
          <div className="flex-1 flex justify-center items-center">
            <div
              className="rounded overflow-hidden m-auto"
              style={{
                width: window.innerWidth > 600 ? 450 : "90%",
                backgroundColor: "white",
                padding: window.innerWidth > 600 ? 50 : 20,
                height: signUpPage ? 700 : OtpPage ? 500 : 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: "1px solid rgba(170, 170, 170, 0.3)",
                transition: "0.2s ease-out",
              }}
            >
              {loading ? (
                <Loader size={200} />
              ) : OtpPage ? (
                <OTP
                  phone={phone}
                  setOtpPage={setOtpPage}
                  handleModal={handleModal}
                />
              ) : signUpPage ? (
                <Signup
                  phone={phone}
                  loading={loading}
                  handleModal={handleModal}
                />
              ) : (
                <>
                  <div className="px-6 pb-12 text-center">
                    <div className="font-bold text-xl mb-2">Sign in</div>
                    <p className="text-gray-700 text-base">
                      To Access your Account
                    </p>
                  </div>
                  <div style={{ marginBottom: "20px" }} className="auth-input">
                    <input
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value.replace(/[^0-9]/g, ""));
                      }}
                      maxLength={10}
                      placeholder="Enter your Phone"
                      style={{ outline: "none", fontSize: 15, width: "100%" }}
                    />
                    <img src={phoneIcon} alt="phone" />
                  </div>
                  <button
                    onClick={handleSignIn}
                    className={
                      phone?.length === 10
                        ? "next-auth-button"
                        : "next-auth-button-disabled"
                    }
                    disabled={phone?.length === 10 ? false : true}
                  >
                    Next
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ModalLogin;
