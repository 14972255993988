import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import ReactImageMagnify from "react-image-magnify";
import "react-multi-carousel/lib/styles.css";

const MultiImageSlider = ({ data }) => {
  const [image, setImage] = useState("");
  const [more, showmore] = useState(false);
  useEffect(() => {
    setImage(data[0]);
  }, [data]);

  return (
    <div>
      {image !== "" ? (
        // <img src={image} alt="" className="product-image" />

        <ReactImageMagnify
          {...{
            smallImage: {
              isFluidWidth: true,
              src: image,
            },
            largeImage: {
              src: image,
              width: (window.innerWidth * 55) / 100,
              height: window.innerHeight,
            },
          }}
          enlargedImageContainerDimensions={{
            width: (window.innerWidth * 55) / 100,
            height: (window.innerHeight * 75) / 100,
          }}
        />
      ) : (
        <Skeleton variant="rectangular" height="45vh" width="100%" />
      )}
      <div className="flex items-center mt-5 overflow-scroll">
        {more
          ? data?.map((i) => (
              <img
                src={i}
                onClick={() => {
                  setImage(i);
                }}
                alt=""
                className={
                  image === i ? "product-image-small-a" : "product-image-small"
                }
              />
            ))
          : data?.slice(0, 5).map((i) => (
              <img
                src={i}
                onClick={() => {
                  setImage(i);
                }}
                alt=""
                className={
                  image === i ? "product-image-small-a" : "product-image-small"
                }
              />
            ))}
        {more ? null : data.length > 5 ? (
          <div
            className="product-image-small flex items-center justify-center text-yellow-500 text-sm cursor-pointer"
            onClick={() => {
              showmore(true);
            }}
          >
            More
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default MultiImageSlider;
