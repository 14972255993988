import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import CacheBuster from "./CacheBuster";
import { ToastContainer, toast } from "react-toastify";
import { gettoken, onMessageListener } from "./firebase";
import Auth from "./routes/Auth";
import Main from "./routes/Main";
import axios from "axios";
import { getCookie } from "./utils/CookieHandler";

function App() {
  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    gettoken();
  }, []);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      toast(notification.body, {
        progressStyle: { background: "#eba300" },
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  // utm tracking data

  useEffect(() => {
    const url = new URL(decodeURIComponent(window.location.href));
    const searchParams = new URLSearchParams(url.searchParams);

    axios.get("https://geolocation-db.com/json/").then((res) => {
      localStorage.setItem(
        "analysis_data",
        JSON.stringify({
          ip: res.data.IPv4,
          geolocation: res.data,
          token: getCookie("token"),
          utm_campaign: searchParams.get("utm_campaign"),
          utm_medium: searchParams.get("utm_medium"),
          utm_source: searchParams.get("utm_source"),
          utm_content: searchParams.get("utm_content"),
          fbclid: searchParams.get("fbclid"),
          igshid: searchParams.get("igshid"),
          user_agent: navigator.userAgent,
        })
      );
    });
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Router>
            <ToastContainer />
            {isLoggedIn ? <Main /> : <Auth />}
          </Router>
        );
      }}
    </CacheBuster>
  );
}

export default App;
