import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import back from "../../assets/icons/back_asset.png";
import next from "../../assets/icons/next.png";
import { useSelector, useDispatch } from "react-redux";
import { addAssetData } from "../../redux/actions/AssetAction";

const JewelleryClarity = () => {
  const { AddAssetData } = useSelector((state) => state?.assetsReducer);

  const [clarity, setClarity] = useState(null);
  const [color, setColor] = useState(null);
  const [cut, setCut] = useState(null);
  const [count, setCount] = useState(null);
  const [karat, setKarat] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // data-------
  const clarityArr = [
    { id: 1, name: "FL-IF" },
    { id: 2, name: "VVS1-VVS2" },
    { id: 3, name: "VS1-VS2" },
    { id: 4, name: "S11-S12" },
    { id: 5, name: "11" },
    { id: 6, name: "12" },
    { id: 7, name: "13" },
  ];
  const colorArr = [
    { id: 1, name: "L-Z" },
    { id: 2, name: "K" },
    { id: 3, name: "J" },
    { id: 4, name: "I" },
    { id: 5, name: "H" },
    { id: 6, name: "G" },
    { id: 7, name: "F" },
    { id: 8, name: "E" },
    { id: 9, name: "D" },
  ];
  const cutArr = [
    { id: 1, name: "Round" },
    { id: 2, name: "Princess" },
    { id: 3, name: "Cushion" },
    { id: 4, name: "Emerald" },
    { id: 5, name: "Radiant" },
    { id: 6, name: "Oval" },
    { id: 7, name: "Peer" },
    { id: 8, name: "Marquise" },
    { id: 9, name: "Asscher" },
    { id: 10, name: "Heart" },
  ];
  const diamondsCount = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];
  const karats = [
    { id: 9, name: "9 Karat" },
    { id: 14, name: "14 Karat" },
    { id: 18, name: "18 Karat" },
    { id: 20, name: "20 Karat" },
    { id: 21, name: "21 Karat" },
    { id: 22, name: "22 Karat" },
    { id: 24, name: "24 Karat" },
  ];
  const silverKarats = [
    { id: 800, name: "800 Karat" },
    { id: 835, name: "835 Karat" },
    { id: 925, name: "925 Karat" },
    { id: 999, name: "999 Karat" },
  ];

  useEffect(() => {
    if (AddAssetData !== null && AddAssetData.karat !== undefined) {
      setClarity(AddAssetData?.stones?.clarity);
      setColor(AddAssetData?.stones?.color);
      setCut(AddAssetData?.stones?.cut);
      setCount(AddAssetData?.stones?.count);
      setKarat(AddAssetData?.karat);
    }
  }, []);
  return (
    <div className="mrlb-5per mt-4">
      <div className="add-white-card pb-10">
        <div className="flex items-center py-4 md:py-8 px-5 md:px-10">
          <div
            onClick={() => {
              navigate("/jewellerytype");
            }}
            className="back-container"
          >
            <img src={back} alt="back" />
          </div>
          <span className="add-title">Adding jewellery</span>
        </div>
        <div className="border-bottom-grey" />
        <div className="px-5 pt-8 pb-4 md:px-10 flex items-center justify-between">
          <div className="flex flex-col">
            <div className="select-title">Select Jewellery clarity</div>
            {/* <div className="select-content">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor
            </div> */}
          </div>
          <div className="select-step">Step 2/3</div>
        </div>
        <div className="sub-white-card mx-2 md:mx-5">
          {AddAssetData?.isDiamond === 1 ? (
            <>
              <div className="sub-card-title mb-2 md:mb-4">
                Number of diamonds
              </div>
              <div className="flex flex-wrap">
                {diamondsCount.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setCount(i.id)}
                    className={
                      count === i.id
                        ? "selected-select-option"
                        : "select-option"
                    }
                  >
                    {i.id}
                  </div>
                ))}
              </div>
              <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
                Select Diamond Clarity
              </div>
              <div className="flex flex-wrap">
                {clarityArr.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setClarity(i.id)}
                    className={
                      clarity === i.id
                        ? "selected-select-option"
                        : "select-option"
                    }
                  >
                    {i.name}
                  </div>
                ))}
              </div>
              <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
                Select Diamond clolor
              </div>
              <div className="flex flex-wrap">
                {colorArr.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setColor(i.id)}
                    className={
                      color === i.id
                        ? "selected-select-option"
                        : "select-option"
                    }
                  >
                    {i.name}
                  </div>
                ))}
              </div>

              <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
                Select Diamond cut
              </div>
              <div className="flex flex-wrap">
                {cutArr.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setCut(i.id)}
                    className={
                      cut === i.id ? "selected-select-option" : "select-option"
                    }
                  >
                    {i.name}
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {AddAssetData?.Category === 1 ? (
            <>
              <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
                Select Gold Karat
              </div>
              <div className="flex flex-wrap">
                {karats.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setKarat(i.id)}
                    className={
                      karat === i.id
                        ? "selected-select-option"
                        : "select-option"
                    }
                  >
                    {i.name}
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {AddAssetData?.Category === 2 ? (
            <>
              <div className="sub-card-title mt-4 md:mt-6 mb-2 md:mb-4">
                Select silver Karat
              </div>
              <div className="flex flex-wrap">
                {silverKarats.map((i) => (
                  <div
                    key={i.id}
                    onClick={() => setKarat(i.id)}
                    className={
                      karat === i.id
                        ? "selected-select-option"
                        : "select-option"
                    }
                  >
                    {i.name}
                  </div>
                ))}
              </div>
            </>
          ) : null}
          <button
            onClick={() => {
              dispatch(
                addAssetData({
                  Category: AddAssetData.Category,
                  JewelsCategory: AddAssetData.JewelsCategory,
                  isDiamond: AddAssetData.isDiamond,
                  karat: karat,
                  stones: {
                    type: cut,
                    count: count,
                    clarity: clarity,
                    color: color,
                    cut: cut,
                    carat_weight: "5",
                  },
                })
              );
              navigate("/jewelleryinfo");
            }}
            disabled={karat === null ? true : false}
            className={
              karat === null ? "disabled-next-button mt-5" : "next-button mt-5"
            }
          >
            <span className="next-text">Next</span>
            <img className="next" src={next} alt="next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default JewelleryClarity;
