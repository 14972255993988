/* eslint-disable no-unreachable */
import { AuthInstance, formDataPostAPI } from "../../utils/axios";

export const ADD_ASSET = "ADD_ASSET";
export const LIST_ASSETS = "LIST_ASSETS";
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_LISTED_ASSETS = "GET_LISTED_ASSETS";
export const GET_MY_BIDS = "GET_MY_BIDS";
export const GET_MY_PAYMENTS = "GET_MY_PAYMENTS";
export const GET_MY_ORDERS = "GET_MY_ORDERS";
export const GET_CERTIFICATES = "GET_CERTIFICATES";
export const ADD_ASSET_DATA = "ADD_ASSET_DATA";
export const GET_CONFIG = "GET_CONFIG";

export const addAssets = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await formDataPostAPI("api/add-asset", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      getAllAssets();
      getUserPortfolio();
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};
export const UploadImages = async (data) => {
  try {
    const res = await formDataPostAPI("api//upload-jewellery-images", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const updateAssets = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await formDataPostAPI("api/update-asset", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      await getAllAssets();
      await getUserPortfolio();
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const deleteAssets = async (id) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/delete_asset", { asset_id: id });
    if (res) {
      return res.data;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const acceptGift = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/accept-gift", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      await getAllAssets();
      await getUserPortfolio();
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};
export const BuyingSchemes = async (id) => {
  try {
    const res = await AuthInstance.post(
      "api/fetch_instant_buy_payment_options",
      {
        listing_id: id,
        analysis_data: JSON.parse(localStorage.getItem("analysis_data")),
      }
    );
    console.log("in_funtion");
    if (res) {
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};
export const BidingSchemes = async (id) => {
  try {
    const res = await AuthInstance.post("api/fetch_scheme_option_on_bid_won", {
      bid_id: id,
    });
    console.log("in_funtion");
    if (res) {
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};
export const OrderDetails = async (id) => {
  try {
    const res = await AuthInstance.post("api/order_details", {
      order_id: id,
    });
    console.log("in_funtion");
    if (res) {
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};
export const PaymentDetails = async (id) => {
  try {
    const res = await AuthInstance.post("api/payment_details", {
      rzp_order_id: id,
    });
    console.log("in_funtion");
    if (res) {
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const addDocument = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await formDataPostAPI("api/add-document-to-asset", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const listAsset = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/list-asset-to-marketplace", data);
    console.log("in_funtion");
    if (res) {
      getAllAssets();
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const updateListing = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/update-listing", data);
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      console.log(res);
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const removeListing = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post(
      "api/remove-listing-from-marketplace",
      data
    );
    console.log("in_funtion");
    if (res) {
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const acceptBid = async (data) => {
  try {
    console.log("in_add_assets_action");
    const res = await AuthInstance.post("api/accept-bid-on-listing", data);
    console.log("in_funtion");
    if (res) {
      await getListedAssets();
      console.log("sucessvndskdnvskdnvk");
      return res;
    } else {
      console.log("some_error_occurddvdsfvf");
      return null;
    }
  } catch (error) {
    console.log("some_error_occurddvdsfvfsdvdfvd", error);
    return null;
  }
};

export const getAllAssets = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/list-assets");
      if (res) {
        console.log("all assets", res.data);
        dispatch({
          type: LIST_ASSETS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getPaginationAssets = async (page) => {
  try {
    const res = await AuthInstance.post("api/list-assets-with-pagination", {
      page_no: page,
    });
    if (res) {
      console.log("marke_data_is_here", res);
      return res.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return null;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const getConfig = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/get-app-config");
      if (res) {
        console.log(res.data.data);
        localStorage.setItem("goldPrice", res.data.data.gold_market_price);
        localStorage.setItem("silverPrice", res.data.data.silver_market_price);
        localStorage.setItem("razorpaykey", res.data.data.razorpay_key);
        localStorage.setItem("is_bid", res.data.data.enforce_kyc_on_bid);
        localStorage.setItem("is_sell", res.data.data.enforce_kyc_on_sell);
        localStorage.setItem("razorpayKeyLoan", res.data.data.razorpay_s2_key);
        localStorage.setItem(
          "is_buy",
          res.data.data.enforce_kyc_on_instant_buy
        );
        dispatch({
          type: GET_CONFIG,
          payload: res.data.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getListedAssets = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.post(
        "api/fetch-all-listings-for-user",
        {}
      );
      if (res) {
        console.log("data_is_here", res.data);
        dispatch({
          type: GET_LISTED_ASSETS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getMyBids = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/fetch-my-bids");
      if (res) {
        console.log("data_is_here", res.data);
        dispatch({
          type: GET_MY_BIDS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getMyOrders = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/orders");
      if (res) {
        console.log("data_is_here", res.data);
        dispatch({
          type: GET_MY_ORDERS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};
export const getAllPayments = () => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/payments");
      if (res) {
        console.log("data_is_here", res.data);
        dispatch({
          type: GET_MY_PAYMENTS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getUserPortfolio = (data) => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.get("api/get-portfolio", data);
      if (res) {
        console.log("data_is_here", res.data.data);
        dispatch({
          type: GET_PORTFOLIO,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getCertificates = (data) => {
  try {
    return async (dispatch) => {
      const res = await AuthInstance.post("api/fetch-docs-for-asset", data);
      if (res) {
        dispatch({
          type: GET_CERTIFICATES,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};
export const addAssetData = (data) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: ADD_ASSET_DATA,
        payload: data,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};
