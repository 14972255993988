import React, { useEffect, useState } from "react";
import AllJewellery from "../../components/market/AllJewellery";
import DesktopFilter from "./DesktopFilter";
import MobileFilter from "./MobileFilter";
import { IoOptionsOutline } from "react-icons/io5";
import {
  getMarketPlace,
  getPublicMarketPlace,
} from "../../redux/actions/MarketAction";
import { useSelector } from "react-redux";

const Market = () => {
  const [show, setShow] = useState(false);

  const url = new URL(decodeURIComponent(window.location.href));
  const searchParams = new URLSearchParams(url.searchParams);

  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  const category =
    searchParams.get("jewel_cat") === "null" ||
    searchParams.get("jewel_cat") === null
      ? null
      : parseInt(searchParams.get("jewel_cat"));

  const sort =
    searchParams.get("sort_by") === null
      ? "recent"
      : searchParams.get("sort_by");

  const type =
    searchParams.get("type") === null ? "all" : searchParams.get("type");

  const city =
    searchParams.get("city") === null ? 1 : parseInt(searchParams.get("city"));
  const minValue =
    searchParams.get("min_price") === null
      ? 0
      : parseInt(searchParams.get("min_price"));
  const maxvalue =
    searchParams.get("max_price") === null
      ? 1000000
      : parseInt(searchParams.get("max_price"));

  const [Data, setData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filterObj, setFilterObj] = useState({
    jewel_cat: category,
    type: type,
    city: city,
    sort_by: sort,
    min_price: minValue,
    max_price: maxvalue,
    page_no: 0,
  });

  useEffect(() => {
    window.scroll(0, 0);
    isLoggedIn
      ? getMarketPlace(filterObj).then((res) => {
          setData(res.data.data);
          setPageData(res.data);
        })
      : getPublicMarketPlace(filterObj).then((res) => {
          setData(res.data.data);
          setPageData(res.data);
        });
  }, [filterObj, isLoggedIn]);

  const pageChanged = () => {
    setLoading(true);
    isLoggedIn
      ? getMarketPlace({
          ...filterObj,
          page_no: pageData.current_page + 1,
        }).then((res) => {
          setData([...Data, ...res.data.data]);
          setLoading(false);
          setPageData(res.data);
          return true;
        })
      : getPublicMarketPlace({
          ...filterObj,
          page_no: pageData.current_page + 1,
        }).then((res) => {
          setData([...Data, ...res.data.data]);
          setLoading(false);
          setPageData(res.data);
          return true;
        });
  };

  return (
    <div className="flex felx-row mb-10">
      <DesktopFilter setFilterObj={setFilterObj} filterObj={filterObj} />
      <MobileFilter
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        show={show}
        setShow={setShow}
      />
      <AllJewellery
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        setShow={setShow}
        Data={Data}
        pageData={pageData}
        pageChanged={pageChanged}
        loading={loading}
      />
      <div className="sticky-only-mobile">
        <div className="flex items-center justify-around">
          <button
            onClick={() => {
              setShow(true);
            }}
            className="asset-name flex items-center"
          >
            <IoOptionsOutline size={20} className="mr-4" />
            FILTER
          </button>
        </div>
      </div>
    </div>
  );
};

export default Market;
