import React from "react";
import JewelleryCardNew from "./JewelleryCardNew";
import Empty from "../../assets/lottie/Empty";
import { FiChevronDown } from "react-icons/fi";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const AllJewellery = ({
  setFilterObj,
  filterObj,
  Data,
  pageData,
  pageChanged,
  loading,
}) => {
  const navigate = useNavigate();
  const sortTypes = [
    { id: "recent", name: "Newest First" },
    { id: "old", name: "Oldest First" },
    { id: "low_to_high", name: "Price - Low to High" },
    { id: "high_to_low", name: "Price - high to Low" },
  ];
  const sort = sortTypes.filter((i) => {
    return i.id === filterObj.sort_by;
  });

  return (
    <div className="market-container">
      <div className="view-all-card">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="title">All Jewellery</div>
          </div>
          <div className="flex items-center justify-between w-1/2 lg:w-1/3 only-desktop">
            <div>
              <select
                value={sort[0].name}
                className="select-city cursor-pointer"
                onChange={(option) => {
                  const res = sortTypes.filter((i) => {
                    return i.name === option.target.value;
                  });
                  setFilterObj({ ...filterObj, sort_by: res[0].id });
                  navigate({
                    search: encodeURIComponent(
                      `${createSearchParams({
                        jewel_cat: filterObj.jewel_cat,
                        type: filterObj.type,
                        city: filterObj.city,
                        sort_by: res[0].id,
                      })}`
                    ),
                  });
                }}
              >
                {sortTypes.map((i) => (
                  <option id={i.id}>{i.name}</option>
                ))}
              </select>
            </div>
            <div className="profile-sub-text">
              {pageData?.total_records} Items
            </div>
          </div>
        </div>
      </div>
      {Data === null ? (
        <div className="flex flex-wrap items-center justify-around mt-2">
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-10 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
          <div className="grid gap-4 mb-10 jewellery-card p-4">
            <Skeleton variant="rectangular" width={230} height={200} />
            <div className="-mt-44 ml-2">
              <Skeleton variant="rectangular" width={130} height={20} />
            </div>
            <div className=" -mt-40 -mb-12 ml-2">
              <Skeleton variant="rectangular" width={200} height={20} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-wrap items-center">
            {Data?.map((i) => (
              <JewelleryCardNew key={i.listing_id} data={i} />
            ))}
          </div>
          {Data?.length === 0 ? (
            <div className="flex flex-col items-center justify-center">
              <Empty />
              <div className="title">No items found</div>
            </div>
          ) : pageData?.current_page <= pageData?.total_pages ? (
            loading ? (
              <div className="flex flex-wrap items-center justify-around mt-2">
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
                <div className="grid gap-4 mb-10 jewellery-card p-4">
                  <Skeleton variant="rectangular" width={230} height={200} />
                  <Skeleton variant="rectangular" width={130} height={20} />
                  <Skeleton variant="rectangular" width={200} height={20} />
                </div>
              </div>
            ) : pageData?.total_records < 40 ? null : (
              <div
                onClick={() => {
                  pageChanged();
                }}
                className="load-more-btn"
              >
                <h2>Load more</h2>
                <FiChevronDown className="profile-icon ml-4" />
              </div>
            )
          ) : null}
        </>
      )}
    </div>
  );
};

export default AllJewellery;
