import React from "react";
import backIcon from "../assets/icons/back_asset.png";

const TitleWithSeemore = ({ title, onClick, content, onBack }) => {
  return (
    <div className="view-all-card">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {onBack ? (
            <div
              onClick={() => window.history.back()}
              className="back-container"
            >
              <img src={backIcon} alt="back" className="cursor-pointer" />
            </div>
          ) : null}
          <div className="title">{title}</div>
        </div>
        {onClick ? (
          <div onClick={onClick} className="view-all cursor-pointer">
            View all
          </div>
        ) : null}
      </div>
      {content ? <div className="mt-2 content">{content}</div> : null}
    </div>
  );
};

export default TitleWithSeemore;
