import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/CookieHandler";
import {
  initiateLoanPayment,
  initiateOrderID,
} from "../../redux/actions/LoanAction";
import Carousel from "react-multi-carousel";
import useRazorpay from "react-razorpay";
import TitleWithSeemore from "../../components/TitleWithSeemore";

const MyLoanDetail = () => {
  const loandetail = JSON.parse(localStorage.getItem("loandetail"));
  const razorpayKeyLoan = localStorage.getItem("razorpayKeyLoan");
  const [loading, setLoading] = useState(false);
  console.log(loandetail);
  const navigate = useNavigate();
  const Razorpay = useRazorpay();

  const responsive = {
    tablet: {
      breakpoint: { max: 4000, min: 300 },
      items: 1,
    },
  };

  const _onPress = async () => {
    setLoading(true);
    console.log("cli kdd");
    const formData = new FormData();
    formData.append("hs_lm_map_id", loandetail?.folderId);
    formData.append("trans_payment_action_for", "interest_amount");
    formData.append(
      "lender_trans_amt",
      loandetail?.mainLenderAmount === null ? 0 : loandetail?.mainLenderAmount
    );
    formData.append(
      "co_lender_trans_amt",
      loandetail?.coLenderAmount === null ? 0 : loandetail?.coLenderAmount
    );
    formData.append("total_trans_amt", loandetail?.totalNextDue);
    formData.append("trans_pay_gateway", "Razorpay");
    formData.append("trans_status", "pending");
    formData.append("created_by", "golduno_app");

    const res = await initiateLoanPayment(formData);
    if (res) {
      _getOrderID();
    } else {
      console.log("errrrrrrrrrr____");
    }
  };

  const _getOrderID = async () => {
    const orderPayload = {
      locAccountId: loandetail?.locAccountId,
    };

    const res = await initiateOrderID(orderPayload);
    if (res) {
      await _hitRazorpayGateway(res.response.order.id);
    } else {
      alert("Some error occured");
    }
  };

  const _hitRazorpayGateway = async (orderID) => {
    setLoading(false);
    const d = getCookie("user");
    const user = JSON.parse(d);
    const amount = parseFloat(loandetail?.totalPayableInterest).toFixed(2);
    const options = {
      description: "Rutpok Loan",
      image: "https://py.ruptokcap.in/ruptoklogo.png",
      currency: "INR",
      key: razorpayKeyLoan,
      name: "Ruptok",
      order_id: orderID,
      amount: amount,
      prefill: {
        email: user.email,
        contact: user.phone,
        name: user.first_name,
      },
      handler: (res) => {
        console.log(res);
        _updateOrder(res.razorpay_payment_id);
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const _updateOrder = async (id) => {
    navigate("/myloans");
  };
  return (
    <div className="add-white-card-2 mrlb-5per mt-5 md:mt-10 prl-3per">
      <TitleWithSeemore
        title={`My Loan #${loandetail.locAccountId}`}
        viewall={false}
        onBack={true}
      />
      <div
        className="flex mt-3"
        style={{ flexWrap: window.innerWidth > 800 ? "nowrap" : "wrap" }}
      >
        <div className="w-full md:w-1/2">
          <div className="add-white-card p-3per mb-3 w-full">
            <div className="flex justify-between mt-5">
              <div>
                <div className="loan-orange-text">
                  {"₹ " +
                    parseFloat(loandetail?.totalWithdrawals).toLocaleString(
                      "en-IN"
                    )}
                </div>
                <div className="loan-sub-text">Loan Amount Disbursed</div>
              </div>
              <div>
                <div className="loan-orange-text">
                  {loandetail?.nextRepaymentDate?.slice(0, 10)}
                </div>
                <div className="loan-sub-text">Due Date</div>
              </div>
              <div>
                <div className="loan-orange-text">
                  {"₹ " +
                    parseFloat(loandetail?.foreclosureAmount).toLocaleString(
                      "en-IN"
                    )}
                </div>
                <div className="loan-sub-text">Foreclosure amount</div>
              </div>
            </div>
          </div>
          <div className="add-white-card p-3per mb-3 w-full">
            <div className="flex justify-between border-bottom">
              <div>
                <div className="loan-sub-text">Customer Name</div>
                <div className="loan-main-text">{loandetail?.displayName}</div>
              </div>
              <div className="w-20 md:w-36">
                <div className="loan-sub-text">Loan taken on</div>
                <div className="loan-main-text">
                  {loandetail?.activatedOnDate.slice(0, 10)}
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-6 border-bottom pb-6">
              <div>
                <div className="loan-sub-text">Gold loan id</div>
                <div className="loan-main-text">{loandetail?.folderId}</div>
              </div>
              <div className="w-20 md:w-36">
                <div className="loan-sub-text">Customer id</div>
                <div className="loan-main-text">{loandetail?.locAccountId}</div>
              </div>
            </div>
            <div className="mt-6 flex flex-wrap justify-between">
              <div>
                <div className="loan-sub-text">Customer Address</div>
                <div className="loan-main-text text-sm">
                  {loandetail.addressLine1 +
                    loandetail.addressLine2 +
                    loandetail.addressLine3 +
                    loandetail.street}
                </div>
              </div>
              <div>
                <div className="loan-sub-text">Scheme</div>
                <div className="loan-main-text">
                  {loandetail?.savingsProductName}
                </div>
              </div>
            </div>
          </div>
          <div className="add-white-card p-3per mb-3 w-ful">
            <div className="flex flex-col justify-between w-full">
              <div className="flex justify-between">
                <div className="loan-main-text text-xs mb-1">
                  Interest Amount
                </div>
                <div className="loan-sub-text">
                  {" ₹ " +
                    parseFloat(loandetail?.showTotalInterestDue).toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="loan-main-text text-xs mb-1">
                  Penalty charge
                </div>
                <div className="loan-sub-text">
                  {loandetail?.totalPenaltyCharge
                    ? " ₹ " +
                      parseFloat(loandetail.showTotalPenalDue).toFixed(2)
                    : " ₹0"}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="loan-main-text text-xs mb-1">
                  Transaction charge
                </div>
                <div className="loan-sub-text">
                  {loandetail?.transactionCharge
                    ? " ₹ " +
                      parseFloat(loandetail.transactionCharge).toFixed(2)
                    : " ₹0"}
                </div>
              </div>
              <div className="flex justify-between mt-3">
                <div className="loan-main-text">Total Payable</div>
                <div className="loan-orange-text">
                  {" ₹ " +
                    parseFloat(loandetail?.totalPayableInterest).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className="add-white-card p-3per mb-3 w-ful">
            <div className="flex w-full justify-between">
              <div className="loan-main-text" style={{ alignSelf: "center" }}>
                Pledge card
              </div>
              <button
                onClick={() => {
                  window.open(
                    `https://hb.ruptok.com/v2/home_service/images/customer/${loandetail.folderId}/customer_certificate.pdf`,
                    "_target"
                  );
                }}
                className="view-button ml-2"
              >
                View
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 ml-0 md:ml-6 w-full md:w-1/2">
          <div className="add-white-card p-3per">
            <div className="flex justify-between mt-8 w-full mb-10 md:mb-0">
              <div>
                <div className="loan-main-text">
                  {parseFloat(loandetail?.totalNetWeight).toFixed(2) + " gms"}
                </div>
                <div className="loan-sub-text">Net Weight</div>
              </div>
              <span>=</span>
              <div>
                <div className="loan-main-text">
                  {parseFloat(loandetail?.totalGrossWeight).toFixed(2) + " gms"}
                </div>
                <div className="loan-sub-text">Total weight</div>
              </div>
              <span>-</span>
              <div>
                <div className="loan-main-text">
                  {loandetail?.totalSMdeduction +
                    loandetail?.totalStoneDeduction}
                  gms
                </div>
                <div className="loan-sub-text">Deductions</div>
              </div>
            </div>
            {/* <div className="loan-main-text text-lg mt-4">Articles</div> */}
            {loandetail?.articles?.length === 1 ? (
              <div
                className="p-2 md:p-10"
                style={{
                  width: window.innerWidth > 600 ? 400 : 300,
                  height: window.innerWidth > 600 ? 450 : 300,
                  margin: "auto",
                }}
              >
                <div className="loan-main-text mb-5 text-lg m-auto text-center">
                  {loandetail?.articles[0].article_name}
                </div>
                <img
                  src={loandetail?.articles[0].article_document}
                  alt=""
                  style={{
                    height: window.innerWidth > 600 ? 250 : 150,
                    width: window.innerWidth > 600 ? 250 : 150,
                    objectFit: "cover",
                    margin: "auto",
                  }}
                />
                <div className="flex justify-between mt-8">
                  <div>
                    <div className="loan-main-text">
                      {parseFloat(loandetail?.articles[0]?.net_weight).toFixed(
                        2
                      ) + " gms"}
                    </div>
                    <div className="loan-sub-text">Net Weight</div>
                  </div>
                  <span>=</span>
                  <div>
                    <div className="loan-main-text">
                      {parseFloat(
                        loandetail?.articles[0]?.gross_weight
                      ).toFixed(2) + " gms"}
                    </div>
                    <div className="loan-sub-text">Total weight</div>
                  </div>
                  <span>-</span>
                  <div>
                    <div className="loan-main-text">
                      {loandetail?.articles[0]?.sm_deduction +
                        loandetail?.articles[0]?.stone_deduction}
                      gms
                    </div>
                    <div className="loan-sub-text">Dedecutions</div>
                  </div>
                </div>
              </div>
            ) : (
              <Carousel
                responsive={responsive}
                slidesToSlide={1}
                arrows={true}
                infinite={true}
                showDots={true}
                autoPlay={true}
                swipeable={true}
              >
                {loandetail?.articles?.map((i) => (
                  <div
                    className="p-2 md:p-10"
                    style={{
                      width: window.innerWidth > 600 ? 400 : 300,
                      height: window.innerWidth > 600 ? 450 : 300,
                      margin: "auto",
                    }}
                  >
                    <div className="loan-main-text mb-5 text-lg m-auto text-center">
                      {i.article_name}
                    </div>
                    <img
                      src={i.article_document}
                      alt=""
                      style={{
                        height: window.innerWidth > 600 ? 250 : 150,
                        width: window.innerWidth > 600 ? 250 : 150,
                        objectFit: "cover",
                        margin: "auto",
                      }}
                    />
                    <div className="flex justify-between mt-8">
                      <div>
                        <div className="loan-main-text">
                          {parseFloat(i?.net_weight).toFixed(2) + " gms"}
                        </div>
                        <div className="loan-sub-text">Net Weight</div>
                      </div>
                      <span>=</span>
                      <div>
                        <div className="loan-main-text">
                          {parseFloat(i?.gross_weight).toFixed(2) + " gms"}
                        </div>
                        <div className="loan-sub-text">Total weight</div>
                      </div>
                      <span>-</span>
                      <div>
                        <div className="loan-main-text">
                          {i?.sm_deduction + i?.stone_deduction}
                          gms
                        </div>
                        <div className="loan-sub-text">Dedecutions</div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          <div className="sticky-white-card">
            <div className="flex flex-col justify-center items-center mr-2">
              <div className="pay-amount-text">
                Please pay the interest amount of
              </div>
              <div className="pay-amount-text">
                {" ₹ " +
                  parseFloat(loandetail?.totalPayableInterest).toFixed(2)}
              </div>
            </div>
            <div></div>
            <button
              onClick={() => _onPress()}
              disabled={
                parseInt(loandetail?.totalPayableInterest) === 0 ? true : false
              }
              className={
                parseInt(loandetail?.totalPayableInterest) === 0
                  ? "disabled-buy-button ml-2"
                  : "buy-button ml-2"
              }
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLoanDetail;
