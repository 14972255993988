import React, { useState, useEffect } from "react";
import { addLoanApi } from "../../redux/actions/LoanAction";
import { getCookie } from "../../utils/CookieHandler";
import { addAddress, getUserAddress } from "../../redux/actions/AuthAction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddAddressModal from "../../components/AddAddressModal";
import Empty from "../../assets/lottie/Empty";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../assets/lottie/Loader.jsx";
import TitleWithSeemore from "../../components/TitleWithSeemore";

const SelectAddress = () => {
  const navigate = useNavigate();
  const { userAddresses } = useSelector((state) => state.authReducer);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [primary, setPrimary] = useState();
  const [data, setData] = useState({
    address_line_1: "",
    address_line_2: "",
    landmark: "",
    friendly_name: "",
    city: 1,
    pincode: "",
    type: 0,
  });

  const _addLoan = async () => {
    setLoading(true);
    if (
      data.address_line_1.trim() === "" ||
      data.address_line_2.trim() === "" ||
      data.landmark.trim() === ""
    ) {
      toast("Please select an address first", {
        progressStyle: { background: "#eba300" },
      });
      setLoading(false);
      return;
    }

    const d = getCookie("user");
    const user = JSON.parse(d);
    const obj = {
      Language: "English",
      Salutation: "Mr",
      First_Name: user.first_name,
      Middle_Name: "",
      Last_Name: user.last_name,
      Gender: "Male",
      DOB: "1997-09-27",
      Mobile_Number: user.phone,
      Email: user.email,
      Address_Type: "Current",
      Visit_Address_Type: "Current",
      Address_1: data.address_line_1,
      Address_2: data.address_line_2,
      Landmark: data.landmark,
      Country: "India",
      State: data.city,
      City: data.city,
      Zip: data.pincode,
      Permanent_Address_1: "",
      Permanent_Address_2: "",
      Permanent_Address_Landmark: "",
      Permanent_City: data.city,
      Permanent_Country: "India",
      Permanent_States: "",
      Permanent_Pincode: "",
      Stage: "Contact",
      schema_id: id,
      loanAmount: "200000",
    };

    const res = await addLoanApi(obj);
    if (res) {
      if (res?.status === 200) {
        toast("Thank you for applying", {
          progressStyle: { background: "#eba300" },
        });
        setLoading(false);
        navigate("/");
      }
    } else {
      console.log("_______res___else____issue");
      toast("Some error occured", { progressStyle: { background: "#eba300" } });
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);
  // add adress-----------
  const AddAddress = () => {
    const res = addAddress(data);
    dispatch(getUserAddress());
    if (res) {
      setShowModal(false);
      setData({
        address_line_1: "",
        address_line_2: "",
        landmark: "",
        friendly_name: "",
        city: 1,
        pincode: "",
        type: 0,
      });
    }
  };
  const setPrimaryAddress = (id) => {
    setPrimary(id);
  };

  return (
    <div className="container mx-auto p-5per bg-white">
      <ToastContainer />
      <TitleWithSeemore title="Select Address" viewall={false} onBack={true} />
      <div className="flex flex-wrap p-2 md:p-5 justify-between">
        {userAddresses?.length === 0 ? (
          <div className="container no-biddings mb-20">
            <Empty />
            <span className="text-slate-300 text-xs md:text-sm mt-4">
              Currently there is no address Add an address
            </span>
          </div>
        ) : null}
        {userAddresses?.map((i) => {
          return (
            <div
              onClick={() => {
                setPrimaryAddress(i.id);
                setData(i);
              }}
              key={i.id}
              className="px-4 add-white-card p-2 md:p-5 flex mb-6 items-center flex double-card items-center"
            >
              <div className="mr-5 md:mr-10">
                <div
                  className={
                    primary === i.id ? "selected-radio-icon" : "radio-icon"
                  }
                ></div>
              </div>
              <div>
                <div className="address_title">{i.friendly_name}</div>
                <div className="address_name">
                  {i.address_line_1 + " " + i.address_line_2}
                </div>
                <div className="address_name">{i.landmark}</div>
                <div className="address_name">
                  {i.city_name + " - " + i.pincode}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {loading ? (
        <Loader size={200} />
      ) : (
        <div className="flex">
          <button
            onClick={() => {
              setShowModal({ show: true });
            }}
            className="upload-button ml-2 md:ml-10"
          >
            Add Address
          </button>
          <button
            onClick={() => {
              _addLoan();
            }}
            className="sticky-white-button ml-2 md:ml-10"
          >
            Apply Loan
          </button>
        </div>
      )}
      <AddAddressModal
        setShowModal={setShowModal}
        showModal={showModal}
        setValue={setData}
        value={data}
        onClick={AddAddress}
      />
    </div>
  );
};

export default SelectAddress;
