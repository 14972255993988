import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_LOGIN,
  GET_ADDRESS,
  GET_CITIES,
  SET_SIGNUPPAGE,
} from "../actions/AuthAction";
import { getCookie } from "../../utils/CookieHandler";
const token = getCookie("token");
const user = getCookie("user");
console.log(token);

const initialState = {
  isLoggedIn: token && user ? true : false,
  userAddresses: null,
  cities: [],
  signUpPage: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, isLoggedIn: true };
    case LOGOUT_USER:
      console.log("in_logout_function");
      return { ...state, isLoggedIn: false };
    case SET_LOGIN:
      console.log("set_user_login_reducer");
      return { ...state, isLoggedIn: true };
    case GET_ADDRESS:
      console.log("set_user_login_reducer");
      return { ...state, userAddresses: action.payload.data };
    case GET_CITIES:
      console.log("set_user_login_reducer");
      return { ...state, cities: action.payload.data };
    case SET_SIGNUPPAGE:
      return { ...state, signUpPage: action.payload };
    default:
      return state;
  }
}

export default authReducer;
