import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import SideDrawer from "./SideDrawer";
import BackDrop from "./BackDrop";
import SideMenu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SetSignupPage } from "../../redux/actions/AuthAction";

export default function Header() {
  const dispatch = useDispatch();
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };
  const backDropClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
    dispatch(SetSignupPage(false));
  };
  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  const navigate = useNavigate();
  return (
    <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 9998 }}>
      <div className="login-header-container">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="flex justify-start"
        >
          <img
            src={logo}
            alt=""
            style={{
              height: "25px",
            }}
          />
        </div>
        <div className="flex items-center">
          {isLoggedIn ? (
            <ul className="only-desktop flex items-center">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/market"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Market
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portfolio"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/myloans"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Loans
                </NavLink>
              </li>
              <li className="flex items-center">
                <div className="text-c-black">|</div>
              </li>
              <div className="toggle-btn ml-4">
                <SideMenu click={drawerToggleClickHandler} />
              </div>
            </ul>
          ) : (
            <ul className="only-desktop flex" style={{ alignItems: "center" }}>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <a
                  href="https://www.ruptok.com/schemes/"
                  className="text-c-black"
                >
                  Schemes
                </a>
              </li>
              <li>
                <NavLink
                  to="/market"
                  className={({ isActive }) =>
                    isActive ? " text-yellow" : " text-c-black"
                  }
                >
                  Marketplace
                </NavLink>
              </li>
              <li>
                <a href="https://www.ruptok.com" className="text-c-black">
                  Apply Now
                </a>
              </li>
              <li className="flex items-center">
                <div className="text-c-black">|</div>
              </li>
              <li>
                <NavLink
                  to="/"
                  className="signup-button"
                  onClick={() => {
                    dispatch(SetSignupPage(false));
                  }}
                >
                  Login
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/"
                  onClick={() => {
                    // dispatch(SetSignupPage(true));
                  }}
                  className="signup-button"
                >
                  Sign up
                </NavLink>
              </li> */}
            </ul>
          )}
          {window.innerWidth < 700 ? (
            <div className="toggle-btn ml-4">
              <SideMenu click={drawerToggleClickHandler} />
            </div>
          ) : null}
        </div>
        <SideDrawer
          show={sideDrawerOpen}
          drawerToggleClickHandler={drawerToggleClickHandler}
          click={backDropClickHandler}
        />
        {sideDrawerOpen ? <BackDrop click={backDropClickHandler} /> : <></>}
      </div>
    </div>
  );
}
