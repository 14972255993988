import {
  LIST_ASSETS,
  GET_PORTFOLIO,
  GET_LISTED_ASSETS,
  GET_MY_BIDS,
  GET_MY_ORDERS,
  GET_MY_PAYMENTS,
  GET_CERTIFICATES,
  ADD_ASSET_DATA,
  GET_CONFIG,
} from "../actions/AssetAction";

const initialState = {
  allAssets: null,
  categories: [],
  totalPortfolio: null,
  myListedAssets: null,
  myBids: null,
  myOrders: null,
  myPayments: null,
  certificates: [],
  razorpayKey: null,
  androidVersion: null,
  iosVersion: null,
  checkVersion: 0,
  banners: null,
  AddAssetData: null,
  razorpayKeyLoan: null,
};

function assetsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ASSETS:
      return { ...state, allAssets: action.payload.data };
    case GET_PORTFOLIO:
      return {
        ...state,
        categories: action.payload.data.categories,
        totalPortfolio: action.payload.data.totalPortfolio,
      };
    case GET_LISTED_ASSETS:
      return { ...state, myListedAssets: action.payload.data };
    case ADD_ASSET_DATA:
      return { ...state, AddAssetData: action.payload };
    case GET_MY_BIDS:
      return { ...state, myBids: action.payload.data };
    case GET_MY_PAYMENTS:
      return { ...state, myPayments: action.payload.data };
    case GET_MY_ORDERS:
      if (action.payload.data === "failed") {
        return { ...state, myOrders: [] };
      } else {
        return { ...state, myOrders: action.payload.data };
      }
    case GET_CERTIFICATES:
      return { ...state, certificates: action.payload.data };
    case GET_CONFIG:
      return {
        ...state,
        razorpayKey: action.payload.razorpay_keyy,
        androidVersion: action.payload.android_version,
        iosVersion: action.payload.ios_version,
        checkVersion: action.payload.check_version,
        razorpayKeyLoan: action.payload.razorpay_s2_key,
        banners: {
          sell_banner: action.payload.sell_banner,
          buy_banner: action.payload.buy_banner,
          loan_banner: action.payload.loan_banner,
        },
      };
    default:
      return state;
  }
}

export default assetsReducer;
