import React, { useEffect, useState } from "react";
import Add from "../../assets/icons/add.png";
import docs from "../../assets/icons/google-docs.png";
import { useDispatch, useSelector } from "react-redux";
import { getCertificates, addDocument } from "../../redux/actions/AssetAction";
import DocNameModal from "../../components/portfolio/DocNameModal";
import SellModal from "../../components/portfolio/SellModal";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  getPortfolioProduct,
  setPortfolioProduct,
} from "../../redux/actions/MarketAction";
import { getCookie } from "../../utils/CookieHandler";
import MultiImageSlider from "../../components/MultiImageSlider";
import ProductPageSkeleton from "../../components/ProductPageSkelton";

const PortfolioProduct = () => {
  const [fileResponse, setfile] = useState();
  const [askingPrice, setAskingPrice] = useState();
  const [date, setDate] = useState("");
  const [showDocModal, setShowDocModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [documentName, setdocumentName] = useState("");
  const [data, setData] = useState(null);
  const [type, setType] = useState(0);
  const [bind, setBind] = useState(0);
  const [stoneCharges, setStoneCharges] = useState("");
  const [makingCharges, setMakingCharges] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getPortfolioProduct(id).then((res) => {
      if (res?.message === "Asset is invalid") {
        navigate("/portfolio");
      } else {
        setData(res.data);
      }
    });
    dispatch(getCertificates({ asset_id: id }));
  }, [id, dispatch, navigate]);
  const { certificates } = useSelector((state) => state?.assetsReducer);

  const { is_kyc } = JSON.parse(getCookie("user"));
  const is_sell = JSON.parse(localStorage.getItem("is_sell"));
  // handdling doc upload--
  const AddDoc = async () => {
    const formData = new FormData();
    formData.append("document", fileResponse);
    formData.append("doc_name", documentName);
    formData.append("asset_id", data?.id);
    const res = await addDocument(formData);
    if (res) {
      toast("Added", {
        progressStyle: { background: "#eba300" },
      });
      setShowDocModal(false);
      dispatch(getCertificates({ asset_id: data.id }));
    }
  };
  const goldPrice = localStorage.getItem("goldPrice");
  const silverPrice = localStorage.getItem("silverPrice");

  // handdling sell upload--
  const sellItem = async () => {
    localStorage.setItem(
      "sellProduct",
      JSON.stringify({
        asset_id: data?.id,
        asking_price: askingPrice,
        target_date: date,
        is_new: type,
        market_bind_status: bind,
        making_charges: makingCharges,
        stone_charges: stoneCharges,
      })
    );
    navigate("/sellitemaddress");
  };
  if (data === null) {
    return <ProductPageSkeleton />;
  } else
    return (
      <div className="product-page flex-1 flex flex-wrap">
        <button
          onClick={() => {
            navigate("/portfolio");
          }}
          className="back-button"
        >
          <FiArrowLeft className="profile-icon" />
        </button>
        <ToastContainer />
        <div className="product-container">
          <MultiImageSlider
            data={
              data?.jewellery_images
                ? [data?.image_url, ...data?.jewellery_images]
                : [data?.image_url]
            }
          />
          <div className="white-card-2 mt-4">
            <div className="product-title mb-2">Product Details</div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Jewellery Category</span>
              <span className="detail-value">: {data?.jewellery_category}</span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Purity </span>
              <span className="detail-value">: {data?.karat} karat</span>
            </div>
            {data?.jewellery_description ? (
              <div className="flex justify-between mt-1">
                <span className="detail-name">Description </span>
                <span className="detail-value">
                  : {data?.jewellery_description}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex-1">
          <div className="white-card flex justify-between mt-4 md:mt-0 items-start">
            <div>
              <div className="product-title">{data?.name}</div>
              <div className="product-karat mt-1">{data?.karat} karat</div>
              <div className="flex">
                <div>
                  <div className="product-price mt-4">
                    {"₹ " +
                      parseFloat(data?.market_worth).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                  </div>
                  <div className="text-slate-300 text-sm mt-1">
                    Market Worth
                  </div>
                </div>
                <div className="ml-10">
                  <div className="product-price mt-4">
                    {"₹ " +
                      parseFloat(data?.expected_ltv).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                  </div>
                  <div className="text-slate-300 text-sm mt-1">
                    loan eligilty
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setPortfolioProduct(data);
                navigate(`/editproduct/${id}`);
              }}
            >
              <MdOutlineModeEditOutline size={30} color="#eba300" />
            </button>
          </div>
          <div className="white-card mt-4">
            <div className="product-title">Weight</div>
            <div className="flex">
              <div className="mr-4">
                <div className="product-weight">{data?.net_weight} GM</div>
                <div className="text-slate-300 text-xs mt-1 text-center">
                  Net Weight*
                </div>
              </div>
              <div>
                <div className="product-weight">{data?.gross_weight} GM</div>
                <div className="text-slate-300 text-xs mt-1 text-center">
                  Gross Weight*
                </div>
              </div>
            </div>
          </div>
          <div className="white-card mt-4">
            <div className="product-title">Price Breakdown</div>
            <div className="text-slate-300 text-xs md:text-sm">
              Price may vary subject to the market value of item*
            </div>
            <div>
              <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                <span className="breakdown-component flex-2">
                  {data?.type === 1 ? `Gold (${data?.karat}Kt)` : `Silver`}
                </span>
                <span className="flex-1 breakdown-heading">
                  {data?.type === 1
                    ? "₹ " +
                      parseFloat(goldPrice).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })
                    : "₹ " +
                      parseFloat(silverPrice).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                </span>
                <span className="flex-1 breakdown-heading">
                  {data?.net_weight} GM
                </span>
                <span className="flex-1 breakdown-heading">
                  {"₹ " +
                    parseFloat(data?.market_worth).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
            </div>
          </div>
          <div className="sticky-white-card ml-0 md:ml-3">
            <button
              onClick={() => {
                navigate("/schemeselection");
              }}
              className="bid-button"
            >
              Apply for loan
            </button>
            {data?.listing_id !== null && data ? (
              <button
                onClick={() => {
                  navigate(`/listingdetail/${data.listing_id}`);
                }}
                className="buy-button"
              >
                Manage listing
              </button>
            ) : (
              <button
                onClick={() => {
                  is_sell === 1
                    ? is_kyc === 0
                      ? navigate("/startkyc")
                      : setShowSellModal(true)
                    : setShowSellModal(true);
                }}
                className="buy-button"
              >
                Sell item
              </button>
            )}
          </div>
          <div className="white-card mt-4">
            <div className="product-title">Certifications</div>
            <div className="flex w-ful">
              {certificates?.map((i) => (
                <a
                  key={i.id}
                  href={i.doc_link}
                  target="_blank"
                  className="flex-col flex items-center justify-center mr-4"
                  rel="noreferrer"
                >
                  <img src={docs} alt="doc icon" className="doc-icon" />
                  <div className="text-slate-300 text-xs md:text-sm mt-2">
                    {i.doc_name}
                  </div>
                </a>
              ))}
              <label className="text-slate-300 text-xs md:text-sm mt-2 flex-col flex items-center doc-upload justify-center cursor-pointer">
                <img src={Add} alt="Add icon" className="add-doc mb-2" />
                <input
                  type="file"
                  title="Add document"
                  style={{ display: "none" }}
                  accept="application/pdf"
                  onChange={(e) => {
                    setfile(e.target.files[0]);
                    setShowDocModal(true);
                  }}
                />
                Add document
              </label>
            </div>
          </div>
        </div>
        <DocNameModal
          setdocumentName={setdocumentName}
          documentName={documentName}
          showModal={showDocModal}
          setShowModal={setShowDocModal}
          onClick={AddDoc}
        />
        <SellModal
          setShowModal={setShowSellModal}
          showModal={showSellModal}
          setAskingPrice={setAskingPrice}
          AskingPrice={askingPrice}
          date={date}
          type={type}
          makingCharges={makingCharges}
          stoneCharges={stoneCharges}
          setMakingCharges={setMakingCharges}
          setStoneCharges={setStoneCharges}
          setType={setType}
          bind={bind}
          setBind={setBind}
          setDate={setDate}
          onClick={sellItem}
          data={data}
        />
      </div>
    );
};

export default PortfolioProduct;
