import { Skeleton } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CustomCarousel = () => {
  const navigate = useNavigate();
  const { banners } = useSelector((state) => state.assetsReducer);
  const responsive = {
    tablet: {
      breakpoint: { max: 4000, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  if (banners === null) {
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          justifyContent: "space-around",
          marginTop: "20px",
        }}
      >
        <Skeleton
          variant="rectangular"
          height="220px"
          width="43%"
          style={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="rectangular"
          height="220px"
          width="43%"
          style={{ borderRadius: "10px" }}
        />
      </div>
    );
  } else
    return (
      <>
        <div className="carousel-container m-auto">
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            arrows={false}
            infinite={true}
            // autoPlay={true}
            // autoPlaySpeed={2000}
          >
            <div
              className="carousel-image-container"
              onClick={() => {
                navigate("/market");
              }}
            >
              <img
                src={banners?.buy_banner}
                alt="Carousel"
                className="carousel-image"
              />
            </div>
            <div
              className="carousel-image-container"
              onClick={() => {
                navigate("/myloans");
              }}
            >
              <img
                src={banners?.loan_banner}
                alt="Carousel"
                className="carousel-image"
              />
            </div>
          </Carousel>
        </div>
      </>
    );
};
export default CustomCarousel;
