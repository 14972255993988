import React from "react";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { AiOutlineClose } from "react-icons/ai";

const ShareModal = ({ setShowModal, showModal, data }) => {
  console.log("---------", data);
  const url = `Hey, Check out this beautiful ${data?.name} at  ₹${parseFloat(
    data?.asking_price
  ).toLocaleString("en-IN", {
    maximumFractionDigits: 0,
  })} from ${window.location.href}`;
  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal-2 relative bg-white rounded-lg shadow">
          <div className="w-full flex justify-between items-center px-6 py-4">
            <div style={{ fontSize: 20, fontWeight: "normal" }}>Share</div>
            <AiOutlineClose
              size={window.innerWidth > 700 ? 30 : 20}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>

          {/* ---------- main body--------- */}
          <div
            className="px-6 pb-4 flex justify-center"
            style={
              window.innerWidth > 700
                ? {}
                : { width: "100vw", overflowX: "scroll" }
            }
          >
            <EmailShareButton
              url={url}
              style={
                window.innerWidth > 500
                  ? {}
                  : window.innerWidth > 400
                  ? { marginLeft: "30%" }
                  : { marginLeft: "80%" }
              }
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <EmailIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Email</div>
                </div>
              }
            />
            <FacebookShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <FacebookIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Facebook</div>
                </div>
              }
            />
            <LinkedinShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <LinkedinIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Linkedin</div>
                </div>
              }
            />
            <TelegramShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <TelegramIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Telegram</div>
                </div>
              }
            />
            <WhatsappShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <WhatsappIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Whatsapp</div>
                </div>
              }
            />
            <TwitterShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <TwitterIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Twitter</div>
                </div>
              }
            />
            <TumblrShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <TumblrIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Tumblr</div>
                </div>
              }
            />
            <PinterestShareButton
              url={url}
              className="m-4"
              children={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <PinterestIcon
                    round={true}
                    size={window.innerWidth > 700 ? 60 : 40}
                  />
                  <div className="share-text">Pinterest</div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ShareModal;
