import React from "react";
import { useNavigate } from "react-router-dom";

const JewelleryCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(`/portfolioproduct/${data.id}`);
      }}
      className="flex flex-col my-4 md:mr-6 rounded jewellery-card-portfolio"
    >
      <div className="jewellery-image-container">
        {data?.thumbnail_url ? (
          <img
            src={data?.thumbnail_url}
            alt="Jewellery"
            className="jewellery-image"
          />
        ) : (
          <img
            src={data?.image_url}
            alt="Jewellery"
            className="jewellery-image"
          />
        )}
      </div>
      {/* <span className="card-title">
        {"₹ " +
          parseFloat(data?.market_worth).toLocaleString("en-IN", {
            maximumFractionDigits: 0,
          })}
      </span> */}
      <div className="flex items-center justify-between w-90per my-2">
        <span className="asset-amount text-center text-black flex-1">
          {data?.name}
        </span>
        {/* <div className="weight-card"> */}
        {/* <span className="asset-weight">{data?.net_weight} GM</span> */}
        {/* </div> */}
      </div>
      <div className="flex flex-wrap mt-3 mb-6">
        <div className="w-1/2">
          <h3 className="portfolio-red-text text-center">{`₹ ${data.market_worth}`}</h3>
          <p className="portfolio-secondary-text text-center">Market value</p>
        </div>
        <div className="w-1/2">
          <h3 className="portfolio-red-text text-center">{`${data.gross_weight} GM`}</h3>
          <p className="portfolio-secondary-text text-center">Gross Weight</p>
        </div>
        <div className="w-1/2 mt-2 md:mt-4">
          <h3 className="portfolio-red-text text-center">{`₹ ${data.expected_ltv}`}</h3>
          <p className="portfolio-secondary-text text-center">
            Loan Eligibility
          </p>
        </div>
        <div className="w-1/2 mt-2 md:mt-4">
          <h3 className="portfolio-red-text text-center">{`${data.net_weight} GM`}</h3>
          <p className="portfolio-secondary-text text-center">Net Weight</p>
        </div>
      </div>
    </button>
  );
};

export default JewelleryCard;
