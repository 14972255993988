import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddAddressModal from "../components/AddAddressModal";
import Empty from "../assets/lottie/Empty";
import {
  addAddress,
  getUserAddress,
  updateAddress,
} from "../redux/actions/AuthAction";
import { FiMapPin } from "react-icons/fi";
import { HiOutlinePencil } from "react-icons/hi";
import TitleWithSeemore from "../components/TitleWithSeemore";
import { ToastContainer } from "react-toastify";
import UpdateAddressModal from "../components/UpdateAddressModal";
import { Skeleton } from "@mui/material";

const Addresses = () => {
  const { userAddresses } = useSelector((state) => state.authReducer);
  const [showAModal, setShowAModal] = useState(false);
  const [showUModal, setShowUModal] = useState(false);
  const [addressId, setaddressId] = useState(null);
  const [data, setData] = useState({
    address_line_1: "",
    address_line_2: "",
    landmark: "",
    friendly_name: "",
    city: 1,
    pincode: "",
    type: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAddress());
  }, [dispatch]);

  // add adress-----------
  const AddAddress = () => {
    const res = addAddress(data);
    if (res) {
      dispatch(getUserAddress());
      setShowAModal(false);
      resetState();
    }
  };

  const UpdateAddress = () => {
    updateAddress({ address_id: addressId, ...data }).then((res) => {
      if (res) {
        dispatch(getUserAddress());
        setShowUModal(false);
        resetState();
      }
    });
  };

  const resetState = () => {
    setData({
      address_line_1: "",
      address_line_2: "",
      landmark: "",
      friendly_name: "",
      city: 1,
      pincode: "",
      type: 0,
    });
  };
  return (
    <div className="bg-white mrlb-5per mt-6 pb-10 ">
      <ToastContainer />
      <TitleWithSeemore title="My Addresses" viewall={false} onBack={true} />
      {userAddresses === null ? (
        <div className="ml-10 mb-20">
          <div className="grid gap-4 mt-10">
            <Skeleton variant="rectangular" width={300} height={20} />
            <Skeleton variant="rectangular" width={300} height={15} />
            <Skeleton variant="rectangular" width={300} height={15} />
            <Skeleton variant="rectangular" width={300} height={15} />
          </div>
          <div className="grid gap-4 mt-10">
            <Skeleton variant="rectangular" width={300} height={20} />
            <Skeleton variant="rectangular" width={300} height={15} />
            <Skeleton variant="rectangular" width={300} height={15} />
            <Skeleton variant="rectangular" width={300} height={15} />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap p-4 md:p-5 justify-between">
          {userAddresses?.length === 0 ? (
            <div className="no-biddings mb-20">
              <Empty />
              <span className="text-slate-300 text-xs md:text-sm mt-4">
                Currently there is no address Add an address
              </span>
            </div>
          ) : null}
          {userAddresses?.map((i) => {
            return (
              <div
                key={i.id}
                className="add-white-card p-2 md:p-5 flex mb-10 flex double-card justify-between"
              >
                <div className="items-center flex">
                  <div className="mr-5 md:mr-10">
                    <FiMapPin className="profile-icon" />
                  </div>
                  <div>
                    <div className="address_title">{i.friendly_name}</div>
                    <div className="address_name">
                      {i.address_line_1 + " " + i.address_line_2}
                    </div>
                    <div className="address_name">{i.landmark}</div>
                    <div className="address_name">
                      {i.city_name + " - " + i.pincode}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col h-full justify-between items-center">
                  <button
                    onClick={() => {
                      setaddressId(i.id);
                      setData({
                        address_line_1: i.address_line_1,
                        address_line_2: i.address_line_2,
                        landmark: i.landmark,
                        friendly_name: i.friendly_name,
                        city: i.city,
                        pincode: i.pincode,
                        type: 0,
                      });
                      setShowUModal(true);
                    }}
                    className="edit-button"
                  >
                    <HiOutlinePencil size={20} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <button
        onClick={() => {
          setShowAModal(true);
        }}
        className="upload-button ml-2 md:ml-10"
      >
        Add Address
      </button>
      <AddAddressModal
        setShowModal={setShowAModal}
        showModal={showAModal}
        setValue={setData}
        value={data}
        onClick={AddAddress}
        resetState={resetState}
      />
      <UpdateAddressModal
        setShowModal={setShowUModal}
        showModal={showUModal}
        setValue={setData}
        value={data}
        onClick={UpdateAddress}
        resetState={resetState}
        id={addressId}
      />
    </div>
  );
};

export default Addresses;
