import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxNPWHLieHJDxeCkLZaJYMys4JJ9VsZiI",
  authDomain: "customer-uat.firebaseapp.com",
  databaseURL: "https://customer-uat-default-rtdb.firebaseio.com",
  projectId: "customer-uat",
  storageBucket: "customer-uat.appspot.com",
  messagingSenderId: "1084559035923",
  appId: "1:1084559035923:web:b092cde9584a91fe0fb9e6",
  measurementId: "G-ECZRSK84CD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const gettoken = () => {
  return getToken(messaging)
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
