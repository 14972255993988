import React from "react";
import { useNavigate } from "react-router-dom";

const ListingCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/listingdetail/${data.listing_id}`);
      }}
      className="add-white-card p-2 md:p-5 flex mb-6 items-center double-card cursor-pointer"
    >
      <img className="portfolio-image" src={data.image_url} alt="" />
      <div className="ml-4 md:ml-10">
        <div className="listing_name">{data.name}</div>
        <div className="listing_title">
          Asking Price :{" "}
          <span className="listing_price">₹ {data.asking_price}</span>
        </div>
        {data?.is_approved === 0 ? (
          <div className="is_approved_0">
            <div className="is_approved_text_0">{"LISTING PENDING "}</div>
          </div>
        ) : null}
        {data?.is_approved === 1 ? (
          <div className="is_approved_1">
            <div className="is_approved_text_1">{"LISTING APPROVED"}</div>
          </div>
        ) : null}
        {data?.is_approved === 2 ? (
          <div className="is_approved_2">
            <div className="is_approved_text_2">{"LISTING REJECTED"}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingCard;
