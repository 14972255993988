import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../assets/lottie/Empty";
import {
  getItemBids,
  getMakretProductNew,
} from "../../redux/actions/MarketAction";
import {
  removeListing,
  getListedAssets,
  updateListing,
} from "../../redux/actions/AssetAction";
import { useNavigate, useParams } from "react-router-dom";
import SellModal from "../../components/portfolio/SellModal";
import { toast, ToastContainer } from "react-toastify";
import AcceptBidModal from "../../components/profile/AcceptBidModal";
import { FiArrowLeft } from "react-icons/fi";
import MultiImageSlider from "../../components/MultiImageSlider";
import CustomAlert from "../../components/CustomAlert";

const ListingDetail = () => {
  const [data, setdata] = useState();
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [askingPrice, setAskingPrice] = useState(null);
  const [selectedBid, setSelectedBid] = useState(null);
  const [acceptModal, setAcceptModal] = useState(false);
  const [date, setDate] = useState(null);
  const [showSellModal, setShowSellModal] = useState(false);
  const [type, setType] = useState(null);
  const [show, setshow] = useState(false);
  const [bind, setBind] = useState(null);
  const [stoneCharges, setStoneCharges] = useState("");
  const [makingCharges, setMakingCharges] = useState("");
  const { bidList } = useSelector((state) => state?.marketReducer);

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(getItemBids({ listing_id: id }));
    getMakretProductNew(id).then((res) => {
      if (res.message === "There is not listing exists with this ID.") {
        navigate("/mylistings");
      } else {
        setdata(res.data);
        setAskingPrice(res.data.asking_price);
        setDate(res.data.target_date.slice(0, 10));
        setType(res.data.listing_type);
        setBind(res.data.market_bind_status);
        setMakingCharges(res.data?.making_charges_percent);
        setStoneCharges(res.data?.stone_charges_percent);
      }
    });
  }, [showSellModal, id, dispatch, navigate]);

  const __removelisting = async () => {
    console.log("remove_listing");
    const formData = { listing_id: data?.listing_id };
    const res = await removeListing(formData);
    if (res) {
      toast("Listing removed successfully.", {
        progressStyle: { background: "#eba300" },
      });
      dispatch(getListedAssets());
      navigate("/mylistings");
      setshow(false);
    }
  };

  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  const goldPrice = localStorage.getItem("goldPrice");

  const silverPrice = localStorage.getItem("silverPrice");

  const _onPress = async () => {
    const d = {
      listing_id: data?.listing_id,
      asking_price: askingPrice,
      target_date: date,
      type: type,
      market_bind_status: bind,
      stone_charges: stoneCharges,
      making_charges: makingCharges,
    };
    const res = await updateListing(d);
    if (res) {
      toast(res.data.message, {
        progressStyle: { background: "#eba300" },
      });
      getMakretProductNew(id).then((res) => {
        setdata(res.data);
        setAskingPrice(res.data.asking_price);
        setDate(res.data.target_date);
        setType(res.data.listing_type);
      });
    }
  };

  const _acceptBid = (item) => {
    if (data?.status !== 0) {
      toast("Bid is already accepted for this item", {
        progressStyle: { background: "#eba300" },
      });
      return;
    }
    console.log("wselected_bid____", item);
    setSelectedBid(item);
    setAcceptModal(true);
  };

  const _onAccpeted = async () => {
    setAcceptModal(false);
    navigate("/mylistings");
  };
  return (
    <div className="product-page flex-1 justify-between flex flex-wrap pb-10">
      <CustomAlert
        show={show}
        text="Are you sure?"
        buttonText="Remove"
        setShow={setshow}
        onClick={() => {
          __removelisting();
        }}
      />
      <button
        onClick={() => {
          navigate("/mylistings");
        }}
        className="back-button"
      >
        <FiArrowLeft className="profile-icon" />
      </button>
      <ToastContainer />
      <div className="product-container">
        <MultiImageSlider
          data={
            data?.jewellery_images
              ? [data?.image_url, ...data?.jewellery_images]
              : [data?.image_url]
          }
        />
      </div>
      <div style={{ width: "55%" }}>
        {window.innerWidth < 800 ? (
          <img src={data?.image_url} alt="Product" className="listing-image" />
        ) : null}{" "}
        <div className="white-card ml-0 flex justify-between">
          <div>
            <div className="product-title">{data?.name}</div>
            <div className="text-slate-300 text-sm my-2">
              {data?.jewellery_description}
            </div>
            <div className="product-karat mt-1">{data?.karat} karat</div>
            <div className="flex">
              <div>
                <div className="product-price mt-4">
                  {"₹ " +
                    parseFloat(data?.asking_price).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </div>
                <div className="text-slate-300 text-sm mt-1">Asking Price</div>
              </div>
              <div className="ml-10">
                <div className="product-price mt-4">
                  {"₹ " +
                    parseFloat(data?.market_worth).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </div>
                <div className="text-slate-300 text-sm mt-1">
                  Estimated market value
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="white-card mt-4 ml-0">
          <div className="product-title">Weight</div>
          <div className="flex">
            <div className="mr-4">
              <div className="product-weight">{data?.net_weight} GM</div>
              <div className="text-slate-300 text-xs mt-1 text-center">
                Net Weight*
              </div>
            </div>
            <div>
              <div className="product-weight">{data?.gross_weight} GM</div>
              <div className="text-slate-300 text-xs mt-1 text-center">
                Gross Weight*
              </div>
            </div>
          </div>
        </div>
        {data?.is_approved === 2 ? (
          <div className="white-card mt-4">
            <div className="product-karat text-black">Rejection Reason</div>
            <div className="text-slate-400">{data?.rejection_reason}</div>
          </div>
        ) : null}
        <div className="white-card ml-0 mt-4">
          <div className="product-title">Price Breakdown</div>
          <div className="text-slate-300 text-xs md:text-sm">
            Price may vary subject to the market value of item*
          </div>
          <div>
            <div className="flex items-center justify-around breakdown-border mt-2 p-4">
              <span className="breakdown-component flex-2">
                {data?.type === 1 ? `Gold (${data?.karat}Kt)` : `Silver`}
              </span>
              {isLoggedIn ? (
                <span className="flex-1 breakdown-heading">
                  {data?.type === 1
                    ? "₹ " +
                      parseFloat(goldPrice).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })
                    : "₹ " +
                      parseFloat(silverPrice).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                </span>
              ) : null}
              <span className="flex-1 breakdown-heading">
                {data?.net_weight} GM
              </span>
              <span className="flex-1 breakdown-heading">
                {"₹ " +
                  parseFloat(data?.market_worth).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  })}
              </span>
            </div>
            {data?.making_charges ? (
              <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                <span className="breakdown-component flex-2">
                  Making Charges
                </span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading">
                  {"₹ " +
                    parseFloat(data?.making_charges).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
            ) : null}
            {data?.stone_charges ? (
              <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                <span className="breakdown-component flex-2">
                  Stone Charges
                </span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading">
                  {"₹ " +
                    parseFloat(data?.stone_charges).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
            ) : null}
            {data?.gst_amount ? (
              <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                <span className="breakdown-component flex-2">GST </span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading"></span>
                <span className="flex-1 breakdown-heading">
                  {"₹ " +
                    parseFloat(data?.gst_amount).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        {data?.is_approved === 1 ? (
          data?.listing_type === 1 ? null : (
            <div className="white-card mt-4 mb-10 ">
              <div className="border-bottom">
                <div className="product-title">Current Biddings</div>
                <div className="text-slate-300 text-xs md:text-sm">
                  Bidding amount is according to the Asking Price of item*
                </div>
                {bidList ? (
                  bidList?.length === 0 ? (
                    <div className="container no-biddings">
                      <Empty />
                      <span className="text-slate-300 text-xs md:text-sm mt-4">
                        Currently there are no biddings
                      </span>
                    </div>
                  ) : (
                    bidList?.map((i) => (
                      <div
                        className="flex justify-between mt-4"
                        onClick={() => {
                          _acceptBid(i);
                        }}
                      >
                        <span className="bid-name">
                          {i.customer_name === null
                            ? "Unknown"
                            : i.customer_name}
                        </span>
                        <span className="bid-amount">
                          {"₹ " +
                            parseFloat(i?.quoted_amount).toLocaleString(
                              "en-IN",
                              {
                                maximumFractionDigits: 0,
                              }
                            )}
                        </span>
                      </div>
                    ))
                  )
                ) : null}
              </div>

              {data?.status === 0 ? (
                <div className="bidding-orange-text cursor-pointer">
                  Click on bid to accept
                </div>
              ) : (
                <div className="bidding-orange-text">
                  You already accepted bid
                </div>
              )}
            </div>
          )
        ) : null}
        {data?.status === 0 ? (
          <div className="sticky-white-card">
            <button
              onClick={() => {
                setShowSellModal(true);
              }}
              className="bid-button"
            >
              Edit listing
            </button>
            <button
              onClick={() => {
                setshow(true);
              }}
              className="buy-button"
            >
              Remove listing
            </button>
          </div>
        ) : null}
      </div>

      <SellModal
        setShowModal={setShowSellModal}
        showModal={showSellModal}
        setAskingPrice={setAskingPrice}
        AskingPrice={askingPrice}
        date={date}
        type={type}
        bind={bind}
        makingCharges={makingCharges}
        stoneCharges={stoneCharges}
        setMakingCharges={setMakingCharges}
        setStoneCharges={setStoneCharges}
        setBind={setBind}
        setType={setType}
        setDate={setDate}
        onClick={_onPress}
        data={data}
      />
      <AcceptBidModal
        onClick={() => _onAccpeted()}
        item={selectedBid}
        setShowModal={setAcceptModal}
        showModal={acceptModal}
      />
    </div>
  );
};

export default ListingDetail;
