import React, { useState } from "react";
import Lottie from "react-lottie";

const Confirm = ({ size }) => {
  const options = {
    animationData: require("./confirm.json"),
  };
  const [stop, setstop] = useState(false);
  setTimeout(() => {
    setstop(true);
  }, 4000);

  return (
    <Lottie
      options={options}
      width={size}
      height={size}
      isPaused={stop}
      speed={1}
    />
  );
};

export default Confirm;
