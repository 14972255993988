// <----------PROD API'S-------->>>>>>>>>>

export const APP_URL = "https://s3.ruptokcap.in/";
export const GOLD_UNO_URL = "https://goldapi.golduno.com/";
export const LOAN_SCHEMES = "https://caapi.ruptok.com/api/";
export const Loan_API_URL = "https://s2.ruptokcap.in/";

// <----------UAT API'S-------->>>>>>>>>>

// export const APP_URL = "https://s3uat.ruptokcap.in/";
//export const APP_URL = "https://s2dev.ruptokcap.in/";
// export const GOLD_UNO_URL = "https://uatgoldapi.golduno.com/";
// export const LOAN_SCHEMES = "https://uatcaapi.ruptok.com/api/";
