import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCities,
  LOGIN_USER,
  SignUpUserAPI,
} from "../../redux/actions/AuthAction";
import { TextField, Select, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../assets/lottie/Loader.jsx";

const Signup = ({ phone, loading, handleModal }) => {
  const dispatch = useDispatch();

  const { cities } = useSelector((state) => state?.authReducer);
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    city: 1,
    referal_code: "",
  });

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const handleSignUp = (e) => {
    e.preventDefault();
    if (state.firstname === "" || state.lastname === "" || state.email === "") {
      toast("Complete Your Details", {
        progressStyle: { background: "#eba300" },
      });
    } else {
      SignUpUserAPI({
        first_name: state.firstname,
        last_name: state.lastname,
        email: state.email,
        city: state.city,
        referral_code: state.referal_code,
      }).then((res) => {
        if (res.message === "User updated successfully.") {
          dispatch({
            type: LOGIN_USER,
            payload: true,
          });
          handleModal();
        } else {
          toast(res.message, {
            progressStyle: { background: "#eba300" },
          });
          setState({
            phone: state.phone,
            email: state.email,
            city: state.city,
            firstname: state.firstname,
            lastname: state.lastname,
            referal_code: state.referal_code,
          });
        }
      });
    }
  };

  return (
    <>
      <div className="px-6 pb-4 text-center mb-2">
        <div className="font-bold text-xl mb-2">
          Complete your details to continue
        </div>
        <p className="text-gray-700 text-base">+91 {phone}</p>
      </div>
      <TextField
        id="outlined-basic"
        label="First Name"
        variant="outlined"
        type="text"
        value={state.firstname}
        onChange={(e) =>
          setState({
            ...state,
            firstname: e.target.value.replace(/[^a-zA-Z]*$/, ""),
          })
        }
        className="flex-1 w-full"
        style={{ marginBottom: "20px" }}
      />

      <TextField
        id="outlined-basic"
        label="Last Name"
        variant="outlined"
        type="text"
        value={state.lastname}
        onChange={(e) =>
          setState({
            ...state,
            lastname: e.target.value.replace(/[^a-zA-Z]*$/, ""),
          })
        }
        className="flex-1 w-full"
        style={{ marginBottom: "20px" }}
      />

      <TextField
        id="outlined-basic"
        label="Email"
        variant="outlined"
        type="text"
        value={state.email}
        onChange={(e) => setState({ ...state, email: e.target.value })}
        className="flex-1 w-full"
        style={{ marginBottom: "20px" }}
      />
      <TextField
        id="outlined-basic"
        label="Referal Code"
        variant="outlined"
        type="text"
        value={state.referal_code}
        onChange={(e) =>
          setState({
            ...state,
            referal_code: e.target.value,
          })
        }
        className="flex-1 w-full"
        style={{ marginBottom: "20px" }}
      />
      <Select
        className="flex-1 w-full"
        style={{ marginBottom: "20px" }}
        value={state.city}
        onChange={(e) => {
          setState({ ...state, city: e.target.value });
        }}
      >
        {cities?.map((i) => (
          <MenuItem value={i.id}>{i.name}</MenuItem>
        ))}
      </Select>
      {loading ? (
        <Loader size={20} />
      ) : (
        <button
          onClick={handleSignUp}
          disabled={
            state.firstname === "" ||
            state.lastname === "" ||
            state.email === "" ||
            state.phone === ""
              ? true
              : false
          }
          className={
            state.firstname === "" ||
            state.lastname === "" ||
            state.email === "" ||
            state.phone === ""
              ? "next-auth-button-disabled"
              : "next-auth-button"
          }
        >
          Next
        </button>
      )}
    </>
  );
};

export default Signup;
