import React, { useState } from "react";
import Lottie from "react-lottie";

const Empty = () => {
  const options = {
    animationData: require("./Emptydata.json"),
  };
  const [stop, setstop] = useState(false);
  setTimeout(() => {
    setstop(true);
  }, 4000);
  return (
    <Lottie
      options={options}
      width={300}
      height={300}
      isPaused={stop}
      speed={1}
    />
  );
};

export default Empty;
