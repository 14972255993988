import React, { useEffect, useState } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { BsCheck2 } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { BidingSchemes } from "../../redux/actions/AssetAction";

const BidSchemeOptions = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [selectedSchemeId, setselectedSchemeId] = useState(1);
  const [data, setData] = useState();
  useEffect(() => {
    BidingSchemes(id).then((res) => {
      setData(res.data.data);
    });
  }, []);
  return (
    <div className="bg-white mrlb-5per mt-6 pb-5">
      <TitleWithSeemore
        title="Gold saver scheme"
        viewall={false}
        onBack={true}
      />
      <div className="flex flex-wrap flex-col items-center justify-center scheme-container">
        <div className="flex items-center jusitfy-center flex-col">
          <div className="text-gray-500 font-medium">Advance Amount</div>
          <div className="text-black text-4xl mt-2 font-extrabold">
            {"₹ " +
              parseFloat(
                data?.scheme_option[selectedSchemeId]?.advance_amount
              ).toLocaleString("en-IN", {
                maximumFractionDigits: 2,
              })}
          </div>
        </div>
        <div className="mt-10 pb-20 md:pb-2 w-full">
          <div
            onClick={() => {
              setselectedSchemeId(0);
            }}
            className={
              selectedSchemeId === data?.scheme_option[0]?.scheme_id
                ? "selected-scheme-full-card flex justify-between p-6"
                : "scheme-full-card flex justify-between p-6"
            }
          >
            <div
              className={
                selectedSchemeId === 0
                  ? "selected-radio-icon mr-6 mt-3"
                  : "radio-icon mr-6 mt-3"
              }
            ></div>
            <div className="w-full h-full pt-2">
              <div className="border-bottom pb-4 mb-4 flex items-center justify-between">
                <div className="text-black font-bold">
                  {data?.scheme_option[0]?.scheme_name}
                </div>
                <div className="text-black font-bold">
                  {"₹ " +
                    parseFloat(data?.scheme_option[0]?.amount).toLocaleString(
                      "en-IN",
                      {
                        maximumFractionDigits: 2,
                      }
                    )}
                </div>
              </div>
              <div>
                <div className="text-gray-400 text-sm font-medium mt-2 flex items-center">
                  <BsCheck2
                    size={20}
                    className="mr-2"
                    color={selectedSchemeId === 0 ? "#eba300" : null}
                  />
                  Enjoy reward benefits
                </div>
                <div className="text-gray-400 text-sm font-medium mt-2 flex items-center">
                  <BsCheck2
                    size={20}
                    className="mr-2"
                    color={selectedSchemeId === 0 ? "#eba300" : null}
                  />
                  Zero charges
                </div>
              </div>
            </div>
          </div>
          {data?.scheme_option?.slice(1).map((res) => (
            <div
              onClick={() => {
                setselectedSchemeId(res?.scheme_id);
              }}
              className={
                res?.scheme_id === selectedSchemeId
                  ? "selected-scheme-sub-card flex items-center justify-between p-6"
                  : "scheme-sub-card flex items-center justify-between p-6"
              }
            >
              {selectedSchemeId === 1 ? (
                res?.scheme_id === selectedSchemeId ? (
                  <div className="recommended-tag">RECOMMMENDED</div>
                ) : null
              ) : null}
              <div className="flex items-center">
                <div
                  className={
                    res?.scheme_id === selectedSchemeId
                      ? "selected-radio-icon mr-6"
                      : "radio-icon mr-6"
                  }
                ></div>
                <div>
                  <div className="text-black font-bold">
                    {res?.tenure} Months
                  </div>
                  <div className="text-gray-400 text-sm font-medium mt-1">
                    {res?.scheme_name}
                  </div>
                </div>
              </div>

              <div className="text-black font-bold">
                {"₹ " +
                  parseFloat(res?.amount).toLocaleString("en-IN", {
                    maximumFractionDigits: 2,
                  })}
                <span className="text-gray-500 font-medium">
                  <span className="mx-1">/</span>
                  month
                </span>
              </div>
            </div>
          ))}
        </div>
        <div
          className="sticky-white-card px-4 md:px-0"
          onClick={() => {
            navigate(`/bidaddress/${id}/${selectedSchemeId}`);
          }}
        >
          <button className="buy-button w-full">Save and continue</button>
        </div>
      </div>
    </div>
  );
};

export default BidSchemeOptions;
