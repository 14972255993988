import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Empty from "../../assets/lottie/Empty";
import BiddingModal from "../../components/market/BiddingModal";
import {
  getItemBids,
  placeBid,
  getMakretProduct,
} from "../../redux/actions/MarketAction";
import { useNavigate, useParams } from "react-router-dom";
import { FiArrowLeft, FiShare } from "react-icons/fi";
import { getCookie } from "../../utils/CookieHandler";
import ShareModal from "../../components/ShareModal";
import MultiImageSlider from "../../components/MultiImageSlider";
import ProductPageSkelton from "../../components/ProductPageSkelton";
import ModalLogin from "../auth/ModalLogin";
import newTaG from "../../assets/icons/newtag.png";

const MarketProduct = () => {
  const navigate = useNavigate();
  const [showBidModal, setShowBidModal] = useState(false);
  const [clicked, setclicked] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [biddingAmount, setBiddingAmount] = useState();
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  const { id } = useParams();

  const { bidList } = useSelector((state) => state?.marketReducer);
  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  const goldPrice = localStorage.getItem("goldPrice");

  const silverPrice = localStorage.getItem("silverPrice");

  useEffect(() => {
    window.scroll(0, 0);
    if (isLoggedIn) dispatch(getItemBids({ listing_id: id }));
    getMakretProduct(id).then((res) => {
      setData(res.data);
    });
  }, [isLoggedIn, id, dispatch]);

  const _onPress = async () => {
    const formdata = {
      listing_id: data?.listing_id,
      quoted_amount: biddingAmount,
    };
    console.log("listing_id", formdata);
    const res = await placeBid(formdata);
    if (res) {
      setShowBidModal(false);
      setBiddingAmount("");
      dispatch(getItemBids({ listing_id: data?.listing_id }));
    }
  };
  if (data === null) {
    return <ProductPageSkelton />;
  } else
    return (
      <div className="product-page flex-1 flex flex-wrap">
        <ShareModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
        />
        <ModalLogin
          showModal={showLoginModal}
          setShowModal={setShowLoginModal}
          handleModal={() => {
            setShowLoginModal(false);
            if (clicked === "buy") {
              const is_buy = JSON.parse(localStorage.getItem("is_buy"));
              const { is_kyc } = JSON.parse(getCookie("user"));
              is_buy === 1
                ? is_kyc === 0
                  ? navigate("/startkyc")
                  : navigate(`/buyschemes/${data?.listing_id}`)
                : navigate(`/buyschemes/${data?.listing_id}`);
              setclicked(null);
            } else if (clicked === "bid") {
              const { is_kyc } = JSON.parse(getCookie("user"));
              const is_bid = JSON.parse(localStorage.getItem("is_bid"));
              is_bid === 1
                ? is_kyc === 0
                  ? navigate("/startkyc")
                  : setShowBidModal(true)
                : setShowBidModal(true);
              setclicked(null);
            }
          }}
        />
        <button
          onClick={() => {
            window.history.back();
          }}
          className="back-button"
        >
          <FiArrowLeft className="profile-icon" />
        </button>
        <div className="product-container">
          <MultiImageSlider
            data={
              data?.jewellery_images
                ? [data?.image_url, ...data?.jewellery_images]
                : [data?.image_url]
            }
          />
          <div className="white-card-2 mt-4">
            <div className="product-title mb-2">Product Details</div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Jewellery Category</span>
              <span className="detail-value">: {data?.jewellery_category}</span>
            </div>
            <div className="flex justify-between mt-1">
              <span className="detail-name">Purity </span>
              <span className="detail-value">: {data?.karat} karat</span>
            </div>
            {data?.jewellery_description ? (
              <div className="flex justify-between mt-1">
                <span className="detail-name">Description </span>
                <span className="detail-value">
                  : {data?.jewellery_description}
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex-1">
          {data?.listing_type === 1 ? (
            <img
              src={newTaG}
              alt="new"
              style={{
                height: 80,
                width: 80,
                position: "absolute",
                transform: "rotate(270deg)",
                marginTop: -10,
                marginLeft: 0,
                zIndex: 900,
              }}
            />
          ) : null}
          <div className="white-card flex justify-between mt-4 md:mt-0">
            <div>
              <div className="flex items-center">
                <div className="product-title mr-4">{data?.name} </div>
                {/* {data?.listing_type === 1 ? (
                <New size={window.innerWidth > 700 ? 50 : 35} />
              ) : null} */}
              </div>
              <div className="product-karat mt-1">{data?.karat} karat</div>
              <div className="product-price mt-4">
                {"₹ " +
                  parseFloat(data?.asking_price).toLocaleString("en-IN", {
                    maximumFractionDigits: 0,
                  })}
              </div>
              <div className="text-slate-300 text-sm mt-1">
                Inclusive all taxes
              </div>
            </div>
            <FiShare
              size={window.innerWidth > 700 ? 30 : 20}
              color="#eba300"
              onClick={() => {
                setShowModal(!showModal);
              }}
            />
          </div>
          <div className="sticky-white-card ml-0 md:ml-3">
            {data?.listing_type === 1 ? null : (
              <button
                onClick={() => {
                  setclicked("bid");
                  if (isLoggedIn) {
                    const { is_kyc } = JSON.parse(getCookie("user"));
                    const is_bid = JSON.parse(localStorage.getItem("is_bid"));
                    is_bid === 1
                      ? is_kyc === 0
                        ? navigate("/startkyc")
                        : setShowBidModal(true)
                      : setShowBidModal(true);
                  } else {
                    setShowLoginModal(true);
                  }
                }}
                className="bid-button"
              >
                Make a bid
              </button>
            )}
            <button
              onClick={() => {
                setclicked("buy");
                if (isLoggedIn) {
                  const is_buy = JSON.parse(localStorage.getItem("is_buy"));
                  const { is_kyc } = JSON.parse(getCookie("user"));
                  is_buy === 1
                    ? is_kyc === 0
                      ? navigate("/startkyc")
                      : navigate(`/buyschemes/${data?.listing_id}`)
                    : navigate(`/buyschemes/${data?.listing_id}`);
                } else {
                  // navigate("/");
                  setShowLoginModal(true);
                }
              }}
              className="buy-button"
            >
              Buy Now
            </button>
          </div>
          <div className="white-card mt-4">
            <div className="product-title">Weight</div>
            <div className="flex">
              <div className="mr-4">
                <div className="product-weight">{data?.net_weight} GM</div>
                <div className="text-slate-300 text-xs mt-1 text-center">
                  Net Weight*
                </div>
              </div>
              <div>
                <div className="product-weight">{data?.gross_weight} GM</div>
                <div className="text-slate-300 text-xs mt-1 text-center">
                  Gross Weight*
                </div>
              </div>
            </div>
          </div>
          <div className="white-card mt-4">
            <div className="product-title">Price Breakdown</div>
            <div className="text-slate-300 text-xs md:text-sm">
              Price may vary subject to the market value of item*
            </div>
            <div>
              <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                <span className="breakdown-component flex-2">
                  {data?.type === 1 ? `Gold (${data?.karat}Kt)` : `Silver`}
                </span>
                {isLoggedIn ? (
                  <span className="flex-1 breakdown-heading">
                    {data?.type === 1
                      ? "₹ " +
                        parseFloat(goldPrice).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })
                      : "₹ " +
                        parseFloat(silverPrice).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })}
                  </span>
                ) : null}
                <span className="flex-1 breakdown-heading">
                  {data?.net_weight} GM
                </span>
                <span className="flex-1 breakdown-heading">
                  {"₹ " +
                    parseFloat(data?.market_worth).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              </div>
              {data?.making_charges ? (
                <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                  <span className="breakdown-component flex-2">
                    Making Charges
                  </span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading">
                    {"₹ " +
                      parseFloat(data?.making_charges).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                  </span>
                </div>
              ) : null}
              {data?.stone_charges ? (
                <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                  <span className="breakdown-component flex-2">
                    Stone Charges
                  </span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading">
                    {"₹ " +
                      parseFloat(data?.stone_charges).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                  </span>
                </div>
              ) : null}
              {data?.gst_amount ? (
                <div className="flex items-center justify-around breakdown-border mt-2 p-4">
                  <span className="breakdown-component flex-2">GST </span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading"></span>
                  <span className="flex-1 breakdown-heading">
                    {"₹ " +
                      parseFloat(data?.gst_amount).toLocaleString("en-IN", {
                        maximumFractionDigits: 0,
                      })}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="white-card mt-4">
            <div className="product-title">
              {" "}
              {data?.type === 1 ? `Gold ` : `Silver `}
              value (Market worth)
            </div>
            <div className="text-slate-300 text-xs md:text-sm">
              Sale Value may vary subject to the market value of article*
            </div>
            <div className="product-sale-value">
              {"₹ " +
                parseFloat(data?.market_worth).toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                })}
            </div>
          </div>
          {isLoggedIn ? (
            data?.listing_type === 1 ? null : (
              <div className="white-card mt-4 mb-10 ">
                <div className="product-title">Current Biddings</div>
                <div className="text-slate-300 text-xs md:text-sm">
                  Bidding amount is according to the Asking Price of item*
                </div>
                {bidList ? (
                  bidList?.length === 0 ? (
                    <div className="container no-biddings">
                      <Empty />
                      <span className="text-slate-300 text-xs md:text-sm mt-4">
                        Currently there are no biddings
                      </span>
                    </div>
                  ) : (
                    bidList?.map((i) => (
                      <div className="flex justify-between mt-4">
                        <span className="bid-name">
                          {i.customer_name === null
                            ? "Unknown"
                            : i.customer_name}
                        </span>
                        <span className="bid-amount">
                          {"₹ " +
                            parseFloat(i?.quoted_amount).toLocaleString(
                              "en-IN",
                              {
                                maximumFractionDigits: 0,
                              }
                            )}
                        </span>
                      </div>
                    ))
                  )
                ) : null}
              </div>
            )
          ) : null}
        </div>
        <BiddingModal
          setShowModal={setShowBidModal}
          showModal={showBidModal}
          setValue={setBiddingAmount}
          value={biddingAmount}
          onClick={_onPress}
          data={data}
        />
      </div>
    );
};

export default MarketProduct;
