import React, { useEffect } from "react";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import Empty from "../../assets/lottie/Empty";
import { useDispatch, useSelector } from "react-redux";
import { getMyBids } from "../../redux/actions/AssetAction";
import BidsCard from "../../components/profile/BidsCard";
import SkeletonLoadingCards from "../../components/SkeletonLoadingCards";

const MyBids = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyBids());
  }, [dispatch]);
  const { myBids } = useSelector((state) => state?.assetsReducer);
  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title="My Bids" viewall={false} onBack={true} />

      {myBids === null ? (
        <SkeletonLoadingCards />
      ) : (
        <div className="flex flex-wrap p-2 md:p-5 justify-between">
          {myBids?.length === 0 ? (
            <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
              <Empty />
              <div className="flex flex-col">
                <span className="no-asset">You have no Bids</span>
              </div>
            </div>
          ) : (
            myBids?.map((asset) => {
              return <BidsCard data={asset} key={asset.id} />;
            })
          )}
        </div>
      )}
    </div>
  );
};

export default MyBids;
