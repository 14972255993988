import { GET_ACTIVE_LOANS, GET_DAYS } from "../actions/LoanAction";

const initialState = {
  activeLoans: [],
  loanData: null,
  days: [],
};

function loanReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_LOANS:
      return {
        ...state,
        activeLoans: action.payload.active_loan,
        loanData: action.payload,
      };
    case GET_DAYS:
      return { ...state, days: action.payload };
    default:
      return state;
  }
}

export default loanReducer;
