import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCities } from "../../redux/actions/AuthAction";
import { BsCheck2 } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { getJewelsCategory } from "../../redux/actions/MarketAction";
import { createSearchParams, useNavigate } from "react-router-dom";
import MultiRangeSlider from "multi-range-slider-react";

const MobileFilter = ({ setFilterObj, filterObj, show, setShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCities());
    dispatch(getJewelsCategory());
  }, []);

  const [itemCategory, setItemCategory] = useState(filterObj.jewel_cat);
  const [choosedCity, setChoosedCity] = useState(filterObj.city);
  const [jewelType, setJewelType] = useState(filterObj.type);

  const jewelTypes = [
    { id: "all", name: "all" },
    { id: "old", name: "Pre-Owned" },
    { id: "new", name: "New" },
  ];

  const [minValue, setMinValue] = useState(filterObj.min_price);
  const [maxValue, setMaxValue] = useState(filterObj.max_price);

  const { cities } = useSelector((state) => state.authReducer);
  const { jewelsCategory } = useSelector((state) => state.marketReducer);

  useEffect(() => {
    setFilterObj({
      jewel_cat: itemCategory,
      type: jewelType,
      city: choosedCity,
      page_no: 0,
      sort_by: filterObj.sort_by,
      min_price: minValue,
      max_price: maxValue,
    });
    navigate({
      search: encodeURIComponent(
        `${createSearchParams({
          jewel_cat: itemCategory,
          type: jewelType,
          city: choosedCity,
          page_no: 0,
          sort_by: filterObj.sort_by,
          min_price: minValue,
          max_price: maxValue,
        })}`
      ),
    });
  }, [minValue, maxValue]);

  return (
    <div
      onClick={() => {
        setShow(false);
      }}
      className={show ? "backdrop" : null}
    >
      <div className={show ? "mobile-show-filter" : "mobile-hide-filter"}>
        <div className="flex items-center justify-between">
          <div className="title">Filters</div>
          <button
            onClick={() => {
              setShow(false);
            }}
          >
            <GrClose />
          </button>
        </div>
        <div className="full-grey-line mt-2"></div>
        <div className="px-5">
          <div className="loan-main-text mt-4 mb-2">Price</div>
          <MultiRangeSlider
            min={0}
            max={1000000}
            onChange={(e) => {
              console.log(
                "----------------------------",
                e.minValue,
                e.maxValue
              );
              setMinValue(e.minValue);
              setMaxValue(e.maxValue);
            }}
            minValue={minValue}
            maxValue={maxValue}
            label={false}
            ruler={false}
            style={{ border: "none", boxShadow: "none", padding: "20px 0px" }}
            barInnerColor="#eba300"
            thumbLeftColor="#eba300"
            thumbRightColor="#eba300"
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
              {"₹ " +
                parseFloat(minValue).toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                })}
            </div>
            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
              {"₹ " +
                parseFloat(maxValue).toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                })}
            </div>
          </div>
          <div className="loan-main-text mt-4 mb-2">Category</div>
          <div className="px-2 grid gap-2">
            <div
              onClick={() => {
                setItemCategory(null);
                setFilterObj({
                  jewel_cat: null,
                  type: jewelType,
                  city: choosedCity,
                  page_no: 0,
                  sort_by: filterObj.sort_by,
                  min_price: minValue,
                  max_price: maxValue,
                });
                navigate({
                  search: encodeURIComponent(
                    `${createSearchParams({
                      jewel_cat: null,
                      type: jewelType,
                      city: choosedCity,
                      page_no: 0,
                      sort_by: filterObj.sort_by,
                      min_price: minValue,
                      max_price: maxValue,
                    })}`
                  ),
                });
                setShow(false);
              }}
              className="loan-sub-text flex items-center"
            >
              {itemCategory === null || filterObj.jewel_cat === null ? (
                <div className="selected-checkbox">
                  <BsCheck2 color="white" />
                </div>
              ) : (
                <div className="checkbox"></div>
              )}
              All
            </div>
            {jewelsCategory?.map((i) => (
              <div
                key={i.id}
                onClick={() => {
                  setItemCategory(i.id);
                  setFilterObj({
                    jewel_cat: i.id,
                    type: jewelType,
                    city: choosedCity,
                    page_no: 0,
                    sort_by: filterObj.sort_by,
                    min_price: minValue,
                    max_price: maxValue,
                  });
                  navigate({
                    search: encodeURIComponent(
                      `${createSearchParams({
                        jewel_cat: i.id,
                        type: jewelType,
                        city: choosedCity,
                        page_no: 0,
                        sort_by: filterObj.sort_by,
                        min_price: minValue,
                        max_price: maxValue,
                      })}`
                    ),
                  });
                  setShow(false);
                }}
                className="loan-sub-text flex items-center"
              >
                {itemCategory === i.id ? (
                  <div className="selected-checkbox">
                    <BsCheck2 color="white" />
                  </div>
                ) : (
                  <div className="checkbox"></div>
                )}
                {i.name}
              </div>
            ))}
          </div>
          <div className="loan-main-text mt-4 mb-2">City</div>
          <div className="px-2 grid gap-2">
            {cities?.map((i) => (
              <div
                key={i.id}
                onClick={() => {
                  setChoosedCity(i.id);
                  setFilterObj({
                    jewel_cat: itemCategory,
                    type: jewelType,
                    city: i.id,
                    page_no: 0,
                    sort_by: filterObj.sort_by,
                    min_price: minValue,
                    max_price: maxValue,
                  });
                  navigate({
                    search: encodeURIComponent(
                      `${createSearchParams({
                        jewel_cat: itemCategory,
                        type: jewelType,
                        city: i.id,
                        page_no: 0,
                        sort_by: filterObj.sort_by,
                        min_price: minValue,
                        max_price: maxValue,
                      })}`
                    ),
                  });
                  setShow(false);
                }}
                className="loan-sub-text flex items-center"
              >
                {choosedCity === i.id ? (
                  <div className="selected-checkbox">
                    <BsCheck2 color="white" />
                  </div>
                ) : (
                  <div className="checkbox"></div>
                )}
                {i.name}
              </div>
            ))}
          </div>
          <div className="loan-main-text mt-4 mb-2">Type</div>
          <div className="px-2 grid gap-2">
            {jewelTypes.map((i) => (
              <div
                key={i.id}
                onClick={() => {
                  setJewelType(i.id);
                  setFilterObj({
                    jewel_cat: itemCategory,
                    type: i.id,
                    city: choosedCity,
                    page_no: 0,
                    sort_by: filterObj.sort_by,
                    min_price: minValue,
                    max_price: maxValue,
                  });
                  navigate({
                    search: encodeURIComponent(
                      `${createSearchParams({
                        jewel_cat: itemCategory,
                        type: i.id,
                        city: choosedCity,
                        page_no: 0,
                        sort_by: filterObj.sort_by,
                        min_price: minValue,
                        max_price: maxValue,
                      })}`
                    ),
                  });
                  setShow(false);
                }}
                className="loan-sub-text flex items-center"
              >
                {jewelType === i.id ? (
                  <div className="selected-checkbox">
                    <BsCheck2 color="white" />
                  </div>
                ) : (
                  <div className="checkbox"></div>
                )}
                {i.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilter;
