import React, { useState } from "react";
import phoneIcon from "../../assets/icons/phone.png";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../redux/actions/AuthAction";
import Carousel from "react-multi-carousel";
import Carousel1 from "../../assets/images/carousel1.png";
import Carousel2 from "../../assets/images/carousel2.png";
import Carousel3 from "../../assets/images/carousel3.png";
import Loader from "../../assets/lottie/Loader.jsx";
import Signup from "./Signup";
import OTP from "./OTP";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const Signin = () => {
  const [phone, setPhone] = useState("");
  const [OtpPage, setOtpPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const signUpPage = useSelector((state) => state?.authReducer?.signUpPage);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await loginUser({ phone: phone });
    if (res) {
      setOtpPage(true);
      setLoading(false);
    }
  };

  const responsive = {
    tablet: {
      breakpoint: { max: 4000, min: 800 },
      items: 1,
    },
  };

  return (
    <div className="flex p-1 md:p-20" style={{ height: "80vh" }}>
      <ToastContainer />
      {window.innerWidth > 1100 ? (
        <div className="flex-1 flex items-center justify-center mr-10">
          <div style={{ width: 500, backgroundColor: "transparent" }}>
            <Carousel
              responsive={responsive}
              slidesToSlide={1}
              arrows={true}
              infinite={true}
              showDots={true}
              autoPlay={true}
              swipeable={true}
            >
              <div className="h-96">
                <img src={Carousel1} alt="" className="m-auto" />
                <div>
                  <div className="carousel-main-text mt-2">
                    Buy Gold Jewellery at the Best Price
                  </div>
                  <div className="carousel-secondary-text my-5">
                    Place your bid buy the Jewellery at the Best Price
                  </div>
                </div>
              </div>
              <div className="h-96">
                <img src={Carousel2} alt="" className="m-auto" />
                <div>
                  <div className="carousel-main-text mt-2">
                    Sell Your Gold Jewellery at the Best Market Price
                  </div>
                  <div className="carousel-secondary-text my-5">
                    Encash Your old Gold Jewellery with Ruptok free Valuation
                    Available at Home
                  </div>
                </div>
              </div>
              <div className="h-96">
                <img src={Carousel3} alt="" className="m-auto" />
                <div>
                  <div className="carousel-main-text mt-2">
                    Gold Loan at Your Doorstep
                  </div>
                  <div className="carousel-secondary-text my-5">
                    Get the Gold Loan on Lowest Interest rate and Highest Value
                    of your Gold
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      ) : null}
      <div className="flex-1 flex justify-center items-center">
        <div
          className="rounded overflow-hidden m-auto"
          style={{
            width: window.innerWidth > 600 ? 450 : "90%",
            backgroundColor: "white",
            padding: window.innerWidth > 600 ? 50 : 20,
            height: signUpPage ? 700 : OtpPage ? 500 : 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            border: "1px solid rgba(170, 170, 170, 0.3)",
            transition: "0.2s ease-out",
          }}
        >
          {loading ? (
            <Loader size={200} />
          ) : OtpPage ? (
            <OTP phone={phone} setOtpPage={setOtpPage} />
          ) : signUpPage ? (
            <Signup
              setOtp={setOtpPage}
              phone={phone}
              setPhone={setPhone}
              loading={loading}
            />
          ) : (
            <>
              <div className="px-6 pb-12 text-center">
                <div className="font-bold text-xl mb-2">Sign in</div>
                <p className="text-gray-700 text-base">
                  To Access your Account
                </p>
              </div>
              <div style={{ marginBottom: "20px" }} className="auth-input">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                  maxLength={10}
                  placeholder="Enter your Phone"
                  style={{ outline: "none", fontSize: 15, width: "100%" }}
                />
                <img src={phoneIcon} alt="phone" />
              </div>
              <button
                onClick={handleSignIn}
                className={
                  phone?.length === 10
                    ? "next-auth-button"
                    : "next-auth-button-disabled"
                }
                disabled={phone?.length === 10 ? false : true}
              >
                Next
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signin;
