import React, { useEffect } from "react";
import Category from "../components/home/Category";
import NetWorthCard from "../components/home/NetWorthCard";
import CustomCarousel from "../components/Carousel";
import Footer from "../components/Footer";
import { getConfig } from "../redux/actions/AssetAction";
import { useDispatch } from "react-redux";
import { getProfile } from "../redux/actions/AuthAction";
import { setCookie } from "../utils/CookieHandler";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConfig());
    window.scrollTo(0, 0);
    getProfile().then((res) => {
      setCookie("user", JSON.stringify(res));
    });
  }, [dispatch]);

  return (
    <>
      <div className="mx-0 md:mx-18 lg:mx-20">
        <CustomCarousel />
        <Category />
        <NetWorthCard MyJewellery={true} shopJewels={true} />
      </div>
      <Footer />
    </>
  );
};

export default Home;
