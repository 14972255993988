import React, { useEffect } from "react";
import Add from "../../assets/icons/add.png";
import TitleWithSeemore from "../TitleWithSeemore";
import JewelleryCardNew from "./JewelleryCardNew";
import noJewellery from "../../assets/images/no-jewellery.png";
import {
  getAllAssets,
  getUserPortfolio,
} from "../../redux/actions/AssetAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
const { innerWidth: width } = window;

const NetWorthCard = ({ MyJewellery, shopJewels }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAssets());
    dispatch(getUserPortfolio());
  }, [dispatch]);
  const { allAssets, totalPortfolio } = useSelector(
    (state) => state.assetsReducer
  );

  const netWorth = totalPortfolio?.toString().split(".");
  const data =
    width > 1400
      ? allAssets?.slice(0, 4)
      : width > 1100
      ? allAssets?.slice(0, 3)
      : width > 400
      ? allAssets?.slice(0, 2)
      : allAssets?.slice(0, 1);
  return (
    <div className="bg-white mrl-5per box-shadow">
      <div className="flex items-center justify-between net-worth mt-2">
        <div className="flex justify-center items-center md:mr-24">
          <div className="ml-2">
            <span className="net-worth-text">Total Jewellery Value</span>
            <div className="flex">
              {totalPortfolio === null ? (
                <Skeleton variant="rectangular" height="40px" width="100%" />
              ) : (
                <span className="price">
                  {"₹ " +
                    parseFloat(netWorth).toLocaleString("en-IN", {
                      maximumFractionDigits: 0,
                    })}
                </span>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/jewellerytype");
          }}
          className="add-jewellery-button"
        >
          <img src={Add} alt="Add-icon" height="14" width="14" />
          <span className="add-jewellery-text">Add Jewellery</span>
        </button>
      </div>

      <div className="full-grey-line" />
      {shopJewels ? (
        <div className="flex justify-between net-worth mt-2 items-center">
          <span className="net-worth-text">
            Search for jewellery in marketplace to add them to your list
          </span>
          <button
            className="shop-button"
            onClick={() => {
              navigate("/market");
            }}
          >
            Shop Jewels
          </button>
        </div>
      ) : null}
      {MyJewellery ? (
        <div className="px-2">
          <TitleWithSeemore
            title="My Jewellery"
            onClick={() => {
              navigate("/portfolio");
            }}
          />
          {allAssets === null ? (
            <div className="flex overflow-hidden items-center justify-around mt-2">
              <div className="grid gap-4 mb-10 jewellery-card p-4">
                <Skeleton variant="rectangular" width={230} height={200} />
                <Skeleton variant="rectangular" width={130} height={20} />
                <Skeleton variant="rectangular" width={200} height={20} />
              </div>
              <div className="grid gap-4 mb-10 jewellery-card p-4">
                <Skeleton variant="rectangular" width={230} height={200} />
                <Skeleton variant="rectangular" width={130} height={20} />
                <Skeleton variant="rectangular" width={200} height={20} />
              </div>
              <div className="grid gap-4 mb-10 jewellery-card p-4">
                <Skeleton variant="rectangular" width={230} height={200} />
                <Skeleton variant="rectangular" width={130} height={20} />
                <Skeleton variant="rectangular" width={200} height={20} />
              </div>
              <div className="grid gap-4 mb-10 jewellery-card p-4">
                <Skeleton variant="rectangular" width={230} height={200} />
                <Skeleton variant="rectangular" width={130} height={20} />
                <Skeleton variant="rectangular" width={200} height={20} />
              </div>
              <div className="grid gap-4 mb-10 jewellery-card p-4">
                <Skeleton variant="rectangular" width={230} height={200} />
                <Skeleton variant="rectangular" width={130} height={20} />
                <Skeleton variant="rectangular" width={200} height={20} />
              </div>
            </div>
          ) : (
            <div className="px-2 md:px-4">
              {allAssets.length === 0 ? (
                <div className="flex flex-wrap p-3per items-center justify-evenly mt-10">
                  <div className="flex flex-col">
                    <span className="no-asset">
                      You have no Jewellery in your list
                    </span>
                    <span className="no-asset-content mt-6 mb-10">
                      Looks for jewellery in market place to add them to your
                      List.{" "}
                    </span>
                  </div>
                  <img src={noJewellery} alt="no asset" />
                </div>
              ) : (
                <div className="flex gap-4">
                  {data.map((i) => (
                    <JewelleryCardNew data={i} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default NetWorthCard;
