import React, { useState } from "react";
import back from "../../assets/icons/back_asset.png";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addAssets,
  UploadImages,
  addAssetData,
} from "../../redux/actions/AssetAction";
import { TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../assets/lottie/Loader.jsx";
import Add from "../../assets/icons/add.png";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { GrClose } from "react-icons/gr";

const JewelleryInfo = () => {
  const { AddAssetData } = useSelector((state) => state?.assetsReducer);
  const [Loading, setLoading] = useState(false);
  const [file, setfile] = useState(null);
  const [images, setImages] = useState([]);
  const [netWeight, setNetWeight] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [name, setname] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ------handle press-----
  const _onPress = async () => {
    if (netWeight > grossWeight) {
      toast("Net weight can't be greater then gross weight", {
        progressStyle: { background: "#eba300" },
      });
    } else if (parseFloat(netWeight) === 0) {
      toast("Net weight can't be Zero", {
        progressStyle: { background: "#eba300" },
      });
    } else if (netWeight > 10000 || grossWeight > 10000) {
      toast("Weight can't be greater than 10Kg", {
        progressStyle: { background: "#eba300" },
      });
    } else {
      setLoading(true);
      var data = new FormData();
      data.append("category", 1);
      data.append("stones", JSON.stringify(AddAssetData.stones));
      data.append("type", AddAssetData.Category);
      data.append("is_stone", AddAssetData.isDiamond);
      data.append("karat", AddAssetData.karat);
      data.append("jewel_category", AddAssetData.JewelsCategory);
      data.append("name", name);
      data.append("gross_weight", grossWeight);
      data.append("net_weight", netWeight);
      data.append("price", price);
      data.append("image", file);
      data.append("jewellery_description", description);
      const response = await addAssets(data);
      if (response) {
        dispatch(addAssetData(null));
        var imagesData = new FormData();
        imagesData.append("assetId", JSON.parse(response).assetId);
        for (var i = 0; i < images.length; i++) {
          imagesData.append("images", images[i]);
        }
        const res = await UploadImages(imagesData);
        console.log("images hogyiu", res);
        if (res) {
          setLoading(false);
          navigate("/portfolio");
        }
      } else {
        toast("Some Error Occured", {
          progressStyle: { background: "#eba300" },
        });
      }
    }
  };

  return (
    <div className="mrlb-5per mt-4">
      <ToastContainer />
      <div className="add-white-card pb-10">
        <div className="flex items-center justify-between py-4 md:py-8 px-5 md:px-10">
          <div className="flex items-center">
            <div
              onClick={() => {
                navigate("/jewelleryclarity");
              }}
              className="back-container"
            >
              <img src={back} alt="back" />
            </div>
            <span className="add-title">Adding jewellery</span>
          </div>
          <div className="select-step">Step 3/3</div>
        </div>
        <div className="border-bottom-grey" />
        <div className="px-5 pb-4 flex items-center justify-between">
          <div className="flex flex-col"></div>
        </div>
        <div className="sub-white-card flex flex-wrap mx-2 md:mx-5 px-5 gap-10">
          <div className="flex flex-col gap-10 flex-1">
            <div className="select-title">Enter Jewellery information</div>
            <div className="flex flex-col gap-4">
              <TextField
                id="outlined-basic"
                label="Jewellery name"
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setname(e.target.value)}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Purchase price (optional)"
                variant="outlined"
                type="number"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value.replace(/[^0-9|.]/g, ""));
                }}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Net Weight"
                variant="outlined"
                type="number"
                value={netWeight}
                onChange={(e) => {
                  setNetWeight(e.target.value.replace(/[^0-9|.]/g, ""));
                }}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Gross Weight"
                variant="outlined"
                type="number"
                value={grossWeight}
                onChange={(e) => {
                  setGrossWeight(e.target.value.replace(/[^0-9|.]/g, ""));
                }}
                className="flex-1 min-width-300"
              />
              <TextField
                id="outlined-basic"
                label="Description (optional)"
                variant="outlined"
                type="text"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className="flex-1 min-width-300"
              />
            </div>
          </div>

          <div className="flex flex-col flex-1">
            <div className="select-title ml-10 mb-8">Cover Image</div>
            {file === null ? (
              <label className="add-image-button-xl mb-3">
                <input
                  type="file"
                  title="Add document"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setfile(e.target.files[0]);
                  }}
                />
                <div className="flex items-center justify-center flex-col">
                  <img src={Add} alt="Add-icon" height={30} width={30} />
                  <div className="mt-2 text-xs text-yellow-600">
                    Add Cover image
                  </div>
                </div>
              </label>
            ) : (
              <div>
                <label
                  style={{
                    position: "absolute",
                    marginLeft: 320,
                    borderRadius: 100,
                    marginTop: -20,
                    backgroundColor: "white",
                    padding: 10,
                    boxShadow: "11px 13px 74px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <input
                    type="file"
                    title="Add document"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setfile(e.target.files[0]);
                    }}
                  />
                  <MdOutlineModeEditOutline size={30} color="#eba300" />
                </label>
                <img
                  src={URL.createObjectURL(file)}
                  alt="add"
                  style={{
                    objectFit: "cover",
                    width: 300,
                    height: 300,
                    margin: "auto",
                    borderRadius: 10,
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col">
            <div className="select-title mb-8">Additional images</div>
            <div className="flex flex-wrap flex-1">
              {images?.length === 0
                ? null
                : images?.map((i) => (
                    <div>
                      <button
                        onClick={() => {
                          const arr = images.filter((item) => item !== i);
                          setImages(arr);
                        }}
                        style={{
                          position: "absolute",
                          marginLeft: 110,
                          backgroundColor: "white",
                          borderRadius: 100,
                          boxShadow: "11px 13px 74px rgba(0, 0, 0, 0.5)",
                          padding: 10,
                        }}
                      >
                        <GrClose size={10} />
                      </button>
                      <img
                        src={URL.createObjectURL(i)}
                        alt="add"
                        className="white-card m-3"
                        style={{
                          objectFit: "cover",
                          width: 120,
                          height: 120,
                        }}
                      />
                    </div>
                  ))}

              <label className="add-image-button white-card mb-3">
                <input
                  type="file"
                  title="Add document"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setImages([...images, e.target.files[0]]);
                  }}
                />
                <div className="flex items-center justify-center flex-col">
                  <img src={Add} alt="Add-icon" height={30} width={30} />
                  <div className="mt-2 text-xs text-yellow-600">Add image</div>
                </div>
              </label>
            </div>
          </div>
        </div>
        {Loading ? (
          <Loader size={200} />
        ) : (
          <button
            onClick={_onPress}
            disabled={
              netWeight.trim() === "" ||
              grossWeight.trim() === "" ||
              name.trim() === "" ||
              file === null
                ? true
                : false
            }
            className={
              netWeight.trim() === "" ||
              grossWeight.trim() === "" ||
              name.trim() === "" ||
              file === null
                ? "disabled-next-button m-8"
                : "next-button m-8"
            }
          >
            <span className="next-text">Submit</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default JewelleryInfo;
