import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import KYC from "../../assets/lottie/Kyc.jsx";
import TitleWithSeemore from "../../components/TitleWithSeemore";
import { createKycOrder } from "../../redux/actions/AuthAction.js";
import Loader from "../../assets/lottie/Loader.jsx";

const KycWebView = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    async function getData() {
      const res = await createKycOrder();
      setloading(true);
      if (res) {
        // window.open(res.data.url);
        console.log("url____", res.data.url);
        setUrl(res.data.url);
      }
    }
    getData();
  }, []);

  return (
    <div className="bg-white mrlb-5per mt-6">
      <TitleWithSeemore title="Verify Identity" viewall={false} onBack={true} />
      <div
        style={{
          padding: "5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {url ? (
          <iframe
            style={{ height: "50vh", width: "50vw" }}
            title="verify"
            src={url}
          />
        ) : null}
      </div>
    </div>
  );
};

export default KycWebView;
