import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getJewelsCategory } from "../../redux/actions/MarketAction";
import { useDispatch } from "react-redux";
import { useNavigate, createSearchParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Skeleton } from "@mui/material";

const Category = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getJewelsCategory());
  }, [dispatch]);
  const { jewelsCategory } = useSelector((state) => state.marketReducer);

  const responsive = {
    largedesktop: {
      breakpoint: { max: 4000, min: 2500 },
      items: 13,
    },
    desktop: {
      breakpoint: { max: 2500, min: 1500 },
      items: 10,
    },
    tablet: {
      breakpoint: { max: 1500, min: 900 },
      items: 7,
    },
    lmobile: {
      breakpoint: { max: 900, min: 500 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 4,
    },
  };
  return (
    <>
      <div className="mx-5 md:mx-20 my-3">
        <div className="title">Categories</div>
        {jewelsCategory === null ? (
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "50px",
              overflow: "hidden",
            }}
          >
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
            <Skeleton
              variant="rectangular"
              style={{ height: "80px", width: "80px", borderRadius: "80px" }}
            />
          </div>
        ) : (
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            arrows={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            swipeable={true}
            className="overflow-y-visible"
          >
            {jewelsCategory.map((i) => (
              <div
                onClick={() => {
                  navigate({
                    pathname: "/market",
                    search: encodeURIComponent(
                      `${createSearchParams({
                        jewel_cat: i.id,
                      })}`
                    ),
                  });
                }}
                key={i.id}
                className="category-container"
              >
                <img src={i.image} alt="category" className="category-image" />
                <span className="category-text">{i.name}</span>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default Category;
